import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from "prop-types"

const Loading = ({ type, color, size }) => (
    <ReactLoading type={type}  color={color} height={size || 50} width={size || 50} />
);
Loading.propTypes={
    type:PropTypes.any.isRequired,
    color:PropTypes.any.isRequired,
    size:PropTypes.any.isRequired,
}
export default Loading;