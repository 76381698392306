import  { useState } from 'react';
import dateFormat from 'dateformat';
// import { useSelector } from 'react-redux';
import { 
  privateRequest, 
   } from '../../../requestMethods';
import useDebounce from '../../../redux/custom-hooks/useDebounce';

const useAnnulations = (currentUser,date, forDay, ) => {
  console.log("annulationHOOKS",currentUser,date, forDay)
    const formattedDate=dateFormat(
        date,
        'yyyy-mm-dd',
      )  
    const [data, setData]=useState([])
  const [loading, setLoading]=useState(false)
  const [errorMessage, setErrorMessage]=useState(null)
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const getAnnulations= async () => {
    setLoading(true)
    try {
      console.log("i'm here on annulation")
      if(forDay){
        console.log("i'm here on forday")
      const res = await privateRequest.get(
        `/api/v2/dashboard/CaPeriodiqueAnnulations/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
      );
      setData(res.data);
      }else{
        console.log("i'm here on forMonth")
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueAnnulations/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setData(res.data);
      }
      setErrorMessage(null)

    } catch (error) {
      console.log(error);
      setErrorMessage("Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.")
     setData([]) 
    }
    setLoading(false)
  };
  // useEffect(() => {
  //   getAnnulations();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(
    ()=>getAnnulations(),
    500,
    [date, currentUser?.siret, currentUser?.id, forDay]
  )
  return {
    annulations:{
        loading,
        errorMessage,
        data
    }
  }
};

export default useAnnulations;
