/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
  DialogContent,
  Divider,
  FormControl,
  Button,
  Switch,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { firestore } from '../../firebase/firebase';

const UberEats = ({
  listEquipments,
  selectedModule,
  siret,
  modulesWithPos,
  handleSwitchChange,
  handleCloseDialog,
}) => {
  const [keenId, setKeenId] = useState(null);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [moduleToSave, setModuleToSave] = useState({
    ...selectedModule,
    status: true,
  });

  console.log('selectedModule', selectedModule);
  console.log('modulesWithPos', modulesWithPos);
  console.log('siret', siret);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setModuleToSave({
      ...moduleToSave,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setModuleToSave({
      ...moduleToSave,
      [name]: checked,
    });
  };

  useEffect(() => {
    // This effect runs when keenId changes
    console.log('keenIDDD', keenId);

    // Update moduleToSave with the new keenId
    setModuleToSave((prevModule) => ({
      ...prevModule,
      keen_user_id: keenId,
    }));
  }, [keenId]);

  const handleEquipmentChange = (selectedEquipmentId) => {
    setSelectedEquipments((prevSelected) => {
      const updatedSelected = prevSelected.includes(selectedEquipmentId)
        ? prevSelected.filter((id) => id !== selectedEquipmentId)
        : [...prevSelected, selectedEquipmentId];
      return updatedSelected;
    });
  };


  const saveModule = async () => {
    try {
      const collectionRef = firestore.collection('marketplaceModules');
      const docRef = collectionRef.doc(siret);
      const docSnapshot = await docRef.get();

      const updatedUserModules = docSnapshot.exists ? docSnapshot.data().users : [];

      selectedEquipments.forEach((equipmentId) => {
        const equipment = listEquipments.find((eq) => eq.id === equipmentId);
        if (equipment) {
          // Check if a module with the same id_module already exists in the user's modules
          const userIndex = updatedUserModules.findIndex(
            (user) => user.keen_user_id === equipment.user_keen_id,
          );

          if (userIndex !== -1) {
            // Update the existing module instead of pushing a new one
            const user = updatedUserModules[userIndex];
            const existingModuleIndex = user.modules.findIndex(
              (module) => module.id_module === moduleToSave.id_module,
            );

            if (existingModuleIndex !== -1) {
              // Update the existing module's properties
              user.modules[existingModuleIndex] = {
                ...user.modules[existingModuleIndex], // Preserve existing properties
                selected_pos: equipment.name,
                keen_user_id: equipment.user_keen_id,
              };
            } else {
              // If the module with the same id_module does not exist, push the new module
              user.modules.push({
                ...moduleToSave,
                selected_pos: equipment.name,
                keen_user_id: equipment.user_keen_id,
                id_module: uuidv4(),
              });
            }
          } else {
            // If the user does not exist, create a new user entry with the new module
            updatedUserModules.push({
              keen_user_id: equipment.user_keen_id,
              modules: [
                {
                  ...moduleToSave,
                  selected_pos: equipment.name,
                  keen_user_id: equipment.user_keen_id,
                  id_module: uuidv4(),
                },
              ],
            });
          }
        }
      });

      // Update Firestore with the updated user modules
      await docRef.set({ users: updatedUserModules }, { merge: true });
      console.log('Modules successfully updated/added');
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding/updating document:', error);
    }
  };


  return (
    <>
      <DialogContent>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ fontSize: '20px', mr: 1 }} />
            <Typography variant="h4">Uber Eats</Typography>
          </Box>
          <Divider sx={{ my: 1, mb: 2 }} />
          <InputLabel sx={{ fontSize: '14px' }} htmlFor="store_id">
            Store ID
          </InputLabel>
          <TextField
            required
            id="store_id"
            name="store_id"
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange}
            value={moduleToSave?.store_id || ''}
          />
          <FormGroup>
            <FormControlLabel
              name="uber_rate"
              onChange={handleCheckBoxChange}
              control={<Checkbox checked={moduleToSave?.uber_rate} />}
              label="Appliquer le taux Uber Eats"
            />
            <FormControlLabel
              name="uber_developer"
              onChange={handleCheckBoxChange}
              control={<Checkbox checked={moduleToSave?.uber_developer} />}
              label="Mode Uber développer"
            />
            <FormControlLabel
              name="ring_new_command"
              onChange={handleCheckBoxChange}
              control={<Checkbox checked={moduleToSave?.ring_new_command} />}
              label="Faire sonner la caisse lors d’une nouvelle commande Uber Eats"
            />
            <FormControlLabel
              name="product_search_by_name"
              onChange={handleCheckBoxChange}
              control={<Checkbox checked={moduleToSave?.product_search_by_name} />}
              label="Recherche produit uber par nom"
            />
          </FormGroup>

          <Typography sx={{ fontWeight: 'bold', my: 2 }} variant="h6">
            Choisir POS
          </Typography>
          <FormControl sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={2}>
              {listEquipments
                ?.filter(
                  (equipment) =>
                    !modulesWithPos.some((module) => module.selected_pos === equipment.name),
                )
                .map((equipment) => (
                  <Grid item xs={12} sm={6} key={equipment.id}>
                    <FormControlLabel
                      label={equipment.name}
                      control={
                        <Switch
                          checked={selectedEquipments.includes(equipment.id)}
                          onChange={() => handleEquipmentChange(equipment.id)}
                        />
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </FormControl>

          <FormControl
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 2 }}
          >
            <Grid container spacing={2}>
              {modulesWithPos?.map((module) => (
                <Grid item xs={12} sm={6} key={module.id_module}>
                  <FormControlLabel
                    label={module.selected_pos}
                    control={
                      <Switch
                        checked={module.status}
                        onChange={() => handleSwitchChange(module.id_module, siret)}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>

          <Button
            onClick={saveModule}
            sx={{ width: 1, mt: 2, mb: 1 }}
            variant="contained"
            color="success"
          >
            Enregistrer
          </Button>
        </Grid>
      </DialogContent>
    </>
  );
};

UberEats.propTypes = {
  listEquipments: PropTypes.array,
  selectedModule: PropTypes.func,
  handleSwitchChange: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  siret: PropTypes.func,
  modulesWithPos: PropTypes.array,
};

export default UberEats;
