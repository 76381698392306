import React from 'react'

const Borne = () => {
  return (
    <svg width="45" height="38" viewBox="0 0 45 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14.0935 2.44043C13.5447 2.44043 13.0984 2.88268 13.0935 3.43143L12.9031 24.5955C12.8981 25.1513 13.3473 25.6045 13.9031 25.6045H27.1888C27.7446 25.6045 28.1938 25.1513 28.1888 24.5955L27.9985 3.43144C27.9935 2.88268 27.5473 2.44043 26.9985 2.44043H14.0935ZM13.9596 4.18975C13.9642 3.6408 14.4106 3.19824 14.9596 3.19824H26.0948C26.6438 3.19824 27.0901 3.6408 27.0948 4.18976L27.2614 23.8197C27.2661 24.3752 26.817 24.8281 26.2614 24.8281H14.7929C14.2373 24.8281 13.7882 24.3752 13.7929 23.8197L13.9596 4.18975ZM15.1416 5.99168C15.1462 5.44266 15.5925 5 16.1416 5H24.8586C25.4077 5 25.854 5.44266 25.8586 5.99168L25.9917 21.9917C25.9963 22.5472 25.5472 23 24.9917 23H16.0084C15.4529 23 15.0038 22.5472 15.0085 21.9917L15.1416 5.99168Z" fill="#FF6346"/>
<path fillRule="evenodd" clipRule="evenodd" d="M24.4354 25.1367H16.5356V35.0428C16.5356 35.4583 16.8725 35.7952 17.288 35.7952H23.6831C24.0986 35.7952 24.4354 35.4583 24.4354 35.0428V25.1367ZM22.9137 27.7258H18.065V33.1238H22.9137V27.7258Z" fill="#FF6346"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.6945 12.4785C28.5577 12.4785 28.4461 12.5882 28.4437 12.725L28.335 19.004C28.3326 19.1442 28.4455 19.2592 28.5857 19.2592H31.6749C31.8148 19.2592 31.9276 19.1447 31.9257 19.0048L31.8371 12.7258C31.8352 12.5887 31.7235 12.4785 31.5864 12.4785H28.6945ZM28.8501 12.8477C28.7831 12.8477 28.7279 12.9004 28.7248 12.9674L28.6586 14.4402C28.6554 14.5116 28.7124 14.5712 28.7839 14.5712H31.4755C31.5473 14.5712 31.6044 14.5112 31.6007 14.4395L31.5264 12.9667C31.523 12.9 31.4679 12.8477 31.4011 12.8477H28.8501Z" fill="#FF6346"/>
</svg>
  )
}

export default Borne