import axios from 'axios';

 
const token=process.env.REACT_APP_TOKEN

export const publicRequest=axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NODEJS}/api`,
  headers: {
    'Content-type': 'application/json',
  },
});
export const privateRequest=axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}`,
  headers: {
    'Content-type': 'application/json',
    Authorization:`Bearer ${token}`
  }
});
export const privateReqApiBackoffice=axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NODEJS}`,
  headers: {
    'Content-type': 'application/json',
    Authorization:`Bearer ${token}`
  }
});

