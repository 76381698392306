import { Box, Button } from '@material-ui/core';
// import { GppBad } from '@material-ui/icons'
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../../utility/utilityFunctions';
import BlockageAccount from '../../components/icons/BlockageAccount';

const AccessDenied = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: t=>t.palette.backgroundColor,
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          width: '90%',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        className="accessDeniedContent"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            color: (t) => t.palette.mainColor,
            '& h1': {
              mb: { xs: '0 !important', sm: '15px' },
              fontSize: { xs: '20px !important', sm: '2rem !important' },
            },
          }}
          className="top"
        >
          {' '}
          <h1>
            <span role="img" aria-label="img">
              🚨
            </span>{' '}
            Maintenance Impayée{' '}
            <span role="img" aria-label="img">
              🚨
            </span>
          </h1>
          <Box
            sx={{
              width: {xs:'120px', sm:'200px'},
              height: {xs:'120px', sm:'200px'},
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="icon"
          >
            <BlockageAccount />
          </Box>
        </Box>
        <Box sx={{
          '& h3': {
              my:0,
              fontSize: { xs: '18px !important', sm: '1.17rem !important' },
            },
            '& p':{
              color: '#4A4A4A',
              fontSize: { xs: '14.5px !important', sm: '16px!important' },
            }
        }} className="center">
          <h3 className="title">Accès aux Statistiques Restreint</h3>
          <p>
            Nous avons remarqué que certain facture de maintenance sont en defaut de paiement Pour
            accéder aux statistiques, veuillez nous contacter a adv@biborne.com.
          </p>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
          className="bottom"
        >
          <a href="mailto:adv@biborne.com">
            <Button
              sx={{
                backgroundColor: "#27AE60",
                borderRadius: '13px',
                fontWeight: '500',
                color: 'white',
                px: '30px',
                '&:hover': {
                  backgroundColor: "#238f51",
                },
              }}
            >
              Contactez Nous
            </Button>
          </a>
          <Box
            onClick={() => {
              handleLogout(dispatch);
              navigate('/auth/login');
            }}
            to="/auth/login"
            variant="body"
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: (t) => t.palette.text.textDark,
              transition: 'all .4s ease',
              '&:hover': {
                color: (t) => t.palette.mainColor,
                textDecoration: 'underline',
              },
              fontSize: '15px',
              fontFamily: '"Jost", sans-serif',
            }}
          >
            Revenir à la page de connexion
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccessDenied;
