import { Box, Typography } from '@material-ui/core'
import React from 'react'
import ErrorIcon from '../icons/ErrorIcon'

const Error = () => {
  return (
    <Box className='error'
                  sx={{
                    // position: 'absolute',
                    // right: 0,
                    // bottom: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection:"column",
                    justifyContent: 'center',
                    backdropFilter: 'blur(1px)',
                    background: '#ffffff5e',
                    "& svg":{
                      fill: (t) => t.palette.error.main,
                      width:"65px"
                    }
                  }}
                >
                  <ErrorIcon
                  />
                  <Typography sx={{
                    fontSize:"13px",
                    color: (t) => t.palette.error.main,
                    textAlign:"center",
                    px:"10px",
                    fontWeight:"500"
                  }}>
                  Une erreur est survenue lors  <br/>de la  récupération des données.
                  </Typography>
                </Box>
  )
}

export default Error