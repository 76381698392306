import React from 'react'

const CB = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="credit-card-svgrepo-com 1" clipPath="url(#clip0_227_703)">
<g id="Group">
<g id="Group_2">
<g id="Group_3">
<g id="Group_4">
<path id="Vector" d="M25.1969 10.4287H11.1747C9.81697 10.4287 8.7041 11.5416 8.7041 12.8993V21.78C8.7041 23.1377 9.81697 24.2506 11.1747 24.2506H25.1969C26.5546 24.2506 27.6674 23.1377 27.6674 21.78V12.8993C27.673 11.536 26.5546 10.4287 25.1969 10.4287ZM26.3376 21.8134C26.3376 22.3031 25.9369 22.7093 25.4417 22.7093H11.1469C10.6572 22.7093 10.251 22.3086 10.251 21.8134V15.5646H26.3376V21.8134ZM26.3376 13.2109H10.251V12.8715C10.251 12.3818 10.6516 11.9756 11.1469 11.9756H25.4417C25.9314 11.9756 26.3376 12.3762 26.3376 12.8715V13.2109Z" fill="#393B3F"/>
</g>
</g>
<g id="Group_5">
<path id="Vector_2" d="M10.9966 8.90291H19.6937L17.9243 5.31946C17.3178 4.0953 15.8321 3.59451 14.6079 4.20103L2.04358 10.4109C0.819425 11.0174 0.318633 12.5031 0.925148 13.7272L4.86471 21.6898C5.39333 22.7637 6.61192 23.2868 7.73036 22.9807C7.3965 22.4187 7.20731 21.7677 7.20731 21.0722V12.6978C7.20175 10.6112 8.91 8.90291 10.9966 8.90291Z" fill="#393B3F"/>
</g>
<g id="Group_6">
<path id="Vector_3" d="M12.7827 21.7461C13.2668 21.7461 13.7064 21.5291 13.9957 21.1841C14.2907 21.5291 14.7247 21.7461 15.2088 21.7461C16.0935 21.7461 16.8113 21.0283 16.8113 20.1436C16.8113 19.2588 16.0935 18.541 15.2088 18.541C14.7247 18.541 14.2851 18.758 13.9957 19.103C13.7008 18.758 13.2668 18.541 12.7827 18.541C11.898 18.541 11.1802 19.2588 11.1802 20.1436C11.1802 21.0283 11.898 21.7461 12.7827 21.7461Z" fill="#393B3F"/>
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_227_703">
<rect width="27" height="27" fill="white" transform="translate(0.66748 0.597656)"/>
</clipPath>
</defs>
</svg>
  )
}

export default CB
