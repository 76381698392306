import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';

const ProgressRectangle = ({ sx,percent }) => {
  let valueTop;
  let valueRight;
  let valueBottom;
  let valueLeft;
  const isXsSize=useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const bordersValue = () => {
    if (percent <= 25) {
      valueTop = percent;
      valueRight = 0;
      valueBottom = 0;
      valueLeft = 0;
    } else if (percent > 25 && percent <= 50) {
      valueTop = 25;
      valueRight = percent - 25;
      valueBottom = 0;
      valueLeft = 0;
    } else if (percent > 50 && percent <= 75) {
      valueTop = 25;
      valueRight = 25;
      valueBottom = percent - 50;
      valueLeft = 0;
    } else if (percent > 75) {
      valueTop = 25;
      valueRight = 25;
      valueBottom = 25;
      valueLeft = percent - 75;
    }
    return {
      valueTop,
      valueRight,
      valueBottom,
      valueLeft,
    };
  };
  useEffect(() => {
    bordersValue();
  }, [percent]);

  const valueTopUsed = (bordersValue().valueTop * sx.width[isXsSize ? "xs" : "sm"]) / 25;
  const valueRightUsed = (bordersValue().valueRight * sx.height[isXsSize ? "xs" : "sm"]) / 25;
  const valueBottomUsed = (bordersValue().valueBottom * sx.width[isXsSize ? "xs" : "sm"]) / 25;
  const valueLeftUsed = (bordersValue().valueLeft * sx.height[isXsSize ? "xs" : "sm"]) / 25;
  return (
    <Box
      className="ProgressRectangle"
      sx={{
        width: {
          xs: `${sx.width.xs}px`,
          sm: `${sx.width.sm}px`,
        },
        height: {
          xs: `${sx.height.xs}px`,
          sm: `${sx.height.sm}px`,
        },
        borderRadius: '8px',
        overflow: 'hidden',
        '& .border': {
          borderRadius: '20px',
          transition:"all .6s ease"
        },
        position: 'relative',
        '& .bordertop': {
          content: '""',
          position: 'absolute',
          background: (t) => t.palette.mainColor,
          height: '4px',
          width: `${valueTopUsed}px`,
        },
        '& .borderRight': {
          content: '""',
          position: 'absolute',
          background: (t) => t.palette.mainColor,
          width: '4px',
          right: 0,
          height: `${valueRightUsed}px`,
        },
        '& .borderBottom': {
          content: '""',
          position: 'absolute',
          background: (t) => t.palette.mainColor,
          height: '4px',
          bottom: 0,
          right: 0,
          transform: 'rotate(180deg)',
          width: `${valueBottomUsed}px`,
        },
        '& .borderLeft': {
          content: '""',
          position: 'absolute',
          background: (t) => t.palette.mainColor,
          width: '4px',
          left: 0,
          bottom: 0,
          transform: 'rotate(180deg)',
          height: `${valueLeftUsed}px`,
        },
      }}
      
    >
      <Box
        sx={{
          width: 'calc(100% - 2px)',
          height: 'calc(100% - 2px)',
          borderRadius: '5px',
          border: '1px dashed',
          borderColor:t=>t.palette.mainColor,
          position: 'absolute',
          top: 'calc(50%)',
          left: 'calc(50%)',
          transform: 'translate(-50%,-50%)',
        }}
      />
      <Box className="border bordertop" />
      <Box className="border borderLeft" />
      <Box className="border borderBottom" />
      <Box className="border borderRight" />
    </Box>
  );
};
ProgressRectangle.propTypes = {
  sx: Proptypes.any.isRequired,
  percent: Proptypes.any.isRequired,
};

export default ProgressRectangle;
