import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';


const Switch = ({choice, onClick, oneLabel}) => {
  return (
    <Box
      onClick={onClick}
      className="toggle"
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'relative',
        width: '60px',
        height: '30px',
        borderRadius: '25px',
        backgroundColor: '#ecf0f3',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        boxShadow: '-3px -3px 7px #ffffff, 3px 3px 5px #ceced1',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          left: choice === 'left' ? '5px' : 'calc(100% - 25px)',
          transition: 'all .6s cubic-bezier(.68,-.55,.256,1.55)',
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: oneLabel && choice === 'left' ? "lightgray" : '#ff7b00',
          boxShadow: '-3px -3px 7px #ffffff, 3px 3px 5px #ceced1',
        }}
        className="btnToggle"
      />
    </Box>
  );
};
Switch.propTypes = {
  choice: PropTypes.any.isRequired,
  onClick: PropTypes.any.isRequired,
  oneLabel: PropTypes.any.isRequired,
};
export default Switch;
