import React from 'react';

const MaximizeCircle = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.583 2.75H19.2497V6.41667"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 8.25L19.25 2.75"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41667 19.2502H2.75V15.5835"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 13.75L2.75 19.25"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8514 2.01684C12.2555 1.89767 11.6322 1.8335 10.9997 1.8335C5.93967 1.8335 1.83301 5.94016 1.83301 11.0002C1.83301 11.6327 1.89719 12.2468 2.01635 12.8335"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14844 19.9832C9.74427 20.1023 10.3676 20.1665 11.0001 20.1665C16.0601 20.1665 20.1668 16.0598 20.1668 10.9998C20.1668 10.3765 20.1026 9.76234 19.9834 9.1665"
        stroke="#292D32"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MaximizeCircle;
