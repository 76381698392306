import React, { createContext, useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}`);

    newSocket.on('connect_error', (error) => {
      console.error('Erreur de connexion WebSocket :', error);
    });

    newSocket.on('progress', (progressIo) => {
      setProgress(progressIo);
      console.log('progressIo', progressIo);
    });

    newSocket.on('complete', (messageIo) => {
      setMessage(messageIo);
      setProgress(100); // Mettre à 100% lorsque c'est terminé
      console.log('messageIo', messageIo);
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  const startExport = () => {
    socket.emit('startExport');
    console.log('Emitted startExport');
  };

  const progressExport = () => {
    socket.emit('progressExport');
    console.log('Emitted progressExport');
  };

  return (
    <SocketContext.Provider value={{ socket, progress, message, startExport, progressExport }}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useSocket = () => useContext(SocketContext);
