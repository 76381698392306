/* eslint-disable prefer-template */
/* eslint-disable class-methods-use-this */
import axios from "axios";
// import FranchiseService from "./FranchiseService";

const API_URL = `${process.env.REACT_APP_API_URL_NODEJS}/api/auth/`;

class AuthService {
  login(login, password) {
    return axios
      .post(API_URL + "signin", {
        login,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          // FranchiseService.get(response.data.id).then(res=>{
          //   localStorage.setItem("user", JSON.stringify(res.data));
          // })
        }
        return response.data;
      });
  }
  
  loginResto(login, password) {
    return axios
      .post(API_URL + "signinResto", {
        login,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  loginGuest(login, password) {
    return axios
      .post(API_URL + "signinGuest", {
        login,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    return null
  }

  register(login, email, password) {
    return axios.post(API_URL + "signup", {
      login,
      email,
      password
    });
  }

  getCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem('user'));
  } catch { return null }
    
  }
}

export default new AuthService();
