import React from 'react'
import propTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ConvertirMonetaire, addSpacesToNumber } from '../../../../utility/utilityFunctions'
import Loading from '../../../../components/custum-elements/Loading'
import Error from '../../../../components/custum-elements/Error'
import EmptyData from '../../../../components/custum-elements/EmptyData'

const InfoList = ({infos, title}) => {
    const {data, error, loading}=infos 
    const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  return (
    <Box className="infoList">
      {loading ? (
        <Box
        sx={{
         width: '100%',
          height: '100%',
          '& svg': {
            fill: (t) => t.palette.mainColor,
          },
        }}
      >
        <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection:"column",
          justifyContent: 'center',
        }}
      >
        <Loading type="bubbles" width={40} height={40} />
        <Typography sx={{
          fontSize:"13px",
          color: (t) => t.palette.mainColor,
          textAlign:"center",
          px:"10px",
          fontWeight:"500"
        }}>
        En cours de chargement...
        </Typography>
      </Box>
        
      </Box>
      ) : error ? (
        <Error/>
      ) : data?.length === 0 ? (
        <Box sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          width:"100%"
        }}><EmptyData/></Box>
      ) : (
        data?.map((item) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: "10px",
              px: title!=="Equipements" ? '10px' : "20px",
              borderRadius: '75px',
              backgroundColor: 'rgba(242, 243, 245, 1)',
              mb: '15px',
            }}
            className="infoItem"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
              className="left"
            >
              {title!=="Equipements" && <Box
                sx={{
                  width: '47px',
                  height: '47px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: title === 'sources' ? (t) => t.palette.lightMainColor : 'white',
                }}
              >
                {item.icon}
              </Box>}
              <Box className="text">
                <Typography
                  sx={{
                    color: (t) => t.palette.text.textDark,
                    fontSize: '14px',
                  }}
                >
                  {item.title}
                </Typography>
                {(item.nbreCommandes && item.nbreCommandes!==0) ?<Box
                  sx={{
                    color: (t) => t.palette.Soft,
                    fontSize: '12px',
                    letterSpacing: ' 0.44px',
                  }}
                >{`${addSpacesToNumber(item.nbreCommandes)} commandes`}</Box>  : null}
              </Box>
            </Box>
            <Box sx={{
              textAlign:"right"
            }} className="right">
              <Box
                sx={{
                  color: (t) => t.palette.text.secondary,
                  fontSize: '14px',
                  fontWeight: 700,
                }}
                className="montant"
              >
                {`${ConvertirMonetaire(currencyCode, currencySymbol, item.montant)}`}
              </Box>
              {(item.percent && item.percent!==0) ? (
                <Box
                  sx={{
                    color:
                      item.percent > 0
                        ? (t) => t.palette.success.main
                        : (t) => t.palette.error.main,
                    fontSize: '15px',
                    fontWeight: 500,
                  }}
                  className="percent"
                >
                  {`${item.percent}%` }
                </Box>
              ) : null}
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
}
InfoList.propTypes={
    infos:propTypes.any.isRequired,
    title:propTypes.any.isRequired,
}
export default InfoList