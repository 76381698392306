import React from 'react'

const Stati = () => {
  return (
    <svg width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="14.8174" width="8" height="9" fill="#F56F3B"/>
<rect x="10.9038" y="0.634766" width="7.78846" height="23.3654" fill="#F56F3B"/>
<rect x="21.8077" y="5.30762" width="7.78846" height="18.6923" fill="#F56F3B"/>
<rect x="32.7115" y="6.86523" width="7.78846" height="17.1346" fill="#F56F3B"/>
</svg>

  )
}

export default Stati