import React from 'react';

const EmptyData3 = () => {
  return (
    <svg width="66" height="84" viewBox="0 0 66 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65.3708 54.3281V72.2377C65.3708 78.2348 50.8824 83.0871 33 83.0871C21.6737 83.0871 11.6967 81.138 5.91758 78.1802C2.94632 76.6674 1.09259 74.8955 0.711087 72.9737C0.656518 72.7283 0.629395 72.4831 0.629395 72.2377V54.3281C0.629395 54.2735 0.629395 54.2191 0.642876 54.1647C0.629395 54.1374 0.629395 54.1238 0.642876 54.1101C0.642876 54.0147 0.656518 53.9193 0.670161 53.824C0.683803 53.7831 0.683803 53.7421 0.697445 53.7012C0.711087 53.5921 0.738211 53.4833 0.779298 53.3741V53.3605C0.833867 53.1425 0.915559 52.9379 1.01105 52.7199C2.81022 48.8082 10.8382 45.578 21.6056 44.2286C23.0095 44.0512 24.4679 43.9015 25.9534 43.7923H26.0353C28.2842 43.6151 30.6151 43.5334 33.0002 43.5334C35.3853 43.5334 37.7161 43.6151 39.9649 43.7923H40.0466C41.5322 43.9015 42.9906 44.0512 44.3945 44.2286C55.162 45.5779 63.1898 48.8082 64.989 52.7199C65.0845 52.9379 65.1662 53.1425 65.2208 53.3605V53.3741C65.2617 53.4833 65.2888 53.5921 65.3025 53.7012C65.3297 53.8375 65.3434 53.9601 65.357 54.1101V54.1647C65.3708 54.2191 65.3708 54.2735 65.3708 54.3281Z"
        fill="#39526C"
      />
      <path
        opacity="0.1"
        d="M65.3708 54.3826V55.2412C65.3708 61.2383 50.8824 66.1041 33 66.1041C15.1177 66.1041 0.629395 61.2383 0.629395 55.2412V54.3826C0.629395 54.5188 0.642876 54.6414 0.656518 54.7642C1.0791 58.9894 8.7253 62.6151 19.5883 64.2642C19.97 64.3187 20.338 64.3733 20.7197 64.4277C21.3329 64.523 21.9463 64.6049 22.5732 64.6731C22.8323 64.6867 23.1048 64.714 23.3773 64.7548C24.8903 64.9183 26.4577 65.0411 28.0659 65.1228C29.1427 65.1772 30.2331 65.2181 31.3371 65.2318C31.8823 65.2454 32.4412 65.2454 33 65.2454C33.5589 65.2454 34.1176 65.2454 34.6628 65.2318C35.7668 65.2181 36.8571 65.1772 37.934 65.1228C39.5423 65.0411 41.1097 64.9183 42.6226 64.7548C42.8951 64.714 43.1678 64.6867 43.4267 64.6731C44.0537 64.6049 44.667 64.523 45.2802 64.4277C45.6619 64.3733 46.0299 64.3187 46.4116 64.2642C57.2746 62.6151 64.9208 58.9894 65.3434 54.7642C65.3572 54.6414 65.3708 54.5188 65.3708 54.3826Z"
        fill="#E8E8E9"
      />
      <path
        d="M65.3708 54.2735V54.3826C65.3708 54.5189 65.3572 54.6415 65.3435 54.7643C64.7711 59.0031 57.1656 62.5878 46.4117 64.2642C46.0301 64.3188 45.6621 64.3733 45.2804 64.4277C44.8987 64.4823 44.5171 64.5231 44.1356 64.5777C43.9038 64.6049 43.6584 64.6322 43.4268 64.6732C43.1679 64.6868 42.8953 64.7141 42.6227 64.7548C41.614 64.8502 40.5783 64.9457 39.5287 65.0139C38.9971 65.0548 38.4656 65.0956 37.934 65.1229C36.8572 65.1638 35.7668 65.2046 34.6628 65.2318C34.1176 65.2455 33.5587 65.2455 33 65.2455C32.4412 65.2455 31.8823 65.2455 31.3371 65.2318C30.2331 65.2046 29.1428 65.1638 28.0659 65.1229C27.5343 65.0956 27.0028 65.0548 26.4712 65.0139C25.4217 64.9457 24.3859 64.8502 23.3772 64.7548C23.1046 64.7141 22.832 64.6868 22.5731 64.6732C22.3415 64.6322 22.0961 64.6049 21.8643 64.5777C21.4827 64.5231 21.101 64.4823 20.7195 64.4277C20.3378 64.3733 19.9698 64.3188 19.5882 64.2642C8.83444 62.5878 1.22901 59.0031 0.656518 54.7643C0.642876 54.6415 0.629395 54.5189 0.629395 54.3826V54.2735C0.629395 54.2327 0.629395 54.2054 0.642876 54.1647C0.629395 54.1374 0.629395 54.1237 0.642876 54.1101C0.656518 53.96 0.670161 53.8374 0.697445 53.7011C0.711087 53.6329 0.724569 53.5649 0.724569 53.4967C0.711087 53.4696 0.724569 53.4423 0.738211 53.415C0.738211 53.3877 0.738211 53.3468 0.751853 53.3058C0.779137 53.2242 0.79278 53.1288 0.833706 53.047C0.874633 52.9242 0.929201 52.7881 0.98361 52.6517C2.67379 48.7672 10.3746 45.5505 20.7741 44.1194C22.0144 43.9423 23.2956 43.7923 24.6176 43.6833C24.6313 43.6697 24.6586 43.6697 24.6722 43.6697C27.33 43.4243 30.1241 43.3016 33 43.3016C35.876 43.3016 38.67 43.4243 41.3279 43.6697C41.3415 43.6697 41.3688 43.6697 41.3824 43.6833C42.7046 43.7923 43.9858 43.9423 45.226 44.1194C55.6255 45.5505 63.3264 48.7672 65.0164 52.6517C65.1118 52.8698 65.1936 53.0879 65.2482 53.3058C65.2755 53.4423 65.2755 53.5649 65.3026 53.7011C65.3299 53.8374 65.3435 53.96 65.3572 54.1101V54.1647C65.3572 54.1918 65.3708 54.2327 65.3708 54.2735Z"
        fill="#CEDCE4"
      />
      <path
        d="M54.6676 69.3567V74.3624C52.0599 75.1509 49.0681 75.7987 45.7949 76.2704V71.2647C49.0682 70.793 52.0599 70.1452 54.6676 69.3567Z"
        fill="#CEDCE4"
      />
      <path
        opacity="0.3"
        d="M65.2481 53.3058C65.2345 53.3331 65.2345 53.3468 65.2209 53.3604V53.3741C63.6944 58.8805 49.8601 63.1874 33.0001 63.1874C16.14 63.1874 2.30588 58.8805 0.779237 53.3741V53.3604C0.765595 53.3468 0.765595 53.3331 0.765595 53.3195L0.751953 53.3058C0.779237 53.2242 0.79288 53.1288 0.833806 53.047C0.874733 52.9242 0.929301 52.7881 0.98371 52.6517C2.6739 48.7672 10.3747 45.5505 20.7742 44.1194C22.0145 43.9423 23.2957 43.7923 24.6177 43.6833C24.6314 43.6697 24.6587 43.6697 24.6723 43.6697C27.3301 43.4243 30.1242 43.3016 33.0001 43.3016C35.8761 43.3016 38.6701 43.4243 41.328 43.6697C41.3416 43.6697 41.3689 43.6697 41.3825 43.6833C42.7047 43.7923 43.9859 43.9423 45.2261 44.1194C55.6256 45.5505 63.3265 48.7672 65.0165 52.6517C65.1119 52.8698 65.1936 53.0879 65.2481 53.3058Z"
        fill="#040000"
      />
      <path
        d="M65.3708 33.1474V51.0435C65.3708 51.5886 65.248 52.1338 65.0164 52.6517C65.0028 52.679 64.9892 52.7063 64.9892 52.7199C62.5768 57.9264 49.1787 61.8928 33 61.8928C16.8214 61.8928 3.42331 57.9264 1.01089 52.7199C1.01089 52.7063 0.997252 52.679 0.98361 52.6517C0.929041 52.5154 0.874633 52.3792 0.833706 52.2564C0.79278 52.1203 0.751853 51.9975 0.724569 51.8613C0.724569 51.8613 0.724569 51.8476 0.724569 51.834C0.683803 51.725 0.67 51.6022 0.656358 51.4796C0.642876 51.3295 0.629395 51.1933 0.629395 51.0434V33.1339C0.629395 32.8203 0.683963 32.4932 0.765656 32.1934C0.779298 32.1661 0.779298 32.1388 0.79294 32.1252C0.847509 31.9209 0.929201 31.7164 1.01105 31.5256C2.82386 27.6138 10.8654 24.3837 21.6192 23.0342C23.0231 22.857 24.4679 22.7071 25.9534 22.598C28.2161 22.4346 30.574 22.3391 33.0002 22.3391C35.4263 22.3391 37.7841 22.4346 40.0468 22.598C41.5323 22.7071 42.9771 22.857 44.381 23.0342C55.1349 24.3835 63.1764 27.6138 64.9892 31.5256C65.071 31.7164 65.1527 31.9209 65.2073 32.1252C65.2209 32.1388 65.2209 32.1661 65.2346 32.1934C65.3163 32.5067 65.3708 32.8203 65.3708 33.1474Z"
        fill="#39526C"
      />
      <path
        opacity="0.1"
        d="M65.3708 33.2019V34.0607C65.3708 38.5992 57.043 42.4839 45.226 44.1193C44.9533 44.1466 44.6671 44.1875 44.3946 44.2285C40.8509 44.6645 37.0071 44.9099 33.0002 44.9099C28.9931 44.9099 25.1494 44.6645 21.6056 44.2285C21.3329 44.1875 21.0468 44.1466 20.7742 44.1193C8.95722 42.4839 0.629395 38.5992 0.629395 34.0607V33.2019C0.629395 33.3247 0.642876 33.4609 0.656518 33.5836C1.09275 37.9043 9.10696 41.5979 20.3926 43.1926C20.5016 43.2063 20.6105 43.2199 20.7197 43.2336C21.592 43.3697 22.4643 43.4652 23.3775 43.5606C23.7864 43.6016 24.1954 43.6423 24.6178 43.6833C25.054 43.724 25.5037 43.765 25.9534 43.7922H26.0353C27.7526 43.9287 29.5245 44.0104 31.3373 44.0376C31.8825 44.0511 32.4413 44.0511 33.0002 44.0511C33.559 44.0511 34.1177 44.0511 34.6629 44.0376C36.4757 44.0104 38.2476 43.9287 39.9649 43.7922H40.0466C40.4965 43.765 40.9462 43.724 41.3823 43.6833C41.8049 43.6423 42.2136 43.6016 42.6226 43.5606C43.5358 43.4652 44.4081 43.3697 45.2804 43.2336L45.6076 43.1926C56.8931 41.5979 64.9073 37.9043 65.3435 33.5836C65.3572 33.4609 65.3708 33.3247 65.3708 33.2019Z"
        fill="#E8E8E9"
      />
      <path
        d="M65.3708 33.0794V33.202C65.3708 33.3248 65.3572 33.4611 65.3435 33.5837C64.7574 37.9044 56.784 41.5708 45.6076 43.1927L45.2804 43.2337C44.8987 43.2881 44.5171 43.329 44.1356 43.3834C43.6313 43.438 43.1405 43.4925 42.6227 43.5608C42.2002 43.6017 41.7641 43.6425 41.3279 43.6697C40.878 43.7105 40.4283 43.7514 39.9649 43.7924C39.815 43.7924 39.6786 43.806 39.5287 43.8196C38.9971 43.8606 38.4656 43.8878 37.934 43.9151C36.8572 43.9695 35.7668 44.0105 34.6628 44.0378H31.3371C30.2331 44.0105 29.1428 43.9695 28.0659 43.9151C27.5343 43.8878 27.0028 43.8606 26.4712 43.8196C26.3213 43.806 26.185 43.7924 26.035 43.7924C25.5716 43.7514 25.1218 43.7105 24.672 43.6697C24.2358 43.6425 23.7997 43.6017 23.3772 43.5608C22.8592 43.4925 22.3686 43.438 21.8643 43.3834C21.4827 43.329 21.101 43.2881 20.7195 43.2337C20.6105 43.22 20.5014 43.2064 20.3924 43.1927C9.2161 41.5706 1.24265 37.9044 0.656518 33.5837C0.642876 33.4611 0.629395 33.3248 0.629395 33.202V33.0794C0.629395 32.9431 0.642876 32.8067 0.656518 32.6706C0.670161 32.5342 0.683803 32.3979 0.724729 32.2617C0.724729 32.2207 0.738371 32.1662 0.752014 32.1252H65.2482C65.2779 32.2389 65.2737 32.3567 65.2927 32.4708C65.326 32.6367 65.3477 32.8049 65.3575 32.9751C65.3585 33.0102 65.3708 33.0436 65.3708 33.0794Z"
        fill="#CEDCE4"
      />
      <path
        d="M54.6676 48.1649V53.1706C52.0599 53.9591 49.0681 54.6069 45.7949 55.0786V50.0729C49.0682 49.601 52.0599 48.9534 54.6676 48.1649Z"
        fill="#CEDCE4"
      />
      <path
        opacity="0.3"
        d="M65.2345 32.125V32.1932C63.6671 37.6997 49.8465 42.0066 33.0002 42.0066C16.1537 42.0066 2.33319 37.6997 0.765625 32.1932V32.125H65.2345Z"
        fill="#040000"
      />
      <path
        d="M65.3708 11.953V29.849C65.3708 30.4213 65.2344 30.9803 64.9892 31.5254C64.8938 31.7298 64.7847 31.9207 64.6621 32.125C61.5544 37.0318 48.5653 40.7118 33.0002 40.7118C17.4349 40.7118 4.44583 37.0318 1.33815 32.125C1.21553 31.9207 1.10639 31.7298 1.01105 31.5254C1.01105 31.5117 0.997412 31.4844 0.98377 31.4572C0.929201 31.3347 0.874793 31.1983 0.833867 31.062C0.79294 30.953 0.765656 30.8439 0.738371 30.7349C0.724729 30.694 0.711248 30.6396 0.711248 30.5987C0.683963 30.4895 0.670321 30.3942 0.656679 30.2852C0.642876 30.1351 0.629395 29.9989 0.629395 29.849V11.9394C0.738371 5.96956 15.1861 1.1582 33.0002 1.1582C44.013 1.1582 53.7446 2.99829 59.5918 5.81965C62.9993 7.45527 65.0983 9.43162 65.3437 11.5715C65.3572 11.694 65.3708 11.8304 65.3708 11.953Z"
        fill="#39526C"
      />
      <path
        opacity="0.1"
        d="M65.3708 12.0076V12.8662C65.3708 17.5139 56.6477 21.4802 44.381 23.034C40.8372 23.4702 37.0073 23.7154 33.0002 23.7154C28.9931 23.7154 25.163 23.4702 21.6192 23.034C9.35236 21.4802 0.629395 17.5139 0.629395 12.8664V12.0077C0.629395 16.8871 10.1975 21.0034 23.3775 22.3663C24.2227 22.4482 25.0811 22.5299 25.9534 22.5979C28.2161 22.7616 30.574 22.8569 33.0002 22.8569C35.4263 22.8569 37.7841 22.7616 40.0468 22.5979C40.9191 22.5299 41.7777 22.4482 42.6229 22.3663C55.8027 21.0032 65.3708 16.887 65.3708 12.0076Z"
        fill="#E8E8E9"
      />
      <path
        d="M65.3708 11.885V11.953C65.289 16.8461 55.7481 20.976 42.6227 22.3662C41.7776 22.448 40.9191 22.5297 40.0466 22.5978C37.7841 22.7615 35.4261 22.8568 33 22.8568C30.5738 22.8568 28.216 22.7615 25.9533 22.5978C25.081 22.5297 24.2223 22.448 23.3773 22.3662C10.2521 20.976 0.711248 16.8461 0.629395 11.953V11.885C0.629395 5.8333 15.1177 0.912964 33.0002 0.912964C43.795 0.912964 53.3495 2.71213 59.2375 5.46528C62.9175 7.18259 65.1664 9.2952 65.3435 11.5715C65.3708 11.6669 65.3708 11.7758 65.3708 11.885Z"
        fill="#CEDCE4"
      />
      <path
        d="M54.6676 26.9727V31.9784C52.0599 32.7669 49.0681 33.4147 45.7949 33.8864V28.8806C49.0682 28.4089 52.0599 27.7612 54.6676 26.9727Z"
        fill="#CEDCE4"
      />
    </svg>
  );
};

export default EmptyData3;
