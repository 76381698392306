import { Box } from '@material-ui/core'
import React from 'react'
import PropsType from 'prop-types';

const CustumTooltip = ({Children, content}) => {
  return (
    <Box component="span"
                      className="tooltipContainer pasEncore"
                      sx={{
                        position: 'relative',
                        "&:before":{
                            borderBottomColor:"#5c5e63 !important"
                        },
                        "&:hover:before":{
                            top:"12px !important"
                        },
                        "&:hover .tooltip":{
                            top:"30px !important"
                        },
                      }}
                    >
                      {Children}
                      <Box
                        sx={{
                          backgroundColor: "#5c5e63",
                          color: 'white',
                          position: 'absolute',
                          padding: '10px',
                          textAlign: 'center',
                          width: 'max-content',
                          fontSize: '14px',
                          fontWeight: '300',
                          top: '18px',
                          left: '50%',
                          opacity: '0',
                          borderRadius: '5px',
                          transition: '.2s top ease',
                          transform: 'translateX(-50%)',
                          pointerEvents: 'none',
                          zIndex: 10,
                        }}
                        className="tooltip"
                      >
                        {content}
                      </Box>
                    </Box>
  )
}
CustumTooltip.propTypes = {
    Children: PropsType.any.isRequired,
    content: PropsType.any.isRequired,
  };
export default CustumTooltip