import React from 'react'

const TicketRestau = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ticket-svgrepo-com 1">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2652 17.7575L15.2592 19.8438C15.2577 20.3336 15.257 20.5784 15.1042 20.7305C14.9514 20.8825 14.7059 20.8825 14.2149 20.8825H11.0791C7.14095 20.8825 5.17188 20.8825 3.94845 19.6621C3.10609 18.8219 2.84371 17.6289 2.762 15.6851C2.74579 15.2997 2.73768 15.1069 2.80966 14.9783C2.88165 14.8497 3.16903 14.6893 3.74377 14.3682C4.38208 14.0118 4.81355 13.3307 4.81355 12.5492C4.81355 11.7676 4.38208 11.0865 3.74377 10.7301C3.16903 10.4091 2.88165 10.2486 2.80966 10.12C2.73768 9.99142 2.74579 9.79867 2.762 9.41318C2.84371 7.46935 3.10609 6.27648 3.94845 5.43621C5.17188 4.21582 7.14096 4.21582 11.0791 4.21582H14.7356C15.0233 4.21582 15.2568 4.44809 15.2576 4.73514L15.2652 7.34082C15.2652 7.91611 15.7327 8.38249 16.3095 8.38249V10.4658C15.7327 10.4658 15.2652 10.9322 15.2652 11.5075V13.5908C15.2652 14.1661 15.7327 14.6325 16.3095 14.6325V16.7158C15.7327 16.7158 15.2652 17.1822 15.2652 17.7575Z"
          fill="#393B3F"
        />
        <path
          id="Vector_2"
          opacity="0.5"
          d="M16.3096 16.7158C16.8863 16.7158 17.3538 17.1822 17.3538 17.7575V19.8163C17.3538 20.3179 17.3538 20.5687 17.5147 20.7225C17.6755 20.8763 17.9215 20.8659 18.4137 20.8451C20.3543 20.7629 21.5465 20.5004 22.3869 19.6621C23.2293 18.8219 23.4917 17.6289 23.5733 15.6852C23.5896 15.2997 23.5977 15.1069 23.5257 14.9783C23.4537 14.8497 23.1663 14.6893 22.5915 14.3683C21.9533 14.0119 21.5218 13.3307 21.5218 12.5492C21.5218 11.7676 21.9533 11.0865 22.5915 10.7301C23.1663 10.4091 23.4537 10.2486 23.5257 10.12C23.5977 9.99144 23.5896 9.79869 23.5733 9.4132C23.4917 7.46937 23.2293 6.2765 22.3869 5.43622C21.4726 4.52421 20.1419 4.29377 17.8842 4.23553C17.5929 4.22802 17.3538 4.4629 17.3538 4.75353V7.34084C17.3538 7.91614 16.8863 8.38251 16.3096 8.38251V10.4658C16.8863 10.4658 17.3538 10.9322 17.3538 11.5075V13.5908C17.3538 14.1661 16.8863 14.6325 16.3096 14.6325V16.7158Z"
          fill="#393B3F"
        />
      </g>
    </svg>
  )
}

export default TicketRestau