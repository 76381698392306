/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'; // Importez le module de stockage Firebase

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
    appId: '1:462747418946:web:53776799ba69d6eb9b48db',
    messagingSenderId: '462747418946',
    projectId: 'prochaine-backend',
    authDomain: 'prochaine-backend.firebaseapp.com',
    databaseURL: 'https://prochaine-backend-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'prochaine-backend.appspot.com',
    measurementId: 'G-NWC6XT30W2',
};

// Initialisez Firebase avec la configuration
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Exportez les instances Firestore et Storage
export const firestore = firebase.firestore();
export const storage = firebase.storage(); // Exportez l'instance de stockage Firebase

