import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import Calendar from './dashboardRestau-components/Calendar';
import SelectRestau from './dashboardRestau-components/SelectRestau';
import authService from '../../services/auth.service';
import DashboardRestau from './DashboardRestau';
import DashboardFranchise from './DashboardFranchise';
import PageContainer from '../../components/container/PageContainer';


const RealtimeDashboard = () => {
  const currentDate = new Date();
  const [forDay, setForDay] = useState(true);
  const [date, setDate] = useState(currentDate);
  const currentUser = authService.getCurrentUser();
  const [selectedRestau, setSelectedRestau] = useState(null);
  return (
    <PageContainer title="Realtime dashboard" description="Realtime dashboard">
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& .block': {
          background: { xs: 'white ' },
          borderRadius: { xs: '8px ' },
          padding: { xs: '14px', md:"20px" },
          boxShadow: {
            xs: '0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
          },
        },
      }}
    >
      {/* head  */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 12,
        }}
        className="head"
      >
        <Box sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems:"center",
          gap:"8px"
        }}><Box
          sx={{
            
            color: (t) => t.palette.text.textDark,
            fontSize: '24px',
            fontWeight: '700',
          }}
          className="left"
        >
          Dashboard
        </Box>
        
        
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md:"70%", xl: '50%' },
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: '20px',

          }}
          className="right"
        >
          {currentUser?.roles !== 'moderator' && (
            <SelectRestau selectedRestau={selectedRestau} setSelectedRestau={setSelectedRestau} />
          )}
          <Calendar date={date} setDate={setDate} forDay={forDay} setForDay={setForDay} />
        </Box>
      </Box>
      {/* !head  */}
      {currentUser?.roles === 'moderator' || selectedRestau ? (
        <DashboardRestau
          date={date}
          forDay={forDay}
          currentUser={
            selectedRestau
              ? { siret: selectedRestau?.restos?.siret, roles: 'moderator', id:selectedRestau?.restos?.id }
              : currentUser
          }
        />
      ) : (
        <DashboardFranchise 
        date={date}
        forDay={forDay}
        currentUser={currentUser} />
      )}
    </Box>
    </PageContainer>
  );
};

export default RealtimeDashboard;
