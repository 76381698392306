import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography, Grid } from '@material-ui/core';

const DoughnutChart = () => {
  // 1
  const optionsdoughnutchart = {
    chart: {
      id: 'donut-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
        },
      },
    },
    legend: {
      show: true,
      position: 'left',
      width: '50px',
    },
    colors: ['#6ac3fd', '#0b70fb', '#f64e60', '#26c6da', '#ffa800', '#BF974D'],
    tooltip: {
      theme: 'dark',
      fillSeriesColor: false,
    },
  };
  const seriesdoughnutchart = [45, 15, 27, 18, 35, 65];


  return (
    <>

      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant="outlined">
            <Box p={2} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h4">Camembert CA Franchise Par Catégorie Produit</Typography>
              </Box>
            </Box>
            <CardContent>
              <Chart
                options={optionsdoughnutchart}
                series={seriesdoughnutchart}
                type="donut"
                height="300px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>

  );
};

export default DoughnutChart;
