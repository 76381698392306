import React from 'react'

const ErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-5.0 -10.0 110.0 135.0">
 <path d="m16.668 99.168h66.664c4.1992-0.007813 8.2227-1.6758 11.191-4.6445s4.6367-6.9922 4.6445-11.191v-66.664c-0.007813-4.1992-1.6758-8.2227-4.6445-11.191s-6.9922-4.6367-11.191-4.6445h-66.664c-4.1992 0.007813-8.2227 1.6758-11.191 4.6445s-4.6367 6.9922-4.6445 11.191v66.664c0.007813 4.1992 1.6758 8.2227 4.6445 11.191s6.9922 4.6367 11.191 4.6445zm-10.836-82.5c0.007813-5.9805 4.8555-10.828 10.836-10.836h66.664c5.9805 0.007813 10.828 4.8555 10.836 10.836v7.5h-88.336zm0 12.5h88.332l0.003907 54.164c-0.007813 5.9805-4.8555 10.828-10.836 10.836h-66.664c-5.9805-0.007813-10.828-4.8555-10.836-10.836z"/>
 <path d="m18.965 11.23c-1.5273 0-2.9023 0.91797-3.4844 2.3281-0.58203 1.4062-0.26172 3.0273 0.81641 4.1055 1.0781 1.0781 2.6992 1.4023 4.1094 0.82031 1.4102-0.58594 2.3281-1.9609 2.3281-3.4844 0-2.082-1.6875-3.7695-3.7695-3.7695z"/>
 <path d="m36.293 15c0-1.5273-0.91797-2.9023-2.3281-3.4883-1.4102-0.58594-3.0312-0.26172-4.1133 0.81641-1.0781 1.0781-1.4023 2.7031-0.81641 4.1133 0.58203 1.4102 1.957 2.3281 3.4844 2.3281 1 0 1.9609-0.39453 2.668-1.1016s1.1055-1.668 1.1055-2.668z"/>
 <path d="m60.668 17.5h24.137c1.3789 0 2.5-1.1211 2.5-2.5s-1.1211-2.5-2.5-2.5h-24.137c-1.3828 0-2.5 1.1211-2.5 2.5s1.1172 2.5 2.5 2.5z"/>
 <path d="m50 84.715c6.1133 0 11.977-2.4297 16.301-6.7539 4.3203-4.3203 6.75-10.184 6.75-16.297 0-6.1172-2.4297-11.977-6.7539-16.301s-10.188-6.75-16.301-6.75c-6.1133 0-11.973 2.4297-16.297 6.7539-4.3242 4.3242-6.75 10.188-6.75 16.301 0.007812 6.1094 2.4375 11.969 6.7617 16.289 4.3203 4.3203 10.18 6.75 16.289 6.7578zm0-41.098c4.7891 0 9.3789 1.9023 12.762 5.2852 3.3867 3.3867 5.2891 7.9766 5.2891 12.766 0 4.7852-1.9023 9.375-5.2891 12.762-3.3828 3.3867-7.9727 5.2852-12.762 5.2852s-9.3789-1.8984-12.762-5.2852c-3.3867-3.3867-5.2891-7.9766-5.2891-12.762 0.007812-4.7852 1.9102-9.375 5.293-12.758 3.3828-3.3867 7.9727-5.2891 12.758-5.293z"/>
 <path d="m40.668 71.113c0.98047 0.96875 2.5625 0.95703 3.5312-0.023437l5.8008-5.8672 5.793 5.8672c0.96875 0.98438 2.5547 0.99219 3.5352 0.023437 0.98438-0.96875 0.99609-2.5547 0.023437-3.5352l-5.8398-5.9102 5.8398-5.9141c0.97266-0.98438 0.96094-2.5664-0.023437-3.5391-0.98047-0.96875-2.5664-0.95703-3.5352 0.023437l-5.793 5.8711-5.7969-5.8711c-0.97656-0.95312-2.5352-0.94922-3.5078 0.007813-0.96875 0.96094-0.99219 2.5195-0.046874 3.5078l5.8398 5.9141-5.8398 5.9102c-0.46875 0.46875-0.72656 1.1094-0.72266 1.7695 0 0.66406 0.26953 1.3008 0.74219 1.7656z"/>
</svg>
  )
}

export default ErrorIcon