import http from "../http-common";

const getAll = () => {
  return http.get("/produit");
};

const get = id => {
  return http.get(`/produit/${id}`);
};

const getOneCat = categorieProduitID => {
  return http.get(`/produit/findOneCat/${categorieProduitID}`);
};

const create = data => {
  return http.post("/produit", data);
};

const update = (id, data) => {
  return http.put(`/produit/${id}`, data);
};

const remove = id => {
  return http.delete(`/produit/${id}`);
};

const removeAll = () => {
  return http.delete(`/produit`);
};

const findByTitle = login => {
  return http.get(`/produit?login=${login}`);
};

const findBycategorieProduitID = categorieProduitID => {
  return http.get(`/produit/published/?categorieProduitID=${categorieProduitID}`);
};

export default {
  getAll,
  get,
  getOneCat,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  findBycategorieProduitID
};
