import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
// import { fr } from 'date-fns/locale';
import { Box, useMediaQuery } from '@material-ui/core';
import { ArrowBack, ArrowForward, KeyboardArrowDown} from '@material-ui/icons';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import {Calendar as Calendar2 }from 'react-calendar';
// import CalendarIcon from '../../../components/icons/CalendarIcon';
// import { CalendarViewMonth } from '@mui/icons-material';
// import { CalendarIcon } from '@mui/x-date-pickers';

const Calendar = ({ date, setDate, forDay, setForDay }) => {
  // const [openCalendar,setOpenCalendar ]=useState(false)
  // const [value, onChange]=useState(new Date())
  const currentDate = new Date();
  const incrementDate = () => {
    const newDate = new Date(date);
    if (newDate.getTime() < new Date().setHours(0, 0, 0, 0)) {
      if (forDay) {
        setDate(new Date(newDate.setDate(newDate.getDate() + 1)));
      } else {
        setDate(new Date(newDate.setMonth(newDate.getMonth() + 1)));
      }
    }
  };
  const decrementDate = () => {
    const newDate = new Date(date);
    if (forDay) {
      setDate(new Date(newDate.setDate(newDate.getDate() - 1)));
    } else {
      setDate(new Date(newDate.setMonth(newDate.getMonth() - 1)));
    }
  };
  const handleDrawerClose2yClick=(date2)=>{
    if (new Date(date2).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
    setDate(date2)
    }
  }
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const dateMins1 = forDay
    ? new Date(new Date(date).setDate(date.getDate() - 1))
    : new Date(new Date(date).setMonth(date.getMonth() - 1));
  const datePlus1 = forDay
    ? new Date(new Date(date).setDate(date.getDate() + 1))
    : new Date(new Date(date).setMonth(date.getMonth() + 1));
  const dateMins2 = forDay
    ? new Date(new Date(date).setDate(date.getDate() - 2))
    : new Date(new Date(date).setMonth(date.getMonth() - 2));
  const datePlus2 = forDay
    ? new Date(new Date(date).setDate(date.getDate() + 2))
    : new Date(new Date(date).setMonth(date.getMonth() + 2));
  const dateMins3 = forDay
    ? new Date(new Date(date).setDate(date.getDate() - 3))
    : new Date(new Date(date).setMonth(date.getMonth() - 3));
  const datePlus3 = forDay
    ? new Date(new Date(date).setDate(date.getDate() + 3))
    : new Date(new Date(date).setMonth(date.getMonth() + 3));
  const displayDay = (date2) => {
    let formatedDate;
    if (forDay) {
      if (
        date2.getFullYear() === currentDate.getFullYear() &&
        date2.getMonth() === currentDate.getMonth() &&
        date2.getDate() === currentDate.getDate()
      ) {
        formatedDate = isMobile ? "Aujourd'hui" : moment(new Date(date2)).format('DD');
      } else {
        formatedDate = moment(new Date(date2)).format('DD');
      }
    } else if (
      date2.getFullYear() === currentDate.getFullYear() &&
      date2.getMonth() === currentDate.getMonth()
    ) {
      formatedDate = isMobile ? 'Ce Mois' : moment(new Date(date2)).format('MMM');
    } else {
      formatedDate = moment(new Date(date2)).format(isMobile ? 'MMMM' : 'MMM');
    }
    return formatedDate;
  };
  const [fullCalendarDisplayed, setFullCalendarDisplayed]=useState(false)
  console.log("date=>",date)
  const handleMonthClick = (value) => {
    setDate(value); 
    setFullCalendarDisplayed(false); 
  };
  return (
    <Box
      sx={{
        width: { xs: '100%', md: '70%' },
        position: 'relative',
        zIndex: '37',
        '& .bottom': {
          maxHeight: {md:'0px'},
          // maxHeight: '50vh',
        },
        '&:hover .bottom': {
          maxHeight: '50vh',
        },
      }}
      className="right block"
    >
      {/* <Box sx={{
        "& svg":{
          fill:t=>t.palette.mainColor,
          cursor:"pointer",
        },
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }} className="head"><CalendarIcon onClick={()=>setOpenCalendar(!openCalendar)} sx={{
        fontSize:"20px",
        width:"30px",
        height:"30px",
      }}/></Box> */}
      <Box
        sx={{
          width: '100%',
          height: '35px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: forDay ? 0 : '50%',
            transition: 'left 0.4s cubic-bezier(0.68,-0.55,0.265,1.55)',
            backgroundColor: (t) => t.palette.mainColor,
            width: '50%',
            height: '2px',
          },
          '& .tab': {
            cursor: 'pointer',
            transition: 'left 0.4s ease',
            display: 'flex',
            width: '100%',
            justifyContent: { xs: 'center', md: 'space-between' },
            fontSize: { xs: '18px', md: '14px' },
          },
          '& .tab:first-child': {
            borderRight: '1px solid lightgray',
            paddingRight: '12px',
          },
          '& .tab:last-child': {
            paddingLeft: '12px',
          },
          '& .selectedDate': {
            display: { xs: 'none', md: 'flex' },
            color: (t) => t.palette.mainColor,
          },
        }}
        className="tabs"
      >
        <Box
          sx={{
            color: forDay ? (t) => t.palette.text.textMid : (t) => t.palette.text.textDark,
            fontWeight: forDay ? '700' : '400',
            opacity: forDay ? '1' : '0.5',
          }}
          onClick={() => {
            setForDay(true);
            // setOpenCalendar(!openCalendar)
          }}
          className="tab"
        >
          Jours
          <Box className="selectedDate">{dateFormat(date, 'dd mmm')}</Box>
        </Box>
        <Box
          sx={{
            color: !forDay ? (t) => t.palette.text.textMid : (t) => t.palette.text.textDark,
            fontWeight: !forDay ? '700' : '400',
            opacity: !forDay ? '1' : '0.5',
          }}
          onClick={() => {
            setForDay(false);
            // setOpenCalendar(!openCalendar)
          }}
          className="tab"
        >
          Mois
          <Box className="selectedDate">{date.getFullYear()}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: { md: 'absolute' },
          width: '100%',
          background: { md: 'white' },
          borderRadius: '0 0 8px 8px',
          left: '0',
          top: 'calc(100% - 6px)',
          boxShadow: {
            md: '0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 3px 4px 0px rgba(0, 0, 0, 0.06)',
          },
          transition: 'max-height .4s ease',
          '& .arrow': {
              backgroundColor: 'transparent',
              color: (t) => t.palette.text.textMid,
              borderRadius: '8px',
              padding: '2px',
              fontSize: '27px',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
              '&:hover': {
                backgroundColor: (t) => t.palette.mainColor,
                color: 'white',
              },
            },
          overflow: 'hidden',
        }}
        className="bottom"
      >
        <Box
          sx={{
            display: !fullCalendarDisplayed ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'space-around',
            py: {xs:'18px', md:'8px'},
            
            '& .day': {
              color: (t) => t.palette.text.textDark,
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '28px',
              letterSpacing: '0.2px',
              textAlign: 'center',
              cursor: 'pointer',
              textTransform: 'capitalize',
              '&.selected': {
                backgroundColor: (t) => t.palette.lightMainColor,
                padding: '4px 12px',
                color: (t) => t.palette.mainColor,
                borderRadius: '6px',
              },
              '& .xsHide': {
                display: { xs: 'none', md: forDay && 'block' },
                fontSize: '12px',
                color: (t) => t.palette.Soft,
              },
              '&.xsHide2': {
                display: { xs: 'none', md: 'block' },
              },
            },
          }}
          className="calendar"
        >
          <ArrowBack onClick={decrementDate} className="arrow" />

          <Box onClick={() => handleDrawerClose2yClick(dateMins3)} className="day xsHide2">
            <Box className="xsHide">{moment(dateMins3).format('ddd').split('.')[0]}</Box>
            {displayDay(dateMins3)}
          </Box>

          <Box onClick={() => handleDrawerClose2yClick(dateMins2)} className="day xsHide2">
            <Box className="xsHide">{moment(dateMins2).format('ddd').split('.')[0]}</Box>
            {displayDay(dateMins2)}
          </Box>
          <Box onClick={() => handleDrawerClose2yClick(dateMins1)} className="day">
            <Box className="xsHide">{moment(dateMins1).format('ddd').split('.')[0]}</Box>
            {displayDay(dateMins1)}
          </Box>

          <Box onClick={() => handleDrawerClose2yClick(date)} className="day selected">
            <Box className="xsHide">{moment(date).format('ddd').split('.')[0]}</Box>
            {displayDay(date)}
          </Box>

          <Box
            sx={{
              opacity:
                !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                '.5',
              cursor:
                !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'not-allowed !important',
              backgroundColor:
                !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'transparent !important',
              color:
                !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'darkgray !important',
            }}
            onClick={() => handleDrawerClose2yClick(datePlus1)}
            className="day"
          >
            <Box
              sx={{
                opacity:
                  !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                  '.5',
                cursor:
                  !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                  'not-allowed !important',
                backgroundColor:
                  !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                  'transparent !important',
                color:
                  !(new Date(datePlus1).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                  'darkgray !important',
              }}
              className="xsHide"
            >
              {moment(datePlus1).format('ddd').split('.')[0]}
            </Box>
            {displayDay(datePlus1)}
          </Box>
          <Box
            sx={{
              opacity:
                !(new Date(datePlus2).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                '.5',
              cursor:
                !(new Date(datePlus2).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'not-allowed !important',
              backgroundColor:
                !(new Date(datePlus2).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'transparent !important',
              color:
                !(new Date(datePlus2).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'darkgray !important',
            }}
            onClick={() => handleDrawerClose2yClick(datePlus2)}
            className="day xsHide2"
          >
            <Box className="xsHide">{moment(datePlus2).format('ddd').split('.')[0]}</Box>
            {displayDay(datePlus2)}
          </Box>

          <Box
            sx={{
              opacity:
                !(new Date(datePlus3).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                '.5',
              cursor:
                !(new Date(datePlus3).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'not-allowed !important',
              backgroundColor:
                !(new Date(datePlus3).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'transparent !important',
              color:
                !(new Date(datePlus3).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) &&
                'darkgray !important',
            }}
            onClick={() => handleDrawerClose2yClick(datePlus3)}
            className="day xsHide2"
          >
            <Box className="xsHide">{moment(datePlus3).format('ddd').split('.')[0]}</Box>
            {displayDay(datePlus3)}
          </Box>

          <ArrowForward
            sx={() => {
              if (!(date.getTime() < new Date().setHours(0, 0, 0, 0))) {
                return {
                  opacity: '.5',
                  cursor: 'not-allowed !important',
                  backgroundColor: 'transparent !important',
                  color: 'darkgray !important',
                };
              }
              return null;
            }}
            onClick={incrementDate}
            className="arrow"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            '& .react-calendar': {
              width: '100%',
              border: 'none',
              px:"20px",
              "& .react-calendar__navigation__arrow:hover":{
                background:"none"
              },
              "& .react-calendar__navigation__label":{
                cursor:"default",
                pointerEvents:"none",
                fontSize:"14px",
                fontWeight:"500",
                color:"#454545"
              },
              "& .react-calendar__month-view__weekdays__weekday abbr":{
                textDecoration:"none !important",
                textTransform: 'capitalize',
                fontSize:"12px",
                color:"darkgray",
                fontWeight:400
              },
              "& .react-calendar__tile--now":{
                backgroundColor:"#d3d3d345",
                borderRadius:"6px"
              },
              "& .react-calendar__tile--active, .react-calendar__tile--hasActive":{
                backgroundColor: (t) => t.palette.lightMainColor,
                padding: '4px 12px',
                color: (t) => t.palette.mainColor,
                borderRadius: '6px',
              },
              "& .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile":{
                flex:"0 0 25% !important"
              },
              "& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus":{
                backgroundColor: (t) => t.palette.lightMainColor,
                color: (t) => t.palette.mainColor,
                borderRadius: '6px',
              },
              "& .react-calendar__month-view__days__day--weekend":{
                color: (t) => t.palette.text.textDark,
              },
              
              "& .react-calendar__tile:disabled":{
                backgroundColor:"transparent",
                color:"lightgray"
              },
              "& .react-calendar__navigation button:disabled":{
                backgroundColor:"transparent",
                "& .arrow":{
                  opacity: '.5',
                  cursor: 'not-allowed !important',
                  backgroundColor: 'transparent !important',
                  color: 'darkgray !important',
                }
              },
              "& .react-calendar__navigation__label:hover":{
                background:"none",
              },
              "& .react-calendar__navigation":{
                mb:"0"
              },
              "& .react-calendar__navigation__prev2-button":{
                display:"none"
              },
              "& .react-calendar__navigation__next2-button":{
                display:"none"
              },
            },
          }}
        >
          <Box
            sx={{
              display: fullCalendarDisplayed ? 'flex' : 'none',
            }}
          >
            <Calendar2 
            onClickDay={()=>setFullCalendarDisplayed(false)}
            navigationLabel={null}
            maxDate={new Date()}
            nextLabel={<ArrowForward className='arrow'/>} 
            prevLabel={<ArrowBack className='arrow'/>} 
            locale="fr-FR" 
            onClickMonth={handleMonthClick}  
            view={forDay ? "month" : "year"}  
            onChange={setDate} value={date} />
          </Box>

          <Box
            sx={{
              // backgroundColor:"red",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              py: '4px',
              mt:fullCalendarDisplayed && '10px',
              cursor: 'pointer',
              fontSize: '13px',
              fontWeight: '500',
              color: (t) => t.palette.text.textMid2,
              fontFamily: '"Jost", sans-serif',
               backgroundColor: (t) => t.palette.lightMainColor,
            }}
            onClick={() => setFullCalendarDisplayed(!fullCalendarDisplayed)}
          >
            Voir
            {fullCalendarDisplayed ? ' moins' : ' plus'}
            <KeyboardArrowDown
              sx={{
                transform: fullCalendarDisplayed && 'rotate(180deg)',
                transition: 'all .4s ease',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
Calendar.propTypes = {
  date: PropTypes.any.isRequired,
  setDate: PropTypes.any.isRequired,
  forDay: PropTypes.any,
  setForDay: PropTypes.any,
};
export default Calendar;
