import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import AuthService from "../../../services/auth.service";
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const rows = [
//   {
//     idcmd: 'CMD1678307584774',
//     type_discount: 'Somme',
//     discount: '5,25€',
//     total_real: '432,5€',
//     total: '338,25€',
//     type_cmd: 'Sur place',
//     discount_label: 'Réduction',
//     cashier: 'Osama',
//     source: 'Caisse',
//     date: '12 Jun, 2021 09:51:40',
//     reduction: 14
//   },
//   {
//     idcmd: 'CMD1678307444584',
//     type_discount: 'Pourcentage',
//     discount: '4,25€',
//     total_real: '42,5€',
//     total: '38,25€',
//     type_cmd: 'A Emporter',
//     discount_label: 'DINOS',
//     cashier: 'Abdoulaye',
//     source: 'Borne',
//     date: '10 Jun, 2021 09:51:40',
//     reduction: 12
//   },
//   {
//     idcmd: 'CMD1678307584774',
//     type_discount: 'Somme',
//     discount: '5,25€',
//     total_real: '432,5€',
//     total: '338,25€',
//     type_cmd: 'Sur place',
//     discount_label: 'Réduction',
//     cashier: 'Osama',
//     source: 'Caisse',
//     date: '12 Jun, 2021 09:51:40',
//     reduction: 14
//   },
//   {
//     idcmd: 'CMD1678307444584',
//     type_discount: 'Pourcentage',
//     discount: '4,25€',
//     total_real: '42,5€',
//     total: '38,25€',
//     type_cmd: 'A Emporter',
//     discount_label: 'DINOS',
//     cashier: 'Aminata',
//     source: 'Borne',
//     date: '10 Jun, 2021 09:51:40',
//     reduction: 12
//   },
//   {
//     idcmd: 'CMD1678307584774',
//     type_discount: 'Somme',
//     discount: '5,25€',
//     total_real: '432,5€',
//     total: '338,25€',
//     type_cmd: 'Sur place',
//     discount_label: 'Réduction',
//     cashier: 'Osama',
//     source: 'Caisse',
//     date: '12 Jun, 2021 09:51:40',
//     reduction: 14
//   },
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));


const TableProduct = ({datedebut, datefin}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);


  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [rows, setRows] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const getListeRestoParams = () => {
    axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`)
      .then(response => {
      setMesRestoParams(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  useEffect(() => {
    if(UserRoles==='guest'){
    getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscount = () => {
    if(UserRoles==='guest'){
        axios.post(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/ListCommandesDiscountGuest/${currentUser?.id}&${datedebut}&${datefin}`, mesRestoParams)
          .then(response => {
            setRows(response.data);
          })
          .catch(e => {
            console.log(e);
          });
        }
        else {
          axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/ListCommandesDiscount/${currentUser?.id}&${datedebut}&${datefin}`)
            .then(response => {
              setRows(response.data);
            })
            .catch(e => {
              console.log(e);
            });
        }
  };

  useEffect(() => {
    retrieveTypeDiscount();
  }, [currentUser?.id,datedebut, datefin, mesRestoParams]);


   const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
const currencyCode=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code
const currencySymbol=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol

  return (
    <>
      <Card variant="outlined">
        <CardContent>
        <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Table Classement Produit</Typography>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            <Table
              aria-label="custom pagination table"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Product Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Nombre de Produits Vendus</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>
                      <Chip
                        sx={{
                          backgroundColor: (theme) => theme.palette.warning.light,
                          color:(theme) => theme.palette.warning.dark,
                          borderRadius: '6px',
                          pl: '3px',
                          pr: '3px',
                        }}
                        size="small"
                        label={row.name}
                      />
                    </TableCell>

 

                    <TableCell>
                      <Typography color="textSecondary" variant="h6" fontWeight="400" align="right">
                      <Chip
                        sx={{
                          backgroundColor: (theme) => theme.palette.success.light,
                          color:(theme) => theme.palette.success.dark,
                          borderRadius: '6px',
                          pl: '3px',
                          pr: '3px',
                        }}
                        size="small"
                        label={ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,row.total)}
                      />
                      </Typography>
                    </TableCell>

    
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>

    </>
  );
};



export default TableProduct;

TableProduct.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
};