import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    listeLangues:[],
}
export const listeLanguesSlice = createSlice({
    name:"listeLangues",
    initialState,
    reducers:{
        setlisteLangues : (state,action)=>{
            state.listeLangues=action.payload
         }
    }
})
export const {
    setlisteLangues,
} = listeLanguesSlice.actions
export default listeLanguesSlice.reducer