import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    sizes:[],
    loadingSizes:false
}
export const TaillesSlice = createSlice({
    name:"tailles",
    initialState,
    reducers:{
        setSizes : (state,action)=>{
            state.sizes=action.payload
         },
         setLoadingSizes : (state,action)=>{
            state.loadingSizes=action.payload
         },
    }
})
export const {
    setSizes,
    setLoadingSizes
} = TaillesSlice.actions
export default TaillesSlice.reducer