import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from "../../../services/auth.service";




const  ColumnTempsSortieJour = ({datedebut, datefin, idResto}) => {
  const currentUser = AuthService.getCurrentUser();
  const [keysArray, setKeysArray] = useState([]);
  const [valuesArray, setValuesArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);


  
  const retrievePaymentMethods = () => {
    setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/GraphPerformancesTempsSortieByDay/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`)
          .then(response => {
            console.log("keys",response.data.keysArray,'values', response.data.valuesArray)
            setKeysArray(response.data.keysArray);
            setValuesArray(response.data.valuesArray);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
  };

  useEffect(() => {
    retrievePaymentMethods();
  }, [currentUser?.id,datedebut, datefin, idResto]);


  const optionscolumnchart = {
    chart: {
      id: 'column-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
      toolbar: {
        show: true,
      },
    },
    colors: ['#6ac3fd', '#0b70fb', '#f64e60'],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '80%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories : keysArray
      // categories : [...Array(31).keys()].slice(1)
    },
    yaxis: {
      title: {
        text: 'Temps de Sortie Moyen',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} minutes`;
        },
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
  };
  const seriescolumnchart = [
    {
      name: 'Temps de Sortie Moyen',
      data: valuesArray
    }
  ];

  return (
      <Card variant="outlined">
        <Box p={2} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4">Temps de Sortie Moyen Par jour
</Typography>
          </Box>
        </Box>

        {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size="50px" />
        </Box>
      )}
            {!isLoading && (
        <CardContent>
          <Chart
            options={optionscolumnchart}
            series={seriescolumnchart}
            type="bar"
            height="300px"
          />
        </CardContent> )}
      </Card>
  );
};

export default ColumnTempsSortieJour;

ColumnTempsSortieJour.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
