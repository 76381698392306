import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@material-ui/core';
import { setMessage } from '../../redux/alert/AlertSlice';

const CustomSnackbar = () => {
    const { message } = useSelector((state) => state.alert);
  const dispatch = useDispatch();
    const handleClose = (e) => {
        if (e === 'clickaway') {
          return;
        }
        dispatch(setMessage(null));
      };
  return message && (
        <Snackbar
          autoHideDuration={message?.autoHideDuration==="noune" ? null: 5000}
          open={message}
          onClose={handleClose}
          // anchorOrigin={{vertical:"bottom",horizontal:"right" }}
        >
          <Alert
            variant="filled"
            severity={message.type}
            style={{
              width: message?.width || '100%',
              borderRadius: '8px',
            }}
          >
            {message.content}
          </Alert>
        </Snackbar>
      )
  
}

export default CustomSnackbar