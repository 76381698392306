import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
// import authService from '../../../services/auth.service';
import { privateRequest } from '../../../requestMethods';
import InfoList from './additionalInfo-components/InfoList';
import Espece from '../../../components/icons/Espece';
import TicketRestau from '../../../components/icons/TicketRestau2';
import MoneyTransfert from '../../../components/icons/MoneyTransfert';
import CB from '../../../components/icons/CB';
import Caisse from '../../../components/icons/Caisse';
import Borne from '../../../components/icons/Borne';
import UberEats from '../../../components/icons/UberEats';
import Delivero from '../../../components/icons/Delivero';
import JustEat from '../../../components/icons/JustEat';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import CustumTooltip from '../../../components/custum-elements/CustumTooltip';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';

const AdditionalInfo = ({ title, date, forDay, currentUser }) => {
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const formattedDate = dateFormat(date, 'yyyy-mm-dd');
  const [sources, setSources] = useState({
    error: null,
    loading: false,
    data: [],
  });
  const [equipement, setequipement] = useState({
    error: null,
    loading: false,
    data: [],
  });
  const [modePaiment, setModePaiment] = useState({
    error: null,
    loading: false,
    data: [],
  });
  // const currentUser=authService.getCurrentUser()

  // const userId=currentUser?.roles==="moderator" ? currentUser?.siret : currentUser?.id
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const getSources = async () => {
    setSources({ ...sources, loading: true });
    setModePaiment({ ...modePaiment, loading: true });
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParSourceCommande/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setSources({
          loading: false,
          error: null,
          data: res.data,
        });
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParSourceCommande/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setSources({
          loading: false,
          error: null,
          data: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      setSources({
        loading: false,
        error:
          "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
        data: [],
      });
    }
  };
  const getequipement = async () => {
    setequipement({ ...equipement, loading: true });
    setModePaiment({ ...modePaiment, loading: true });
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParEquipement/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );

        setequipement({
          loading: false,
          error: null,
          data: res.data,
        });
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParEquipement/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setequipement({
          loading: false,
          error: null,
          data: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      setequipement({
        loading: false,
        error:
          "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
        data: [],
      });
    }
  };
  const getModePaiments = async () => {
    setModePaiment({ ...modePaiment, loading: true });
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParModesPaiement/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setModePaiment({
          loading: false,
          error: null,
          data: res.data,
        });
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParModesPaiement/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setModePaiment({
          loading: false,
          error: null,
          data: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      setModePaiment({
        loading: false,
        error:
          "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
        data: [],
      });
    }
  };
  // useEffect(() => {
  //   getSources();
  //   getModePaiments();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(
    () => {
      getSources();
      getModePaiments();
      if(currentUser?.roles === 'moderator'){
        getequipement()
      };
    },
    500,
    [date, currentUser?.siret, currentUser?.id, forDay],
  );
  const dispalyIcon = (name) => {
    let icon;
    switch (name) {
      case 'caisse':
        icon = <CB />;

        break;
      case 'Espece':
        icon = <Espece />;

        break;
      case 'Ticket Resto':
        icon = <TicketRestau />;

        break;

      default:
        icon = <MoneyTransfert />;
        break;
    }
    return icon;
  };
  const dispalyIconSources = (name) => {
    let icon;
    switch (name) {
      case 'caisse':
        icon = <Caisse />;

        break;
      case 'Borne':
        icon = <Borne />;

        break;
      case 'Uber Eats':
        icon = <UberEats />;

        break;
      case 'Delivero':
        icon = <Delivero />;
        break;
      case 'JustEat':
        icon = <JustEat />;

        break;

      default:
        icon = null;
        break;
    }
    return icon;
  };
  const formateddataSources = sources?.data?.map((el) => ({
    title: el?.sent_from,
    nbreCommandes: el?.nombrescommandes,
    montant: el?.ttcAmount,
    percent: el?.percentage,
    icon: dispalyIconSources(el?.sent_from),
  }));
  const formateddataequipement =
    equipement?.data?.data?.map((el) => ({
      title: el?.keenInfo?.name,
      nbreCommandes: el?.nombrescommandes,
      montant: el?.ttcAmount,
      percent: el?.percentage,
      id: el?.keenio_user_id,
      // icon:dispalyIconSources(el?.sent_from)
    })) || [];
  const formateddataPaimentMode = modePaiment?.data?.map((el) => ({
    title: el?.name,
    nbreCommandes: el?.nombrescommandes,
    montant: el?.ttcAmount,
    percent: el?.percentage,
    icon: dispalyIcon(el?.name),
  }));
  return (
    <Box>
      <Box
        sx={{
          fontSize: '18px',
          fontWeight: '600',
          mb: '20px',
          color: (t) => t.palette.text.textDark,
          '& .tooltipContainer:hover .tooltip': {
            // top: '-56px !important',
            right: 'unset',
            transform: 'translateX(-50%)',
            left: '50%',
            pointerEvents: 'all',
          },
          '& .tooltipContainer::after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '9px',
            left: '0',
            backgroundColor: 'transparent',
          },
        }}
        className="title"
      >
        {title}
        {title === 'Equipements' && (
          <CustumTooltip
            Children={
              <Box
                component="span"
                sx={{
                  textDecoration: 'underline',
                  color: (t) =>
                    t.palette[
                      equipement?.data?.RestoKeenioInfo?.keen_ids?.length >
                      formateddataequipement?.length
                        ? 'error'
                        : 'success'
                    ].main,
                  ml: '4px',
                  cursor: 'pointer',
                }}
              >
                ({equipement?.data?.RestoKeenioInfo?.keen_ids?.length})
              </Box>
            }
            content={
              <Box
                sx={{
                  fontSize: '12px',
                }}
              >
                {equipement?.data?.RestoKeenioInfo?.keen_ids
                  ?.sort(
                    (a, b) =>
                      formateddataequipement?.map((fel) => fel?.id)?.includes(b?.user_keen_id) -
                      formateddataequipement?.map((fel) => fel?.id)?.includes(a?.user_keen_id),
                  )
                  ?.sort(
                    (a, b) =>
                      formateddataequipement?.find((fel) => fel?.id === b?.user_keen_id)?.montant -
                      formateddataequipement?.find((fel) => fel?.id === a?.user_keen_id)?.montant,
                  )
                  .map((el) => (
                    <Box
                      sx={{
                        borderBottom: '1px solid lightgray',
                        py: '5px',
                        textAlign: 'left',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: '600',
                            textTransform: 'capitalize',
                          }}
                        >
                          {el?.name}
                        </Box>
                        {!formateddataequipement
                          ?.map((fel) => fel?.id)
                          ?.includes(el?.user_keen_id) ? (
                          <Box
                            sx={{
                              color: (t) => t.palette.error.main,
                              textAlign: 'right',
                              fontWeight: '500',
                            }}
                          >
                            Non remonté
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              color: (t) => t.palette.success.main,
                              textAlign: 'right',
                              fontWeight: '500',
                            }}
                          >
                            {ConvertirMonetaire(
                              currencyCode,
                              currencySymbol,
                              formateddataequipement?.find((fel) => fel?.id === el?.user_keen_id)
                                ?.montant,
                            )}
                          </Box>
                        )}
                      </Box>

                      <Box>{el?.user_keen_id}</Box>
                    </Box>
                  ))}
              </Box>
            }
          />
        )}
      </Box>
      <InfoList
        title={title}
        infos={
          title === 'Sources'
            ? { ...sources, data: formateddataSources }
            : title === 'Equipements'
            ? { ...equipement, data: formateddataequipement }
            : { ...modePaiment, data: formateddataPaimentMode }
        }
      />
    </Box>
  );
};
AdditionalInfo.propTypes = {
  title: PropTypes.any.isRequired,
  date: PropTypes.any.isRequired,
  forDay: PropTypes.any.isRequired,
  currentUser: PropTypes.any.isRequired,
};

export default AdditionalInfo;
