/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';
import UberEats from './UberEats';
import Dishop from './Dishop';
import ClickNCollect from './ClickNCollect';
import Deliveroo from './Deliveroo';

const Modules = ({
  selectedModule,
  modulesWithPos,
  siret,
  listEquipments,
  setListEquipments,
  openDialog,
  handleCloseDialog,
  handleSwitchChange,
}) => {
  return (
    <>
      {selectedModule?.module_name === 'Disho' ? (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Dishop />
        </Dialog>
      ) : selectedModule?.module_name === 'Uber Eats' ? (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <UberEats
            selectedModule={selectedModule}
            setListEquipments={setListEquipments}
            listEquipments={listEquipments}
            siret={siret}
            modulesWithPos={modulesWithPos}
            handleSwitchChange={handleSwitchChange}
            handleCloseDialog={handleCloseDialog}
          />
        </Dialog>
      ) : selectedModule?.module_name === 'Click & Collect' ? (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <ClickNCollect />
        </Dialog>
      ) : selectedModule?.module_name === 'Delivero' ? (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Deliveroo
            selectedModule={selectedModule}
            setListEquipments={setListEquipments}
            listEquipments={listEquipments}
            siret={siret}
            modulesWithPos={modulesWithPos}
            handleSwitchChange={handleSwitchChange}
            handleCloseDialog={handleCloseDialog}
          />
        </Dialog>
      ) : null}
    </>
  );
};

Modules.propTypes = {
  selectedModule: PropTypes.func,
  siret: PropTypes.func,
  openDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  handleSwitchChange: PropTypes.func,
  listEquipments: PropTypes.array,
  setListEquipments: PropTypes.array,
  modulesWithPos: PropTypes.array,
};

export default Modules;
