import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    printers:[],
    loadingPrinters:false
}
export const printersSlice = createSlice({
    name:"printers",
    initialState,
    reducers:{
        setPrinters : (state,action)=>{
            state.printers=action.payload
         },
         setLoadingPrinters : (state,action)=>{
            state.loadingPrinters=action.payload
         },
    }
})
export const {
    setPrinters,
    setLoadingPrinters
} = printersSlice.actions
export default printersSlice.reducer