import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    siretSiege:null,
    config: null,
    loading:false,
    error:null
}
export const carteSlice = createSlice({
    name:"carte",
    initialState,
    reducers:{
        setConfig : (state,action)=>{
           state.config=action.payload
        },
        // there is 3 cases for storageFile
        setStorageFileStart: (state) => {
            state.storageFile = null;
            state.loading = true;
            state.error = null;
          },
        setStorageFileSucess: (state, action) => {
            state.storageFile = action.payload;
            state.loading = false;
            state.error = null;
          },
        setStorageFileFailure: (state, action) => {
            state.storageFile = null;
            state.loading = false;
            state.error = action?.payload;
          },
    }
})
export const {
    setStorageFileStart,
    setStorageFileSucess,
    setStorageFileFailure,
    setConfig,
} = carteSlice.actions
export default carteSlice.reducer