import { Avatar, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropsType from 'prop-types';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
import { ConvertirMonetaire, addSpacesToNumber } from '../../../utility/utilityFunctions';
import { 
  privateRequest, 
   } from '../../../requestMethods';
import Error from '../../../components/custum-elements/Error';
import Loading from '../../../components/custum-elements/Loading';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import EmptyData from '../../../components/custum-elements/EmptyData';


const TopVentsProducts = ({date, forDay, currentUser}) => {
  const formattedDate=dateFormat(
    date,
    'yyyy-mm-dd',
  )  
  const [activeTab, setActiveTab] = useState('products');
  const [data, setData]=useState([])
  const [data2, setData2]=useState([])
  const [loading, setLoading]=useState(false)
  const [loading2, setLoading2]=useState(false)
  const [errorMessage, setErrorMessage]=useState(null)
  const [errorMessage2, setErrorMessage2]=useState(null)
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const getTopVentsProducts= async () => {
    setLoading(true)
    try {
      if(forDay){
      const res = await privateRequest.get(
        `/api/v2/dashboard/TopProductSales/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${currentUser?.roles === 'moderator' ? currentUser?.id : 0}`,
      );
      setData(res.data);
      }else{
        const res = await privateRequest.get(
          `/api/v2/dashboard/TopProductSales/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${currentUser?.roles === 'moderator' ? currentUser?.id : 0}`,
        );
        setData(res.data);
      }
      setErrorMessage(null)

    } catch (error) {
      console.log(error);
      setErrorMessage("Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.")
     setData([]) 
    }
    setLoading(false)
  };
  const getTopVentsCategories= async () => {
    setLoading2(true)
    try {
      if(forDay){
      const res = await privateRequest.get(
        `/api/v2/dashboard/TopCategoriesSales/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${currentUser?.roles === 'moderator' ? currentUser?.id : 0}`,
      );
      setData2(res.data);
      }else{
        const res = await privateRequest.get(
          `/api/v2/dashboard/TopCategoriesSales/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${currentUser?.roles === 'moderator' ? currentUser?.id : 0}`,
        );
        setData2(res.data);
      }
      setErrorMessage2(null)

    } catch (error) {
      console.log(error);
      setErrorMessage2("Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.")
     setData2([]) 
    }
    setLoading2(false)
  };
  // useEffect(() => {
  //   getTopVentsProducts();
  //   getTopVentsCategories();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(
    ()=>{
      getTopVentsProducts()
      getTopVentsCategories()
    },
    500,
    [date, currentUser?.siret, currentUser?.id, forDay]
  )
  const topVentsProductsData = data.map(element=>(
    {
      title: element.name,
      img: element.imageUrl,
      nbreSales: element.nombrescommandes,
      montant:element.ttcAmount,
      percent:element.pourcentage
    }
  ))
  const topVentsCategoriesData = data2.map(element=>(
    {
      title: element.category_name,
      img: element.imageUrl,
      nbreSales: element.nombrescommandes,
      montant:element.ttcAmount,
      percent:element.pourcentage
    }
  ))
  return (
    <Box mb={2}>
      <Box sx={{
        fontSize:"18px",
        fontWeight:"600",
        mb:"10px",
        textAlign:"center"
      }} className="title">Top Ventes </Box>
      <Box
          className="customPriceTop"
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            borderBottom: '1px solid lightgray',
            position: 'relative',
            // overflow: 'hidden',
            mb:"10px"
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '50%',
              height: '3px',
              backgroundColor: '#FF6346',
              left: activeTab === 'products' ? '0' : '50%',
              bottom:'calc(-3px / 2)',
              transition: 'left 0.4s cubic-bezier(0.68,-0.55,0.265,1.55)',
              borderRadius:"4px"
            }}
          />
          <Box
            sx={{
              cursor: 'pointer',
              width: '50%',
              textAlign: 'center',
              py: "10px",
              fontWeight: activeTab === 'categories' ? 400 : 600,
              zIndex: 1,
              color:t=> activeTab === 'categories' ? 'black' : t.palette.mainColor,
            }}
            onClick={() => setActiveTab('products')}
          >
            Produits
          </Box>
          <Box
            sx={{
              cursor: 'pointer',
              width: '50%',
              textAlign: 'center',
              py: "10px",
              fontWeight: activeTab === 'categories' ? 600 : 400,
              zIndex: 1,
              color:t=> activeTab === 'categories' ? t.palette.mainColor : 'black',
            }}
            onClick={() => setActiveTab('categories')}
          >
            Categories
          </Box>
        </Box>
      <Box sx={{
         width: '100%',
         overflow: 'hidden',
      }}>
        <Box sx={{
              width: '200%',
              display: 'flex',
              height: 'calc(100%)',
              overflowY: 'auto',
              transform: activeTab === 'categories' && 'translateX(-50%)',
              transition: 'all 0.4s cubic-bezier(0.68,-0.55,0.265,1.55)',
            }}>
              <Box
        sx={{
          // display: 'flex',
          // alignItems: 'center',
          // flexWrap: 'wrap',
          // flexDirection:"column",
          // columnGap: '8px',
          width: '100%',
        }}
        className="ventesList"
      >
        {loading ? 
       <Box
       sx={{
        width: '100%',
         height: '100%',
         '& svg': {
           fill: (t) => t.palette.mainColor,
         },
       }}
     >
       <Box
       sx={{
         width: '100%',
         height: '100%',
         display: 'flex',
         alignItems: 'center',
         flexDirection:"column",
         justifyContent: 'center',
       }}
     >
       <Loading type="bubbles" width={40} height={40} />
       <Typography sx={{
         fontSize:"13px",
         color: (t) => t.palette.mainColor,
         textAlign:"center",
         px:"10px",
         fontWeight:"500"
       }}>
       En cours de chargement...
       </Typography>
     </Box>
       
     </Box>
        :
        errorMessage ? <Error/>
        : topVentsProductsData?.length===0 ? 
        <Box sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          width:"100%"
        }}><EmptyData/></Box>
        : topVentsProductsData.map((item) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
              borderRadius: '6px',
              backgroundColor: '#f9fafb',
              mb: '10px',
            }}
            className="vente"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Avatar
                alt={item.title}
                src={item.img}
                sx={{
                  borderRadius: '6px',
                  width: '45px',
                  height: '45px',
                }}
              />
              <Box className="text">
                <Typography
                  sx={{
                    color: (t) => t.palette.text.textDark,
                    fontSize: '14px',
                  }}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    color: (t) => t.palette.Soft,
                    fontSize: '12px',
                    letterSpacing: ' 0.44px',
                  }}
                >{`${addSpacesToNumber(item.nbreSales)} Ventes`}</Box>
              </Box>
            </Box>
            <Box sx={{
              textAlign:"right"
            }}>
             <Box sx={{
              color:t=>t.palette.text.secondary,
              fontSize:"14px",
              fontWeight:700
            }}>{ConvertirMonetaire(currencyCode, currencySymbol, item.montant)}
            </Box> 
            <Box
                  sx={{
                    color:
                      item.percent > 0
                        ? (t) => t.palette.success.main
                        : (t) => t.palette.error.main,
                    fontSize: '15px',
                    fontWeight: 500,
                  }}
                  className="percent"
                >
                  {`${item.percent.toFixed(2)}%`}
                </Box>
            </Box>
            
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          // display: 'flex',
          // alignItems: 'center',
          // flexWrap: 'wrap',
          // flexDirection:"column",
          // columnGap: '8px',
          width: '100%',
        }}
        className="ventesList"
      >
        {loading2 ? 
        <Box
        sx={{
         width: '100%',
          height: '100%',
          '& svg': {
            fill: (t) => t.palette.mainColor,
          },
        }}
      >
        <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection:"column",
          justifyContent: 'center',
        }}
      >
        <Loading type="bubbles" width={40} height={40} />
        <Typography sx={{
          fontSize:"13px",
          color: (t) => t.palette.mainColor,
          textAlign:"center",
          px:"10px",
          fontWeight:"500"
        }}>
        En cours de chargement...
        </Typography>
      </Box>
        
      </Box>
        :
        errorMessage2 ? <Error/>
        : topVentsCategoriesData?.length===0 ? 
        <Box sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          width:"100%"
        }}><EmptyData/></Box>
        : topVentsCategoriesData.map((item) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
              borderRadius: '6px',
              backgroundColor: '#f9fafb',
              mb: '10px',
            }}
            className="vente"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Avatar
                alt="ImgProduct"
                src={item.img}
                sx={{
                  borderRadius: '6px',
                  width: '45px',
                  height: '45px',
                }}
              />
              <Box className="text">
                <Typography
                  sx={{
                    color: (t) => t.palette.text.textDark,
                    fontSize: '14px',
                  }}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    color: (t) => t.palette.Soft,
                    fontSize: '12px',
                    letterSpacing: ' 0.44px',
                  }}
                >{`${addSpacesToNumber(item.nbreSales)} Ventes`}</Box>
              </Box>
            </Box>
            <Box sx={{
              textAlign:"right"
            }}>
             <Box sx={{
              color:t=>t.palette.text.secondary,
              fontSize:"14px",
              fontWeight:700
            }}>{ConvertirMonetaire(currencyCode, currencySymbol, item.montant)}
            </Box> 
            <Box
                  sx={{
                    color:
                      item.percent > 0
                        ? (t) => t.palette.success.main
                        : (t) => t.palette.error.main,
                    fontSize: '15px',
                    fontWeight: 500,
                  }}
                  className="percent"
                >
                  {`${item.percent.toFixed(2)}%`}
                </Box>
            </Box>
          </Box>
        ))}
      </Box>
        </Box>
      </Box>
      
    </Box>
  );
};

TopVentsProducts.propTypes = {
  date: PropsType.any.isRequired,
  forDay: PropsType.any.isRequired,
  currentUser: PropsType.any.isRequired,
};
export default TopVentsProducts;
