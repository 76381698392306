import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { setMessage } from '../../redux/alert/AlertSlice';
import { firestore } from '../../firebase/firebase';

const DeleteRelation = ({ onClose, restautId, cardId, setRestaurantCards }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    console.log('restautId =>', restautId, 'cardId=>', cardId);
    setLoading(true);
    const db = firestore;
    const cardRef = doc(db, 'CartesCampagnesRestos', cardId);
    const docSnap = await getDoc(cardRef);

    console.log('cardRef=>', cardRef);
    try {
      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        console.log(
          'restaurantId',
          docSnap.data().restaurantIds.filter((el) => el !== restautId),
        );
        const updatedRestaurantIds = docSnap.data().restaurantIds.filter((el) => el !== restautId);
        const timestamp = new Date();
        const updateDocument = {
          ...docSnap.data(),
          restaurantIds: updatedRestaurantIds,
          updatedAt: timestamp,
        };
        await setDoc(doc(db, 'CartesCampagnesRestos', cardId), updateDocument);
       
      } else {
        console.log('No such document!');
      }
      console.log('Restaurant supprimé avec succès.');
      setRestaurantCards((prev) => ({
        ...prev,
        [restautId]: prev[restautId].filter((el) => el.id !== cardId),
      }));
      dispatch(
        setMessage({
          content: 'la suppression de la relation a été éffectué avec succes! ',
          type: 'success',
        }),
      );
    } catch (error) {
      console.error('Erreur lors de la suppression de la relation :', error);
      dispatch(
        setMessage({
          content: 'error',
          type: 'error',
        }),
      );
    }
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <Stack p={3}>
        <Grid spacing={2} container>
          <Grid dispaly="flex" textAlign="right" item xs={12}>
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} color="#ff7b00" />
          </Grid>
          <Grid item xs={12}>
            <Typography>Êtes-vous sûr de vouloir supprimer cette Relation ?</Typography>
          </Grid>
          <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
            <Button onClick={onClose} variant="outlined">
              Annuler
            </Button>
            <Button
              disabled={loading}
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
              }}
              onClick={handleDelete}
              variant="contained"
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
DeleteRelation.propTypes = {
  onClose: PropTypes.any.isRequired,
  restautId: PropTypes.any.isRequired,
  cardId: PropTypes.any.isRequired,
  setRestaurantCards: PropTypes.any.isRequired,
};
export default DeleteRelation;
