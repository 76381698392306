import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ThemeSettings from '../../../layouts/full-layout/customizer/ThemeSettings';
import PageContainer from '../../../components/container/PageContainer';
import ConfigGeneral from './ConfigGeneral';
import ConfigCategories from './ConfigCategories';
import ConfigOptions from './ConfigOptions';
import ConfigProduits from './ConfigProduits';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function SimpleTabs() {
  const classes = ThemeSettings();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs style={{backgroundColor: 'white'}} value={value} onChange={handleChange} aria-label="Biborne Tabs">
          <Tab label="Configuration générale" {...a11yProps(0)} />
          <Tab label="Configuration des catégories" {...a11yProps(1)} />
          <Tab label="Configuration des produits" {...a11yProps(2)} />
          {/* <Tab label="Options" {...a11yProps(3)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <PageContainer title="Configuration Générale" description="Configuration Générale">
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <ConfigGeneral />
      </Grid>
    </Grid>
  </PageContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <PageContainer title="Configuration Catégories" description="Configuration Catégories">
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <ConfigCategories />
      </Grid>
    </Grid>
  </PageContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <PageContainer title="Options" description="Options">
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <ConfigOptions />
      </Grid>
    </Grid>
  </PageContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <PageContainer title="Configuration des produits" description="Configuration des produits">
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {/* <ConfigItems /> */}
        <ConfigProduits />
      </Grid>
    </Grid>
  </PageContainer>
      </TabPanel>
    </div>
  );
}