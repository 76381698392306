import React, { 
  useRef, 
  useState, 
  useEffect 
} from 'react';
// import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { Paper, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Button, Grid } from '@mui/material';

const DetailsCommandeCnC = ({
  currencySymbol,
  detailsCnC
  
}) => {

  const [detailsCommande, setDetailsCommande] = useState(null);
  
  // const orderItemsTaxData = [];
  const [orderItemsTaxData, setorderItemsTaxData] = useState(null);

  useEffect(() => {
    if (detailsCnC) {
      setDetailsCommande(detailsCnC);
    }
  }, [detailsCnC]);

  
  const ConvertirMille = (value) => {
    const ValueParseFloat = parseFloat(value);
    const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
    return FinalValue;
  };

  
  useEffect(() => {
    // Vérifier que detailsCommande et detailsCommande.items sont définis
    if (detailsCommande && detailsCommande.items) {
      // Utiliser reduce pour regrouper les éléments par tva
      const groupedItems = detailsCommande.items.reduce((acc, item) => {
        const tvaKey = item.tva;
  
        // Si la clé tva n'existe pas dans l'accumulateur, l'initialiser
        if (!acc[tvaKey]) {
          acc[tvaKey] = {
            tax_rate: tvaKey,
            sumhtamount: 0,
            sumtaxamount: 0,
            sumttcamount: 0,
          };
        }
  
        // Ajouter les valeurs actuelles aux valeurs accumulées
        acc[tvaKey].sumhtamount += item.ht;
        acc[tvaKey].sumtaxamount += item.tvaAmount;
        acc[tvaKey].sumttcamount += item.priceQTotal;
  
        return acc;
      }, {});
  
      // Convertir l'objet en tableau
      const resultArray = Object.values(groupedItems);
      // console.log("resultArray", resultArray);
      setorderItemsTaxData(resultArray);
    }
  }, [detailsCommande]);
  

  // CMD1704025896631
// Calculer la somme des sumhtamount
const sumOfSumtaxamount = orderItemsTaxData?.reduce((sum, item) => sum + (item.sumtaxamount ? item.sumtaxamount : 0), 0);
const sumOfSumhtamount = orderItemsTaxData?.reduce((sum, item) => sum + (item.sumhtamount ? item.sumhtamount : 0), 0);
const sumOfSumttcamount = orderItemsTaxData?.reduce((sum, item) => sum + (item.sumttcamount ? item.sumttcamount : 0), 0);
// const sumOfSumtaxamount = 0;
// const sumOfSumhtamount = 0;
// const sumOfSumttcamount = 0;


const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  pageStyle: `
    @media print {
      body {
        margin: 0;
        padding: 0;
      }
      /* Ajoutez ici votre style d'impression personnalisé */
    }
  `,
});

  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center', lineHeight: '0.4', backgroundColor: '' }} ref={componentRef}>
      {detailsCnC ? (
        <>
          <Typography variant="h4" sx={{ fontWeight: 'bold', lineHeight: 2 }}>
            Détails de la commande
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            {/* <strong>Source:</strong> {detailsCommande?.orderData?.sent_from} */}
            <strong>Source:</strong> Click&Collect
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            <strong>Commande N°:</strong> {detailsCommande?.id_commande_local}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            <strong>Client:</strong> {detailsCommande?.client?.first_name} {detailsCommande?.client?.last_name} | {detailsCommande?.client?.phone}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            {/* <strong>Date et Heure:</strong> {moment(detailsCommande?.orderData?.paid_at).format('llll')} -{' '} */}
            {moment(detailsCommande?.created?.split('Z')[0]).format('ddd DD MMM yyyy HH:mm:ss')} -{' '}
            <strong>{detailsCommande?.type}</strong>
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    QTE
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    DESIGNATION
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    PRIX
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    TOTAL
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    TVA
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsCommande?.items?.map((item) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell sx={{ lineHeight: '0.1' }} align="left">
                        {item.quantity}
                      </TableCell>
                      <TableCell sx={{ lineHeight: item.product.length > 25 ? '1.3' : '0.1' }} align="left">
                        {item.product}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.1' }} align="right">
                        {ConvertirMille(item.productPrice)} {currencySymbol}
                      </TableCell>
                      <TableCell align="right">
                        {ConvertirMille(item.priceQTotal)} {currencySymbol}
                      </TableCell>
                      <TableCell align="right">{item.tva ? item.tva * 100 : 0}%</TableCell>
                    </TableRow>
                    {/* Options */}
                    {item?.items?.map((option) => (
                      <TableRow key={option?._id} sx={{ backgroundColor: '#edf2fb' }}>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography sx={{ lineHeight: '0.1' }} variant="caption">
                            {option?.quantity}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            (sub) {option?.name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} colSpan={3} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            {option.priceQTotal > 0 ? option?.priceQTotal + currencySymbol : '*'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}

                    {item?.supplements?.map((option) => (
                      <TableRow key={option?._id} sx={{ backgroundColor: '#fcfcf7' }}>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography sx={{ lineHeight: '0.1' }} variant="caption">
                            {option?.quantity}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            {option?.name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} colSpan={3} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            {option.price > 0 ? option?.price + currencySymbol : '*'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" align="left" sx={{ paddingTop: 2 }} gutterBottom>
            Nb. Article : {detailsCommande?.items?.length}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    TVA
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    MT. TVA
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    Base HT
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    Base TTC
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderItemsTaxData?.map((item) => (
                  <TableRow key={item.tax_rate}>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {item.tax_rate ? item.tax_rate * 100 : 0}%
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {ConvertirMille(item?.sumtaxamount ? item?.sumtaxamount : 0)} {currencySymbol}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(item?.sumhtamount ? item?.sumhtamount : 0)} {currencySymbol}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(item?.sumttcamount ? item?.sumttcamount : 0)} {currencySymbol}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: '#e9ecef' }}>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    Total
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    {ConvertirMille(sumOfSumtaxamount)} {currencySymbol}
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    {ConvertirMille(sumOfSumhtamount)} {currencySymbol}
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    {ConvertirMille(sumOfSumttcamount)} {currencySymbol}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <hr style={{ borderTop: '0px dashed #e9ecef', margin: '20px 0' }} />

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Total T.T.C.
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {ConvertirMille(detailsCommande?.total)} {currencySymbol}
            </Typography>
          </div>

          <hr style={{ borderBottom: '0px dashed #e9ecef', margin: '20px 0' }} />

            {Number(detailsCommande?.frais_livraison) > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Frais de livraison
              </Typography>
              <Typography variant="h6">
                {ConvertirMille(detailsCommande?.frais_livraison)} {currencySymbol}
              </Typography>
            </div>
          )}

          {Number(detailsCommande?.frais_gestion) > 0 && (
            <hr style={{ borderBottom: '0px dashed #e9ecef', margin: '20px 0' }} />
          )}

            {Number(detailsCommande?.frais_gestion) > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Frais de gestion
              </Typography>
              <Typography variant="h6">
                {ConvertirMille(detailsCommande?.frais_gestion)} {currencySymbol}
              </Typography>
            </div>
          )}

            {Number(detailsCommande?.discount) > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Réduction
              </Typography>
              <Typography variant="h6">
                {ConvertirMille(detailsCommande?.discount)} {currencySymbol}
              </Typography>
            </div>
          )}

          <Typography variant="h6" style={{ marginTop: '16px' }}>
            Mode de paiement
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>
                {detailsCommande?.payment_type && (
                  <TableRow>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {detailsCommande?.payment_type}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(detailsCommande?.total)} {currencySymbol}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* 
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Signature : BO413y7jg
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        N* de serie L 3544-560-502-615
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Version logiciel : 9.0.0.11
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Type d'operation : Vente
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Impression N* 1 - 2 ligne(s)
      </Typography> */}

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '16px' }}
            onClick={handlePrint}
          >
            Imprimer
          </Button>
        </>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '5px', textAlign: 'center', lineHeight: '0.8' }}>
            <p>Cliquez sur une commande pour voir les détails</p>
          </Paper>
        </Grid>
      )}
    </Paper>
  );
};

DetailsCommandeCnC.propTypes = {
    // orderDetails: PropTypes.any.isRequired,
    currencySymbol: PropTypes.any.isRequired,
    detailsCnC: PropTypes.any.isRequired,
};

export default DetailsCommandeCnC;
