import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import AuthService from "../../../services/auth.service";
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const ReductionByRestaurant = ({datedebut, datefin, idResto}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [restaurants, setRestaurants] = useState([]);

  // PROGRESS CIRCLE
  const [isLoading, setIsLoading] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - restaurants.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const getListeRestoParams = () => {
    axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`)
      .then(response => {
      setMesRestoParams(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  useEffect(() => {
    if(UserRoles==='guest'){
    getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscountByRestaurant = () => {
    if(UserRoles==='guest'){
        axios.post(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/ListCommandesDiscountByRestaurantGuest/${currentUser?.id}&${datedebut}&${datefin}`, mesRestoParams)
          .then(response => {
            setRestaurants(response.data);
          })
          .catch(e => {
            console.log(e);
          });
        }
        else {
          setIsLoading(true);
          axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/ListCommandesDiscountByRestaurant/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`)
            .then(response => {
              setRestaurants(response.data);
              setIsLoading(false);
            })
            .catch(e => {
              console.log(e);
            });
        }
  };

  useEffect(() => {
    retrieveTypeDiscountByRestaurant();
  }, [currentUser?.id,datedebut, datefin, mesRestoParams, idResto]);

   
   const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
const currencyCode=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code
const currencySymbol=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol

  return (
    <>
      <Card variant="outlined">
      {isLoading && <Box sx={{ display: 'flex' }}>
        <CircularProgress size="50px" />
      </Box>}
      <CardContent>
        <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Réduction par Restaurant</Typography>
          </Box>
          {!isLoading && <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            <Table
              aria-label="custom pagination table"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Nom Restaurant</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" align="right">Réduction</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? restaurants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : restaurants
                ).map((restau) => (
                  <TableRow key={restau.nomeresto}>
                    <TableCell>
                      <Chip
                        sx={{
                          backgroundColor: (theme) => theme.palette.success.light,
                          color:(theme) => theme.palette.success.dark,
                          borderRadius: '6px',
                          pl: '3px',
                          pr: '3px',
                        }}
                        size="small"
                        label={restau.nomeresto.toUpperCase()}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography color="textSecondary" align="right">
                      {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.DISCOUNT)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={restaurants.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>}
        </CardContent>
      </Card>
    </>
  );
};

export default ReductionByRestaurant;

ReductionByRestaurant.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
