import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    sidebarOpened:false
}
export const sidebarSlice = createSlice({
    name:"sidebar",
    initialState,
    reducers:{
        setSidebarOpened : (state,action)=>{
            state.sidebarOpened=action.payload
         },
    }
})
export const {
    setSidebarOpened
} = sidebarSlice.actions
export default sidebarSlice.reducer