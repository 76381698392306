import http from "../http-common";

const getAll = () => {
  return http.get("/borneparam");
};

const get = id => {
  return http.get(`/borneparam/${id}`);
};

const getOneParam = franchiseId => {
  return http.get(`/borneparam/findOneParam/${franchiseId}`);
};

const create = data => {
  return http.post("/borneparam", data);
};

const update = (id, data) => {
  return http.put(`/borneparam/${id}`, data);
};

const remove = id => {
  return http.delete(`/borneparam/${id}`);
};

const removeAll = () => {
  return http.delete(`/borneparam`);
};

const findByTitle = login => {
  return http.get(`/borneparam?login=${login}`);
};

const findByfranchiseid = franchiseId => {
  return http.get(`/borneparam/published/?franchiseId=${franchiseId}`);
};

export default {
  getAll,
  get,
  getOneParam,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  findByfranchiseid
};
