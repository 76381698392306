import React from 'react';

const BlockageAccount = () => {
  return (
    <svg
      width="677"
      height="462"
      viewBox="0 0 677 462"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.856 94.8597C116.856 42.5218 159.284 0.0935059 211.622 0.0935059H581.447C633.785 0.0935059 676.213 42.5218 676.213 94.8597C676.213 147.198 633.785 189.626 581.447 189.626H547.65C555.343 200.441 559.866 213.668 559.866 227.951C559.866 248.395 550.598 266.675 536.034 278.818H548.013C598.427 278.818 639.295 319.686 639.295 370.1C639.295 420.514 598.427 461.382 548.013 461.382H113.047C62.6329 461.382 21.7646 420.514 21.7646 370.1C21.7646 338.343 37.9815 310.374 62.5858 294.021C27.9453 291.894 0.508789 263.126 0.508789 227.951C0.508789 191.391 30.1462 161.754 66.7058 161.754H144.496C127.415 144.613 116.856 120.97 116.856 94.8597Z"
        fill="#FB5B4D"
        fillOpacity="0.12"
      />
      <path
        d="M483.32 66.3275L483.321 66.3282L514.193 98.0248C517.313 101.228 515.043 106.607 510.572 106.607H184.032C179.529 106.607 177.273 101.162 180.458 97.9774L209.604 68.8309C211.974 66.4608 213.306 63.2463 213.306 59.8945V43.1307C213.306 40.3388 215.569 38.0755 218.361 38.0755H477.315C480.107 38.0755 482.37 40.3388 482.37 43.1307V62.5768C482.014 63.8171 482.259 65.2375 483.32 66.3275ZM189.069 173.206L190.76 169.813C176.63 162.772 166.466 148.542 166.466 132.355V129.491C166.466 126.7 168.73 124.436 171.521 124.436H522.691C525.483 124.436 527.746 126.7 527.746 129.491V132.314C527.372 149.279 517.991 163.226 503.837 169.835C499.263 171.971 495.544 176.508 495.544 182.158V363.562C495.544 370.542 501.202 376.2 508.182 376.2H522.691C525.483 376.2 527.746 378.463 527.746 381.255V389.909C527.746 392.701 525.483 394.964 522.691 394.964H171.521C168.73 394.964 166.466 392.701 166.466 389.909V381.255C166.466 378.463 168.73 376.2 171.521 376.2H186.03C193.01 376.2 198.669 370.542 198.669 363.562V181.95C198.669 176.475 195.168 172.01 190.76 169.813L189.069 173.206ZM439.389 131.397H435.598C435.598 138.119 432.408 148.819 425.378 157.813C418.451 166.675 408.004 173.636 393.482 173.636C377.121 173.636 366.712 166.776 360.284 158.205C353.732 149.467 351.218 138.788 351.218 131.397H347.427H343.635C343.635 139.209 340.449 149.893 333.531 158.574C326.71 167.133 316.336 173.67 301.755 173.67C284.987 173.67 274.452 165.374 267.961 155.849C261.332 146.123 259.106 135.347 259.106 131.397H255.315H251.524C251.524 148.725 241.075 163.453 226.099 169.958C221.348 172.022 217.433 176.637 217.433 182.443V363.562C217.433 370.542 223.091 376.2 230.071 376.2H464.141C471.121 376.2 476.78 370.542 476.78 363.562V182.228C476.78 176.475 472.933 171.886 468.242 169.796C453.463 163.21 443.181 148.581 443.181 131.397H439.389Z"
        fill="white"
        stroke="#FB5B4D"
        strokeWidth="7.58283"
      />
      <path
        d="M355.518 291.265C355.518 296.431 351.328 300.621 346.163 300.621C342.489 300.621 339.332 298.497 337.84 295.455L350.353 282.942C353.395 284.434 355.518 287.591 355.518 291.265Z"
        fill="#FB5B4D"
      />
      <path
        d="M402.241 213.838C400.52 212.116 397.707 212.116 395.985 213.838L379.856 229.967C375.437 218.372 364.129 212.518 346.164 212.518C313.849 212.518 310.118 233.066 310.118 248.564V255.738C310.175 255.738 310.233 255.738 310.29 255.738C294.047 257.69 288.766 265.783 288.766 285.93V296.606C288.766 305.789 289.857 312.447 292.612 317.211L290.029 319.794C288.307 321.516 288.307 324.329 290.029 326.051C290.947 326.854 292.038 327.313 293.186 327.313C294.334 327.313 295.424 326.854 296.285 325.993L402.241 220.037C404.021 218.315 404.021 215.56 402.241 213.838ZM319.474 255.222C319.015 255.222 318.613 255.222 318.154 255.222V248.564C318.154 231.746 322.918 220.554 346.164 220.554C365.679 220.554 371.821 228.015 373.543 236.338L354.659 255.222H319.474Z"
        fill="#FB5B4D"
      />
      <path
        d="M403.562 285.924V296.6C403.562 320.133 396.388 327.308 372.854 327.308H319.819C314.711 327.308 312.185 321.109 315.801 317.493L331.987 301.306C332.389 301.88 332.848 302.397 333.308 302.914C337.325 307.276 343.295 309.572 349.895 308.194C350.412 308.079 350.929 307.965 351.445 307.735C351.962 307.62 352.421 307.448 352.88 307.218C354.028 306.817 355.119 306.185 356.152 305.439C356.554 305.21 356.898 304.923 357.185 304.636C358.046 303.947 358.849 303.143 359.538 302.282C359.825 301.995 360.112 301.651 360.342 301.249C361.088 300.216 361.719 299.125 362.121 297.977C362.351 297.518 362.523 297.059 362.638 296.542C362.867 296.026 362.982 295.509 363.097 294.993C364.474 288.392 362.179 282.423 357.816 278.405C357.357 277.946 356.783 277.486 356.209 277.085L374.117 259.177C374.404 258.89 374.634 258.66 374.921 258.43C377.217 256.536 380.144 255.618 383.071 255.847C383.588 255.905 384.162 255.962 384.678 256.077C385.654 256.249 386.63 256.479 387.548 256.708C388.467 256.938 389.328 257.225 390.189 257.512C391.853 258.143 393.288 258.89 394.608 259.75C395.928 260.611 397.019 261.645 398.052 262.85C398.569 263.481 399.085 264.17 399.487 264.916C399.889 265.548 400.233 266.236 400.578 266.983C400.922 267.729 401.209 268.532 401.496 269.336C401.84 270.312 402.127 271.402 402.357 272.55C402.587 273.354 402.701 274.215 402.874 275.133C402.988 275.994 403.103 276.912 403.218 277.888C403.448 280.299 403.562 282.997 403.562 285.924Z"
        fill="#FB5B4D"
      />
    </svg>
  );
};

export default BlockageAccount;
