import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    message:null
}
export const AlertSlice = createSlice({
    name:"alert",
    initialState,
    reducers:{
        setMessage : (state,action)=>{
            state.message=action.payload
         },
    }
})
export const {
    setMessage
} = AlertSlice.actions
export default AlertSlice.reducer