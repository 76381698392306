import { Box, Typography } from '@material-ui/core';
import React from 'react'
import EmptyData3 from '../icons/EmptyData3';
// import EmptyDataIcon from '../icons/EmptyDataIcon';


const EmptyData = () => {
  return (
    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        p:"40px 0"
                      }}
                    >
                      <EmptyData3 />
                      <Typography
                        sx={{
                          color: '#6E7489',
                          textAlign: 'center',
                          mt: '15px',
                        }}
                      >
                        Aucune donnée disponible <br /> pour le moment
                      </Typography>
                    </Box>
    
  )
}

export default EmptyData