import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import carteSlice from './carte/carteSlice';
import AlertSlice from './alert/AlertSlice';
import currentUserInfoSlice from './currentUser/currentUserInfoSlice';
import  TaillesSlice  from './config/TailleSlice';
import  itemsOfOptionsSliceSlice  from './carte/itemsOfOptionsSlice';
import sidebarSlice from './sidebar/sidebarSlice';
import listeLanguesSlice from './config/listeLanguesSlice';
import changeLogs from './carte/changeLogs';
import printersSlice from './config/printersSlice';

const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  carte:carteSlice,
  changeLogs:changeLogs,
  alert:AlertSlice,
  sidebar:sidebarSlice,
  currentUserInfo:currentUserInfoSlice,
  tailles:TaillesSlice,
  printers:printersSlice,
  listeLangues:listeLanguesSlice,
  itemsOfOptions:itemsOfOptionsSliceSlice,
});

export default RootReducers;
