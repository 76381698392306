import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    itemsOfOptions:[],
}
export const itemsOfOptionsSlice = createSlice({
    name:"itemsOfOptions",
    initialState,
    reducers:{
        setItemsOfOptions : (state,action)=>{
           state.itemsOfOptions=action.payload
        }
    }
})
export const {
  setItemsOfOptions
} = itemsOfOptionsSlice.actions
export default itemsOfOptionsSlice.reducer