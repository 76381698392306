import { Box } from '@material-ui/core';
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import CaData from './dashboardRestau-components/CaData';
import Graph from './dashboardRestau-components/Graph';
import AdditionalInfo from './dashboardRestau-components/AdditionalInfo';
import Types from './dashboardRestau-components/Types';
import TopVentsProducts from './dashboardRestau-components/TopVentsProducts';
import Promotions from './dashboardRestau-components/Promotions';
import Annulations from './dashboardRestau-components/Annulations';

const DashboardRestau = ({
    forDay,
    date,
    currentUser
}) => {
    const [totalTtc, setTotalTtc] = useState(0);
  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      gap: '20px',
    }}
    className="dashContainer"
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: { md: '75%' },
      }}
      className="left"
    >
      {/* caCards  */}
      <CaData currentUser={currentUser} date={date} forDay={forDay} />
      {/* !caCards  */}
      <Box className="block">
        <Graph currentUser={currentUser} date={date} forDay={forDay} setTotalTtc={setTotalTtc} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '20px',
          flexWrap: 'wrap',
        }}
        className="container2"
      >
         {/* Equipement */}
         <Box
          sx={{
            width: { xs: '100%', md: '40%' },
          }}
          className="block"
        >
          <AdditionalInfo currentUser={currentUser} date={date} forDay={forDay} title="Equipements" />
        </Box>

        {/* !Equipement */}
        {/* sources */}
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
          }}
          className="block"
        >
          <AdditionalInfo currentUser={currentUser} date={date} forDay={forDay} title="Sources" />
        </Box>

        {/* !sources */}
       

        {/* Types */}
        <Types currentUser={currentUser} date={date} forDay={forDay} />
        {/* !Types */}
        {/* modePaiment */}
        <Box
          sx={{
            width: { xs: '100%', md: 'calc(100% / 3)' },
          }}
          className="block"
        >
          <AdditionalInfo currentUser={currentUser} date={date} forDay={forDay} title="Modes de Paiement" />
          {/* <DisplayedComponentInLeft /> */}
        </Box>

        {/* !modePaiment */}
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: { md: '25%' },
      }}
      className="right"
    >
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block"
      >
        <TopVentsProducts currentUser={currentUser} date={date} forDay={forDay} />
        {/* <DisplayedComponentInRight /> */}
      </Box>
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block"
      >
        <Promotions currentUser={currentUser} forDay={forDay} date={date} totalTtc={totalTtc}/>
      </Box>
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block"
      >
        <Annulations currentUser={currentUser} forDay={forDay} date={date} />
      </Box>
      
    </Box>
  </Box>
  )
}
DashboardRestau.propTypes = {
    date: PropTypes.any.isRequired,
    forDay: PropTypes.any,
    currentUser: PropTypes.any,
  };
export default DashboardRestau