import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Grid,
  Alert,
  Stack,
} from '@material-ui/core';
import AuthService from '../../../services/auth.service';
import BorneParamService from '../../../services/BorneParamService';
import CustomCheckbox from '../../../components/forms/custom-elements/CustomCheckbox';

const ConfigGeneral = () => {
  const initialParamState = {
    franchiseId: '',
    hide_name_categories: false,
    hide_name_sub_categories: false,
    hide_name_products: false,
    hide_name_options: false,
  };

  const currentUser = AuthService.getCurrentUser();
  const [params, setParams] = useState(initialParamState);
  const [alertMessage, setAlertMessage] = useState('');
  const [buttonModifier, setButtonModifier] = useState(true);
  const [buttonSauvegarder, setButtonSauvegarder] = useState(true);
  const MessageSuccess = (
    <Alert variant="filled" severity="success">
      Parametres Borne mises à jour avec succès !
    </Alert>
  );
  const MessageError = (
    <Alert variant="filled" severity="error">
      Erreur de mise à jour des Parametres Borne !
    </Alert>
  );

  const getParams = () => {
    // BorneParamService.getOneParam(currentUser?.id)
    //   .then((response) => {
    //     setParams(response.data);
    //     console.log(response.data);
    //     console.log(response.data.id);

    //     if (response.data.id > 0) {
    //       setButtonModifier(false);
    //       setButtonSauvegarder(true);
    //     } else {
    //       setButtonModifier(true);
    //       setButtonSauvegarder(false);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    //  setParams(Carte);
    //      console.log(Carte);
  };

  useEffect(() => {
    getParams(currentUser?.id);
  }, [currentUser?.id]);

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setParams({ ...params, [name]: value, [name]: checked });
  };

  const saveBorneparam = () => {
    const data = {
      franchiseId: currentUser?.id,
      hide_name_categories: params.hide_name_categories,
      hide_name_sub_categories: params.hide_name_sub_categories,
      hide_name_products: params.hide_name_products,
      hide_name_options: params.hide_name_options,
    };

    BorneParamService.create(data)
      .then((response) => {
        setParams(data);
        setAlertMessage(MessageSuccess);
        console.log(response.data);

        setButtonModifier(false);
        setButtonSauvegarder(true);
      })
      .catch((e) => {
        console.log(e);
        setAlertMessage(MessageError);

        setButtonModifier(true);
        setButtonSauvegarder(false);
      });
  };

  const updateBorneParam = () => {
    BorneParamService.update(params.id, params)
      .then((response) => {
        setParams({ ...params });
        console.log(response.data);
        setAlertMessage(MessageSuccess);
      })
      .catch((e) => {
        console.log(e);
        setAlertMessage(MessageError);
      });
  };

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          p: 0,
        }}
      >
        <Stack sx={{ width: '100%' }}>{alertMessage}</Stack>
        <Box
          sx={{
            padding: '15px 30px',
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography fontWeight="500" variant="h4">
              Configuration Générale
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent
          sx={{
            padding: '30px',
          }}
        >
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="hide_name_categories"
                      name="hide_name_categories"
                      value={params.hide_name_categories}
                      checked={params.hide_name_categories}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Cacher les noms des catégories"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="hide_name_sub_categories"
                      name="hide_name_sub_categories"
                      value={params.hide_name_sub_categories}
                      checked={params.hide_name_sub_categories}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Cacher les noms des sous catégories"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="hide_name_products"
                      name="hide_name_products"
                      value={params.hide_name_products}
                      checked={params.hide_name_products}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Cacher les noms des produits"
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="hide_name_options"
                      name="hide_name_options"
                      value={params.hide_name_options}
                      checked={params.hide_name_options}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Cacher les noms des options"
                />
              </Grid>
            </Grid>


            <Box p={3}>
              {buttonModifier && (
                <Button
                  color="primary"
                  sx={{ marginRight: '10px' }}
                  variant="contained"
                  onClick={saveBorneparam}
                >
                  Sauvegarder
                </Button>
              )}

              {buttonSauvegarder && (
                <Button color="success" variant="contained" onClick={updateBorneParam}>
                  Appliquer
                </Button>
              )}
            </Box>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ConfigGeneral;
