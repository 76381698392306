import { Box } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import React, { useState } from 'react';
import PropsType from 'prop-types';
import { useSelector } from 'react-redux';
import { ConvertirMonetaire, addSpacesToNumber } from '../../../utility/utilityFunctions';
import usePromotions from '../custom-hooks/usePromotions';

const Promotions = ({date, forDay, totalTtc, currentUser}) => {
    const [displayDetails, setDisplayDetails]=useState(false)
    const { accumulationPercent, promotions } = usePromotions(
      currentUser,
      date,
      forDay,
      totalTtc
    ); 
    const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
    const {
      loading,
      error,
      data
    }= promotions
     return (
    <Box className="promotionContainer">
      <Box
        onClick={() => setDisplayDetails(!displayDetails)}
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        className="promotionTop"
      >
        <Box
          sx={{
            color: (t) => t.palette.textDark,
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          Promotions{' '}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}
            className="nbreCommandes"
          >
            {addSpacesToNumber(data?.accumulation?.nombrescommandes)} Commandes
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            textAlign:"right"
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.textDark,
              fontSize: '17px',
              fontWeight: 500,
            }}
            className="percent"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, data?.accumulation?.totalDiscount)}
           <Box sx={{
            fontSize:"14px",
            fontWeight:"400",
            
           }}> {` (${accumulationPercent.toFixed(2)}%)`}</Box>
          </Box>
          <KeyboardArrowUp
            sx={{
              transform: displayDetails && 'rotate(180deg)',
              transition: 'transform .4s ease',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: displayDetails ? '500vh' : '0px',
          overflow: 'hidden',
          transition: 'max-height .4s ease',
        }}
        className="promotionsList"
      >
        {loading ? (
          <Box>Loading...</Box>
        ) : error ? (
          <Box>{error}</Box>
        ) : data?.PromotionJson?.length === 0 ? (
          <Box>Aucun données à afficher.</Box>
        ) : (
          data?.PromotionJson?.map((el, index) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid lightgray',
                borderRight: '1px solid lightgray',
                paddingTop: index === 0 && '10px',
                marginTop: index === 0 && '10px',
                padding: '10px',
                borderRadius: '7px',
                backgroundColor: 'rgba(242, 243, 245, 1)',
                mb: '15px',
              }}
              className="promoItem"
            >
              <Box
                sx={{
                  fontSize: '15px',
                  fontWeight: '600',
                }}
                className="left"
              >
                {el.labelDiscount}{' '}
                <Box
                  sx={{
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                  className="nbreCommandes"
                >
                  {addSpacesToNumber(el.nombrescommandes)} Commandes
                </Box>{' '}
              </Box>
              <Box className="right">
                {ConvertirMonetaire(currencyCode, currencySymbol, el.totalDiscount)}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};
Promotions.propTypes = {
    date: PropsType.any.isRequired,
    forDay: PropsType.any.isRequired,
    totalTtc: PropsType.any.isRequired,
    currentUser: PropsType.any.isRequired,
  };
export default Promotions;
