import http from "../http-common";

const getAll = () => {
  return http.get("/categorie_produit");
};

const get = ID => {
  return http.get(`/categorie_produit/${ID}`);
};

const getOneCat = franchiseId => {
  return http.get(`/categorie_produit/findOneCat/${franchiseId}`);
};

const create = data => {
  return http.post("/categorie_produit", data);
};

const update = (ID, data) => {
  return http.put(`/categorie_produit/${ID}`, data);
};

const remove = ID => {
  return http.delete(`/categorie_produit/${ID}`);
};

const removeAll = () => {
  return http.delete(`/categorie_produit`);
};

const findByTitle = login => {
  return http.get(`/categorie_produit?login=${login}`);
};

const findByfranchiseid = franchiseId => {
  return http.get(`/categorie_produit/published/?franchiseId=${franchiseId}`);
};

export default {
  getAll,
  get,
  getOneCat,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  findByfranchiseid
};
