/* eslint-disable prefer-template */
import React, { 
    // useState, 
    // useEffect 
} from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
// import jwt from 'jsonwebtoken';
import dateFormat from 'dateformat';
import frLocale from 'date-fns/locale/fr';
import { 
    Box, 
    // MenuItem 
} from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
// import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
// import AuthService from '../../../services/auth.service';

const FromtoDatesChoice = ({datedebut, setDatedebut, datefin, setDatefin}) => {
//   const date = new Date();
//   const firstDay = dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-mm-dd');
//   const lastDay = dateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-mm-dd');

// //   const currentUser = AuthService.getCurrentUser();
//   const [datedebut, setDatedebut] = useState(firstDay);
//   const [datefin, setDatefin] = useState(lastDay);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: '20px',
          marginBottom: '20px',
        }}
        className="statsHead"
      >
        <Box
          sx={{
            // width: { xs: '100%', sm: 'calc(100% / 2 - 20px)', md: 'calc(100% / 4 - 20px)' },
            width: { xs: '100%', sm: 'calc(100% / 2 - 20px)', md: 'calc(100% / 2 - 20px)' },
          }}
        >
          <CustomFormLabel htmlFor="datedebut">Date Debut</CustomFormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <DatePicker
              value={datedebut}
              onChange={(newValue2) => {
                setDatedebut(dateFormat(newValue2, 'yyyy-mm-dd'));
              }}
              renderInput={(params) => (
                <CustomTextField
                  size="small"
                  {...params}
                  fullWidth
                  id="datedebut"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '18px',
                      height: '18px',
                    },
                    '& .MuiFormHelperText-root': {
                      display: 'none',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            width: { xs: '100%', sm: 'calc(100% / 2 - 20px)', md: 'calc(100% / 2 - 20px)' },
          }}
        >
          <CustomFormLabel htmlFor="datefin">Date Fin</CustomFormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <DatePicker
              value={datefin}
              onChange={(newValueDf) => {
                setDatefin(dateFormat(newValueDf, 'yyyy-mm-dd'));
              }}
              renderInput={(params) => (
                <CustomTextField
                  size="small"
                  {...params}
                  fullWidth
                  id="datefin"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '18px',
                      height: '18px',
                    },
                    '& .MuiFormHelperText-root': {
                      display: 'none',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};
FromtoDatesChoice.propTypes = {
    datedebut: PropTypes.any.isRequired,
    setDatedebut: PropTypes.any.isRequired,
    datefin: PropTypes.any.isRequired,
    setDatefin: PropTypes.any.isRequired,

//   date: PropTypes.any.isRequired,
//   setDate: PropTypes.any.isRequired,
//   forDay: PropTypes.any,
//   setForDay: PropTypes.any,
};

export default FromtoDatesChoice;
