/* eslint-disable no-unused-expressions */
// import { getDoc, setDoc } from 'firebase/firestore';
import {addDoc, collection, serverTimestamp } from "firebase/firestore"; 
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import { firestore, storage } from '../firebase/firebase';
import { setConfig, setStorageFileSucess } from '../redux/carte/carteSlice';
import authService from '../services/auth.service';
import { currencyCodeToLocale } from '../data/data';
import { setItemsOfOptions } from '../redux/carte/itemsOfOptionsSlice';
import { setSizes } from '../redux/config/TailleSlice';
import { setCurrentUserInfo } from '../redux/currentUser/currentUserInfoSlice';

const db = firestore;
// UpBackEndLogs
async function logChange(changeDetails, currentUser, cardUrl) {
  console.log("changeDetails=>", changeDetails)
  const collectionName="change_logs_"+(currentUser?.roles==="admin" ? "Franchises" : "Restos")
  const changed_by=currentUser?.user_name ? currentUser?.user_name : currentUser?.roles==="admin" ? "Franchise" : "Resto"
  try {
    await addDoc(collection(db, collectionName), {
      ...changeDetails,
      changed_by,
      timestamp: serverTimestamp(),
      currentUser:currentUser?.id,
      cardUrl
            
    });
    console.log("Change log saved successfully");
  } catch (error) {
    console.error("Error saving change log: ", error);
  }
}

const updateCart = async (
  config,
  dispatch,
  updatedCarteData,
  currentUser,
  withouUpdateStorageFile,
  changeLogs
) => {
  const currentUserId=currentUser?.id
  const formatedDate = firebase.firestore.Timestamp.fromDate(new Date());
  console.log('update carte start...', changeLogs);
  console.log("config",config, currentUserId)
  const folderRef = storage.ref(`CartesCampagnes/${currentUserId}/`);
  const updatedCarteRef = folderRef.child(config.campagne);
  const updatedCarteJson = JSON.stringify(updatedCarteData);
  const updatedCarteBlob = new Blob([updatedCarteJson], { type: 'application/octet-stream' });
  try {
    await updatedCarteRef.put(updatedCarteBlob);
    console.log('Updated carte uploaded to storage successfully!');
    if(!withouUpdateStorageFile){
      console.log("withouUpdateStorageFile is false", withouUpdateStorageFile)
       dispatch(setStorageFileSucess(updatedCarteData))
    }else{
      console.log("withouUpdateStorageFile is true", withouUpdateStorageFile)
    }
    // Firestore update
    const cartesCampagnesRef = db.collection('CartesCampagnes');
    const querySnapshot = await cartesCampagnesRef
      .where('url', '==', config?.campagne)
      .where('franchiseId', '==', currentUserId)
      .get();

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Assuming there's only one document that matches the criteria
      await doc.ref.update({
        updatedAt: formatedDate,
        updatedBy: currentUser?.user_name || 'La franchise',
      });
      // const { id, roles, user_name } = currentUser;
      // // CallBackEndLogs
      // await logChange(changeLogs, { id, roles, user_name }, config.campagne);
      console.log('updatedAt field updated successfully in Firestore!');
    } else {
      console.log('No document found with the specified criteria.');
    }
  } catch (error) {
    console.log("error while updating carte",error);
  }
};
const updateFranchiseOptions = async (dispatch, updatedOptions, currentUserId) => {
  console.log('update options start...');
  console.log('updatedOptions', updatedOptions);
  const franchiseOptionsPath = `franchiseOptions/${currentUserId}/options.json`;
  const franchiseOptionsRef = firebase.storage().ref().child(franchiseOptionsPath);
  let franchiseOptionsSnapShot;
  try {
    franchiseOptionsSnapShot = await franchiseOptionsRef.getDownloadURL();
  } catch (error) {
    console.log('error while recupering franchiseOptions File.', error);
    return;
  }
  const franchiseOptionsBlob = new Blob([JSON.stringify(updatedOptions)], {
    type: 'application/json',
  });
  try {
    await franchiseOptionsRef.put(franchiseOptionsBlob);
    const response = await fetch(franchiseOptionsSnapShot);
    const franchiseOptionsContent = await response.json();
    console.log('franchiseOptionsContent', franchiseOptionsContent);
    dispatch(setItemsOfOptions(updatedOptions));
  } catch (error) {
    console.log(error);
  }
};
const updateFranchiseOptions2 = async (dispatch, updatedOptions, currentUserId) => {
  console.log('update options start...', dispatch);
  console.log('updatedOptions', updatedOptions);

  // Define the path and reference for the file in Firebase Storage
  const franchiseOptionsPath = `franchiseOptions/${currentUserId}/optionWhereSupplementAreDeleted.json`;
  const franchiseOptionsRef = firebase.storage().ref().child(franchiseOptionsPath);

  let franchiseOptionsSnapShot;

  try {
    // Attempt to get the download URL to check if the file exists
    franchiseOptionsSnapShot = await franchiseOptionsRef.getDownloadURL();
  } catch (error) {
    console.log('Error while recovering franchiseOptions File.', error);

    if (error.code === 'storage/object-not-found') {
      // If file is not found, create a new file with initial content
      const initialContent = JSON.stringify(updatedOptions);
      const initialBlob = new Blob([initialContent], { type: 'application/octet-stream' });

      try {
        // Upload the initial file
        await franchiseOptionsRef.put(initialBlob);
        console.log('File created successfully as it was not found.');
      } catch (uploadError) {
        console.log('Error while creating the file.', uploadError);
      }

      // Exit the function after creating the file
      return;
    } 
    // else {
    //   // Handle other errors
    //   console.log('Unexpected error occurred.', error);
    //   return;
    // }
  }

  // If file exists, update it
  const franchiseOptionsBlob = new Blob([JSON.stringify(updatedOptions)], { type: 'application/json' });

  try {
    // Upload the updated file
    await franchiseOptionsRef.put(franchiseOptionsBlob);

    // Fetch the content of the updated file
    const response = await fetch(franchiseOptionsSnapShot);
    const franchiseOptionsContent = await response.json();
    console.log('franchiseOptionsContent', franchiseOptionsContent);

    // Dispatch the updated options
    // dispatch(setItemsOfOptions(updatedOptions));
  } catch (error) {
    console.log('Error while updating the file.', error);
  }
};

const fetchImageURLs = async (path, currentUserId, type) => {
  if (path) {
    const imageRef = storage.ref(`CartesCampagnesImages/${currentUserId}/${type}/${path}`);
    try {
      const downloadURL = await imageRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      return null;
    }
  }
  return null;
};
function removeSpacesAndAccent(inputString) {
  const outputString = inputString.replace(/ /g, '');
  return outputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

 const uploadImage = (selectedFile, currentUserId, type, elementName, productMoment) => {
  return new Promise((resolve, reject) => {
    const fileName = productMoment
      ? elementName + '_Moment.' + selectedFile.type.split('/')[1]
      : elementName + '.' + selectedFile.type.split('/')[1];
    const storageRef = storage.ref(`CartesCampagnesImages/${currentUserId}/${type}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress} % done`);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            console.log('first');
        }
      },
      (error) => {
        console.log('error uploading img', error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            resolve({
              imageURL: downloadURL,
              path: fileName,
              isImgModified: true,
              sizeFile: selectedFile.size,
            });
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
            reject(error);
          });
      },
    );
  });
};
const handleLogout = (dispatch) => {
  authService.logout();
  dispatch(setConfig(null));
  dispatch(setSizes([]));
  dispatch(setCurrentUserInfo(null));
};

const handleSaveImage = async (itemId, selectedFiles, type, setNewPath, currentUserId) => {
  if (selectedFiles?.find((f) => f.id === itemId)) {
    try {
      const resultat = await uploadImage(
        selectedFiles?.find((f) => f.id === itemId)?.content,
        currentUserId,
        type,
        selectedFiles?.find((f) => f.id === itemId)?.id
      );
      resultat.id = itemId;
      setNewPath(resultat);
    } catch (error) {
      console.error('Error during image upload:', error);
    }
  }
};
function objectsAreEqual(objA, objB) {
  if (objB) {
    const { imageURL: imageURLA, position: positionA, ...restOfObjA } = objA;
    const { imageURL: imageURLB, position: positionB, ...restOfObjB } = objB;
    const keysA = Object.keys(restOfObjA);
    const keysB = Object.keys(restOfObjB);
    // console.log('the element =>', objA.name,keysA.map((key) => key+' : ' + objA[key]+' _ ' +objB[key]))
    if (keysA.length !== keysB.length) {
      return false;
    }
    if (keysA.some((key) => objA[key] !== objB[key])) {
      return false;
    }
    return true;
  }
  if (objA?.name?.trim() === '') {
    return true;
  }
  return false;
}
function arraysAreEqual(arr1, arr2) {
  // Check if the arrays are of different lengths
  if (arr1.length !== arr2.length) return false;
  // Iterate through each element and compare
  for (let i = 0; i < arr1.length; i++) {
    // If any element is different, return false
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
  }
  // If all elements are equal, return true
  return true;
}
function objectsAreEqual2(objA, objB) {
  if (objB) {
    const {
      // prices:pricesA, allSizes: allSizesA,
      // imageURL: imageURLA,
      position: positionA,
      ...restOfObjA
    } = objA;
    const {
      // prices:pricesB, allSizes: allSizesB,
      // imageURL: imageURLB,
      position: positionB,
      ...restOfObjB
    } = objB;
    const keysA = Object.keys(restOfObjA);
    const keysB = Object.keys(restOfObjB);
    if (keysA.length !== keysB.length) {
      return false;
    }
    if (
      keysA.some((key) => objA[key] !== objB[key]) ||
      keysA
        .filter((key) => objA[key] !== objB[key])
        .filter((key) => Array.isArray(objA[key]))
        .some((key) => !arraysAreEqual(objA[key], objB[key]))
    ) {
      return false;
    }
    return true;
  }
  if (objA?.name?.trim() === '') {
    return true;
  }
  return false;
}
const ConvertirMonetaire = (currencyCode, currencySymbol, value) => {
  // if (value) {
    const ValueParseFloat = parseFloat(value) || 0;
    const FinalValue2 = ValueParseFloat.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2,
    }).replace(/[^\d,.]/g, '');
    const FinalValue = currencyCode
      ? currencyCodeToLocale[currencyCode]
        ? ValueParseFloat.toLocaleString(currencyCodeToLocale[currencyCode], {
            style: 'currency',
            currency: currencyCode || 'USD',
          })
        : FinalValue2 + ' ' + currencySymbol
      : FinalValue2;
    return FinalValue;
  // }
  // return 0;
};
function adjustDataForTimeZone(data2, userOffset) {
  return data2.map((item) => {
    // Parse the hour as an integer
    const hour = parseInt(item.hour_day.split(':')[0], 10);
    // Adjust the hour based on the user's time zone offset
    const adjustedHour = hour + userOffset;

    // Handle negative and overflowing hour values
    if (adjustedHour < 0) {
      item.hour_day = (24 + adjustedHour).toString().padStart(2, '0');
    } else if (adjustedHour >= 24) {
      item.hour_day = (adjustedHour - 24).toString().padStart(2, '0');
    } else {
      item.hour_day = adjustedHour.toString().padStart(2, '0');
    }

    return item;
  });
}
function convertHexToRgb(hex) {
  // Remove the "#" symbol if it's present
  const hex2 = hex.replace(/^#/, '');

  // Parse the hexadecimal values for red, green, and blue
  const r = parseInt(hex2.slice(0, 2), 16);
  const g = parseInt(hex2.slice(2, 4), 16);
  const b = parseInt(hex2.slice(4, 6), 16);

  // Return the RGB color in the "rgb()" format
  return `${r},${g},${b}`;
}
function convertRgbToHex(rgb) {
  // Split the input string by commas and extract the numeric values
  const values = rgb?.match(/\d+/g);

  if (values && values.length === 3) {
    // Convert the numeric values to hexadecimal and pad them if necessary
    const r = parseInt(values[0], 10).toString(16).padStart(2, '0');
    const g = parseInt(values[1], 10).toString(16).padStart(2, '0');
    const b = parseInt(values[2], 10).toString(16).padStart(2, '0');

    // Construct and return the "#rrggbb" format string
    return `#${r}${g}${b}`;
  }

  // Return an empty string if the input is not in the expected format
  return null;
}
function convertCommaToDot(numberString) {
  // Check if the numberString contains a comma
  if(typeof numberString==='string'){
   if (numberString.includes(',')) {
      // Replace the comma with a dot
      return numberString.replace(',', '.');
  } 
  return numberString; 
  }
  
  return numberString;    
  
}
export const toNumber = (valueToTransform2) => {
  const valueToTransform=convertCommaToDot(valueToTransform2)
  const result= valueToTransform !== '' ? parseFloat(valueToTransform) : 0;
  return result || 0;
};
function updatedPricesInProductFn(data) {
  return data.map((item) => {
    return {
      ...item,
      price: toNumber(item.price),
      purchase_price: toNumber(item.purchase_price),
      price_point: toNumber(item.price_point),
      prices: item?.prices?.map((price) => ({
        ...price,
        price: toNumber(price.price),
      })),
      cartes: item?.cartes?.map((carte) => ({
        ...carte,
        number_carte: parseInt(carte?.number_carte,10) || 0,
      })),
      daily_stock:parseInt(item?.daily_stock, 10) || 0,
      options:item?.options?.map(op=>({
        ...op,
          max_option: parseInt(op?.max_option,10) || 0,
          min_option: parseInt(op?.min_option,10) || 0,
          min_option_size10: parseInt(op?.min_option_size10,10) || 0,
          option_free_size4: parseInt(op?.option_free_size4,10) || 0,
          option_free_size5: parseInt(op?.option_free_size5,10) || 0,
          option_free_size2: parseInt(op?.option_free_size2,10) || 0,
          option_free_size3: parseInt(op?.option_free_size3,10) || 0,
          option_free_size8: parseInt(op?.option_free_size8,10) || 0,
          option_free_size9: parseInt(op?.option_free_size9,10) || 0,
          option_free_size6: parseInt(op?.option_free_size6,10) || 0,
          option_free_size7: parseInt(op?.option_free_size7,10) || 0,
          option_free: parseInt(op?.option_free,10) || 0,
          max_option_size10: parseInt(op?.max_option_size10,10) || 0,
          option_free_size10: parseInt(op?.option_free_size10,10) || 0,
          min_option_size4: parseInt(op?.min_option_size4,10) || 0,
          max_option_size4: parseInt(op?.max_option_size4,10) || 0,
          min_option_size5: parseInt(op?.min_option_size5,10) || 0,
          max_option_size5: parseInt(op?.max_option_size5,10) || 0,
          min_option_size2: parseInt(op?.min_option_size2,10) || 0,
          max_option_size6: parseInt(op?.max_option_size6,10) || 0,
          min_option_size3: parseInt(op?.min_option_size3,10) || 0,
          max_option_size7: parseInt(op?.max_option_size7,10) || 0,
          max_option_size2: parseInt(op?.max_option_size2,10) || 0,
          max_option_size3: parseInt(op?.max_option_size3,10) || 0,
          min_option_size8: parseInt(op?.min_option_size8,10) || 0,
          max_option_size8: parseInt(op?.max_option_size8,10) || 0,
          min_option_size9: parseInt(op?.min_option_size9,10) || 0,
          max_option_size9: parseInt(op?.max_option_size9,10) || 0,
          min_option_size6: parseInt(op?.min_option_size6,10) || 0,
          min_option_size7: parseInt(op?.min_option_size7,10) || 0,
        
      })),
      allSizes: item?.allSizes?.map((size) => ({
        ...size,
        price: toNumber(size.price),
        price_emp: toNumber(size.price_emp),
        price_delivero: toNumber(size.price_delivero),
        price_platform: toNumber(size.price_platform),
        price_drive: toNumber(size.price_drive),
        price_sp: toNumber(size.price_sp),
        price_bar: toNumber(size.price_bar),
        price_lv: toNumber(size.price_lv),
        price_uber: toNumber(size.price_uber),
        price_happy_hour: toNumber(size.price_happy_hour),
        price_terasse: toNumber(size.price_terasse),
      })),
      price_emp_junior: toNumber(item.price_emp_junior),
      price_lv_mega: toNumber(item.price_lv_mega),
      price_emp_senior: toNumber(item.price_emp_senior),
      price_happy_mega: toNumber(item.price_happy_mega),
      price_bar_mega: toNumber(item.price_bar_mega),
      price_drive_mega: toNumber(item.price_drive_mega),
      price_sp_mega: toNumber(item.price_sp_mega),
      price_bar_senior: toNumber(item.price_bar_senior),
      price_platform: toNumber(item.price_platform),
      price_platform_senior: toNumber(item.price_platform_senior),
      price_mega: toNumber(item.price_mega),
      price_bar_junior: toNumber(item.price_bar_junior),
      price_emp: toNumber(item.price_emp),
      price_drive: toNumber(item.price_drive),
      price_opening: toNumber(item.price_opening),
      price_senior: toNumber(item.price_senior),
      price_terasse_junior: toNumber(item.price_terasse_junior),
      price_happy_junior: toNumber(item.price_happy_junior),
      price_platform_junior: toNumber(item.price_platform_junior),
      price_lv: toNumber(item.price_lv),
      price_happy: toNumber(item.price_happy),
      price_sp_junior: toNumber(item.price_sp_junior),
      price_sp_senior: toNumber(item.price_sp_senior),
      price_lv_junior: toNumber(item.price_lv_junior),
      price_emp_mega: toNumber(item.price_emp_mega),
      price_lv_senior: toNumber(item.price_lv_senior),
      price_junior: toNumber(item.price_junior),
      price_happy_senior: toNumber(item.price_happy_senior),
      price_drive_senior: toNumber(item.price_drive_senior),
      price_sp: toNumber(item.price_sp),
      price_platform_mega: toNumber(item.price_platform_mega),
      price_drive_junior: toNumber(item.price_drive_junior),
      price_terasse_senior: toNumber(item.price_terasse_senior),
      price_terasse: toNumber(item.price_terasse),
      price_terasse_mega: toNumber(item.price_terasse_mega),
    };
  });
}
function updatedPricesInOptionFn(data) {
  return data.map((item) => {
    return {
      ...item,
      price: toNumber(item.price),
      price_already_included: toNumber(item.price_already_included),
      prices:item.prices.map(p=>({
        ...p,
        price:toNumber(p.price),
        price_size1:toNumber(p.price_size1),
        price_size2:toNumber(p.price_size2),
        price_size3:toNumber(p.price_size3),
        price_size4:toNumber(p.price_size4),
        price_size5:toNumber(p.price_size5),
        price_size6:toNumber(p.price_size6),
        price_size7:toNumber(p.price_size7),
        price_senior:toNumber(p.price_senior),
        price_junior:toNumber(p.price_junior),
        price_mega:toNumber(p.price_mega),
      })),
      price_buy: toNumber(item.price_buy),
    price_junior: toNumber(item.price_junior),
    price_mega: toNumber(item.price_mega),
    price_senior: toNumber(item.price_senior),
    price_size1: toNumber(item.price_size1),
    price_size2: toNumber(item.price_size2),
    price_size3: toNumber(item.price_size3),
    price_size4: toNumber(item.price_size4),
    price_size5: toNumber(item.price_size5),
    price_size6: toNumber(item.price_size6),
    price_size7: toNumber(item.price_size7),
    };
  });
}
function addSpacesToNumber(number) {
  // Convert number to string
  if (number && number !== 0) {
    let numberString = number?.toString();

    // Determine the length of the string
    const { length } = numberString;
    // Calculate the number of spaces needed
    const spacesNeeded = Math.floor((length - 1) / 3);

    // Insert spaces into the string
    for (let i = 1; i <= spacesNeeded; i++) {
      numberString = numberString?.slice(0, -3 * i) + ' ' + numberString?.slice(-3 * i);
    }
    return numberString;
  }
  return 0;
} // Output: "12 184 340"
const addImageURLS = async (data, currentUserId) => {
  const categoriesimageURLs = await Promise.all(
    data?.orderJson.categories.map(async (category) => {
      if(!category?.imageURL){
        if (category.path) {
        const imageRef = storage.ref(
          `CartesCampagnesImages/${currentUserId}/categories/${category.path}`,
        );
        try {
          const downloadURL = await imageRef.getDownloadURL();
          return downloadURL;
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            return null;
          }
        }
      }
      return null;
      }
      return category?.imageURL
      
    }),
  );
  const categoriesWithImageURLs = data?.orderJson.categories.map((category, i) => {
    return {
      ...category,
      imageURL: categoriesimageURLs[i],
    };
  });
  const ProductsImageURLs = await Promise.all(
    data?.orderJson.items.map(async (product) => {
      if(!product?.imageURL){
         if (product.path) {
        const imageRef = storage.ref(
          `CartesCampagnesImages/${currentUserId}/products/${product.path}`,
        );
        try {
          const downloadURL = await imageRef.getDownloadURL();
          return downloadURL;
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            return null;
          }
        }
      }
      return null;
      }
      return product?.imageURL
    }),
  );
  const ProductsImageURLsplatform = await Promise.all(
    data?.orderJson.items.map(async (product) => {
      if (!product?.imageURL_platform) {
        if (product.path_platform) {
          const imageRef = storage.ref(
            `CartesCampagnesImages/${currentUserId}/products/${product.path_platform}`,
          );
          try {
            const downloadURL = await imageRef.getDownloadURL();
            return downloadURL;
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              return null;
            }
          }
        }
        return null;
      }
      return product?.imageURL_platform;
    }),
  );
  const ProductsWithImageURLs2 = data?.orderJson.items.map((product, i) => {
    return {
      ...product,
      imageURL: ProductsImageURLs[i],
      imageURL_platform: ProductsImageURLsplatform[i],
    };
  });
  let optionsItemsWithImages = [];
  await Promise.all(
    data?.orderJson?.options.map(async (op) => {
      if (op.items && op.items.length !== 0) {
        const items = await Promise.all(
          op.items.map(async (item) => {
            if(!item?.imageURL){
              if (item.path) {
                try {
                  const imageRef = storage.ref(
                    `CartesCampagnesImages/${currentUserId}/${
                      op.is_ingredient ? 'ingredients' : 'supplements'
                    }/${item.path}`,
                  );
                  const downloadURL = await imageRef.getDownloadURL();
                  return downloadURL;
                } catch (error) {
                  return null;
                }
              }
              return null;
            }
            return item?.imageURL
          }),
        );
        return items;
      }
      return [];
    }),
  )
    .then((res) => {
      optionsItemsWithImages = data?.orderJson?.options.map((op, i) => {
        if (op.items.length !== 0) {
          const items = op.items.map((item, j) => {
            return {
              ...item,
              imageURL: res[i].length !== 0 ? res[i][j] : null,
            };
          });
          return {
            ...op,
            items: items,
          };
        }
        return op;
      });
    })
    .catch((error) => console.log('const =>error', error));
  const uapdatedData = {
    orderJson: {
      ...data?.orderJson,
      categories: categoriesWithImageURLs,
      items: ProductsWithImageURLs2,
    },
  };
  return {uapdatedData, optionsItemsWithImages};
};
const addPrinters=(exsitantPrinters)=>{
  console.log("exsitantPrinters", exsitantPrinters)
  const numberOfMessingPrintersKichen=8-exsitantPrinters?.filter(printer=>printer?.type_printer==="kitchen")?.length
  console.log("numberOfMessingPrintersKichen", numberOfMessingPrintersKichen)
  const numberOfMessingPrinterslabel=4-exsitantPrinters?.filter(printer=>printer?.type_printer==="label")?.length
  console.log("numberOfMessingPrinterslabel", numberOfMessingPrinterslabel)
  const startedPrinterIdKitchen=[...exsitantPrinters]?.sort((a,b)=>b.idPrinter-a.idPrinter)[0]?.idPrinter+1 || 2
  console.log("startedPrinterIdKitchen", startedPrinterIdKitchen)
  const startedPrinterIdLabel=exsitantPrinters?.length!==0 ? startedPrinterIdKitchen+numberOfMessingPrintersKichen : 1+numberOfMessingPrintersKichen+1
  console.log("startedPrinterIdLabel", startedPrinterIdLabel)
  const missingPrintersKitchen=[]
  for (let index = startedPrinterIdKitchen; index < startedPrinterIdKitchen+numberOfMessingPrintersKichen; index++) {
    missingPrintersKitchen.push({
      idPrinter: index,
      width_ticket: 48,
      type_printer: 'kitchen',
      name: 'imprimante preparation'+index,
      number_ticket: 1,
      ip_address: '192.168.1.211',
      type: 'Réseau',
    },)

  }
  const missingPrintersLabel=[]
  for (let index = startedPrinterIdLabel; index < startedPrinterIdLabel+numberOfMessingPrinterslabel; index++) {
    missingPrintersLabel.push({
      idPrinter: index,
      width_ticket: 48,
      type_printer: 'label',
      name: 'imprimante etiquette'+index,
      number_ticket: 1,
      ip_address: '192.168.1.211',
      type: 'Réseau',
    },)

  }
  console.log("missingPrintersKitchen", missingPrintersKitchen)
  console.log("missingPrintersLabel", missingPrintersLabel)
  const UpdatedPrinters=[...exsitantPrinters,...missingPrintersKitchen, ...missingPrintersLabel]
  const updatedPrinterK=UpdatedPrinters
  ?.filter(printer=>printer.type_printer==="kitchen")
  ?.sort((a,b)=>a.idPrinter-b.idPrinter)
  ?.map((itemk, indexk)=>({
    ...itemk,
    name:"Imprimante preparation "+(indexk+1)
  }))
  const updatedPrinterL=UpdatedPrinters
  ?.filter(printer=>printer.type_printer==="label")
  ?.sort((a,b)=>a.idPrinter-b.idPrinter)
  ?.map((itemL, indexL)=>({
    ...itemL,
    name:"Imprimante etiquette "+(indexL+1)
  }))
  const UpdatedPrinters2=[...updatedPrinterK,...updatedPrinterL ]
  console.log("UpdatedPrinters2", UpdatedPrinters2)
  return UpdatedPrinters2
}
function updateProductPrinters(products, printers) {
  // Separate the printers by type
  const kitchenPrinters = printers.filter(p => p.type_printer === 'kitchen').map(p => p.idPrinter);
  const labelPrinters = printers.filter(p => p.type_printer === 'label').map(p => p.idPrinter);
  // Update each product's printer and printerLabel based on availability
  const updatedProducts=products.map(product => {
    // Update kitchen printer
    if (product.printer !== -1 && !kitchenPrinters.includes(product.printer)) {
      // Printer is no longer valid, reset or assign a valid printer
      // product.printer = kitchenPrinters.length > 0 ? kitchenPrinters[0] : -1; // Assign first available kitchen printer or -1 if none
      return {
        ...product,
        printer:kitchenPrinters.length > 0 ? kitchenPrinters[0] : -1
      }
    }

    // Update label printer
    if (product.printerLabel !== -1 && !labelPrinters.includes(product.printerLabel)) {
      // Label printer is no longer valid, reset or assign a valid printer
      // product.printerLabel = labelPrinters.length > 0 ? labelPrinters[0] : -1; // Assign first available label printer or -1 if none
      return {
        ...product,
        printerLabel:labelPrinters.length > 0 ? labelPrinters[0] : -1
      }
    }
    return product
  });

  return updatedProducts;
}
function cleanPhoneNumbers(phoneNumbers, countryCode) {
  const validNumbers = [];

  phoneNumbers.forEach((number) => {
    if (/^[0-9\s+]+$/.test(number)) {
      let cleanedNumber = number.replace(/\D/g, '');
      if (cleanedNumber.startsWith('0')) {
        cleanedNumber = cleanedNumber.slice(1);
      }
      if (!cleanedNumber.startsWith(countryCode)) {
        cleanedNumber = countryCode + cleanedNumber;
      }
      if (cleanedNumber.length >= 10 && cleanedNumber.length <= 15) {
        validNumbers.push(cleanedNumber);
      }
    }
  });

  return validNumbers;
}

// UpfrontEndLogs
const updateChangeLogs = (
  dispatch,
  setChangeLogs,
  changeLogs,
  elementId,
  elementName,
  actionType,
  changedKey,
  newValue,
  oldValue,
  listesKeys
) => {
  console.log("changedKey=>", changedKey, listesKeys)
  dispatch(
    setChangeLogs(
      {
        elementId,
        elementName,
        actionType,
        changes:actionType==="EDIT" ? {
          ...changeLogs.changes,
          [changedKey]: {
                  oldValue,
                  newValue,
                  updatedAt:new Date(),
                  // listesKeys:changedKey?.startsWith("complexKey") ? listesKeys : null
                },
        } : null
      }
  ),
  );
};
const detectName=(element, defaultLangue)=>{
  return element?.translation?.find((l) => l.langue === defaultLangue?.code)
  ?.name || element?.name
}

const detectTariff = (tariff) => {
  switch (tariff) {
    case 'Tarif 1':
      return 1;
    case 'Tarif 2':
      return 2;
    case 'Tarif 3':
      return 3;
    default:
      return 1;
  }
};
export function swapIdSizesForTariffs(product) {
  const { allSizes } = product;

  // Group sizes by name for easier comparison
  const groupedBySizeName = {};
  allSizes.forEach(size => {
      if (!groupedBySizeName[size.name_size]) {
          groupedBySizeName[size.name_size] = [];
      }
      groupedBySizeName[size.name_size].push(size);
  });

  // Iterate through each group to find and swap id_size of tariff 1 and 3
  Object.values(groupedBySizeName).forEach(sizes2 => {
      const tariff1 = sizes2.find(size => size.tariff === 1);
      const tariff3 = sizes2.find(size => size.tariff === 3);

      if (tariff1 && tariff3) {
          // Swap id_size between tariff 1 and 3
          const tempIdSize = tariff1.id_size;
          tariff1.id_size = tariff3.id_size;
          tariff3.id_size = tempIdSize;
      }
  });

  return {
      ...product,
      allSizes,
  };
}
// function compareItems(siretItems, allItems) {
  //   if (!siretItems || !allItems) {
  //     console.error('Missing items to compare');
  //     return;
  //   }

  //   const differences = siretItems.map((siretItem) => {
  //     const matchingItem = allItems.find((item) => item.idGlobal === siretItem.idGlobal);

  //     if (!matchingItem) {
  //       return { id: siretItem.idGlobal, message: 'Item not found in allItems' };
  //     }

  //     const changes = {};

  //     // Compare prices (assuming prices is an array of objects with a 'price' attribute)
  //     if (Array.isArray(siretItem.prices) && Array.isArray(matchingItem.prices)) {
  //       const priceMismatch =
  //         siretItem.prices.length !== matchingItem.prices.length ||
  //         siretItem.prices.some((priceObj, index) => {
  //           const siretPrice = Number(priceObj.price);
  //           const matchingPrice = Number(matchingItem.prices[index].price);
  //           return siretPrice !== matchingPrice;
  //         });
  //       if (priceMismatch) {
  //         changes.prices = {
  //           siretPrices: siretItem.prices,
  //           allItemsPrices: matchingItem.prices,
  //         };
  //       }
  //     }

  //     // Compare allSizes (assuming allSizes is an array of objects with a 'price' attribute)
  //     if (Array.isArray(siretItem.allSizes) && Array.isArray(matchingItem.allSizes)) {
  //       const sizesMismatch =
  //         siretItem.allSizes.length !== matchingItem.allSizes.length ||
  //         siretItem.allSizes.some((sizeObj, index) => {
  //           const siretSizePrice = Number(sizeObj.price);
  //           const matchingSizePrice = Number(matchingItem.allSizes[index].price);
  //           return siretSizePrice !== matchingSizePrice;
  //         });
  //       if (sizesMismatch) {
  //         changes.allSizes = {
  //           siretAllSizes: siretItem.allSizes,
  //           allItemsAllSizes: matchingItem.allSizes,
  //         };
  //       }
  //     }

  //     // Compare the single price attribute (as a number)
  //     const priceDiff = Number(siretItem.price) !== Number(matchingItem.price);
  //     if (priceDiff) {
  //       changes.price = {
  //         siretPrice: siretItem.price,
  //         allItemsPrice: matchingItem.price,
  //       };
  //     }

  //     // Compare removed (boolean)
  //     const removedDiff = siretItem.removed !== matchingItem.removed;
  //     if (removedDiff) {
  //       changes.removed = {
  //         siretRemoved: siretItem.removed,
  //         allItemsRemoved: matchingItem.removed,
  //       };
  //     }

  //     // Compare sold_out (boolean)
  //     const soldOutDiff = siretItem.sold_out !== matchingItem.sold_out;
  //     if (soldOutDiff) {
  //       changes.sold_out = {
  //         siretSoldOut: siretItem.sold_out,
  //         allItemsSoldOut: matchingItem.sold_out,
  //       };
  //     }

  //     // Return changes with element name
  //     return {
  //       id: siretItem.idGlobal,
  //       name: siretItem.name || matchingItem.name, // Include the name of the item if available
  //       changes,
  //       hasDifferences: Object.keys(changes).length > 0,
  //     };
  //   });

  //   // Filter and return only the items that have changes, including the changes themselves
  //   return differences.filter((item) => item.hasDifferences);
  // }

  // // Example usage
  // const differences = compareItems(siretContent?.orderJson?.items, allItems);
  // console.log("Differences with changes and names:", differences);

  // // Example usage
  // const differences = compareItems(siretContent?.orderJson?.items, allItems);
  // console.log("Differences with changes:", differences);

  // function compareOptionItems(itemsOfOptions, siretOptions) {
  //   // Flatten the items arrays from both data sources
  //   const flattenedItemsOfOptions = [].concat(...itemsOfOptions?.map(option => option?.items) || []);
  //   const flattenedSiretItems = [].concat(...siretOptions?.map(option => option?.items) || []);
  // console.log("flattenedItemsOfOptions", flattenedItemsOfOptions, "flattenedSiretItems", flattenedSiretItems)
  //   const differences = flattenedItemsOfOptions.map((optionItem) => {
  //     const matchingItem = flattenedSiretItems.find(item => item.idGlobal === optionItem.idGlobal);

  //     if (!matchingItem) {
  //       return { id: optionItem.idGlobal, message: "Item not found in siretOptions" };
  //     }

  //     const changes = {};

  //     // Compare prices (assuming prices is an array of objects with a 'price' attribute)
  //     if (Array.isArray(optionItem.prices) && Array.isArray(matchingItem.prices)) {
  //       const priceMismatch = optionItem.prices.length !== matchingItem.prices.length ||
  //         optionItem.prices.some((priceObj, index) => {
  //           const optionPrice = Number(priceObj.price);
  //           const siretPrice = Number(matchingItem.prices[index].price);
  //           return optionPrice !== siretPrice;
  //         });
  //       if (priceMismatch) {
  //         changes.prices = {
  //           optionPrices: optionItem.prices,
  //           siretPrices: matchingItem.prices,
  //         };
  //       }
  //     }

  //     // Compare individual price attributes
  //     const priceFields = [
  //       'price',
  //       'price_junior',
  //       'price_senior',
  //       'price_mega',
  //       'price_size1',
  //       'price_size2',
  //       'price_size3',
  //       'price_size4',
  //       'price_size5',
  //       'price_size6',
  //       'price_size7',
  //     ];

  //     priceFields.forEach((field) => {
  //       const optionPrice = Number(optionItem[field]);
  //       const siretPrice = Number(matchingItem[field]);

  //       if (optionPrice !== siretPrice) {
  //         changes[field] = {
  //           optionPrice,
  //           siretPrice
  //         };
  //       }
  //     });

  //     // Compare removed (boolean)
  //     const removedDiff = optionItem.removed !== matchingItem.removed;
  //     if (removedDiff) {
  //       changes.removed = {
  //         optionRemoved: optionItem.removed,
  //         siretRemoved: matchingItem.removed,
  //       };
  //     }

  //     // Compare sold_out (boolean)
  //     const soldOutDiff = optionItem.sold_out !== matchingItem.sold_out;
  //     if (soldOutDiff) {
  //       changes.sold_out = {
  //         optionSoldOut: optionItem.sold_out,
  //         siretSoldOut: matchingItem.sold_out,
  //       };
  //     }

  //     return {
  //       id: optionItem.idGlobal,
  //       name: optionItem.name || matchingItem.name, // Include name if available
  //       changes,
  //       hasDifferences: Object.keys(changes).length > 0,
  //     };
  //   });

  //   // Filter and return only items with changes
  //   return differences.filter(item => item.hasDifferences);
  // }
  // function comparePrices(localJson, customisedPricesL) {
  //   const result = { produits: [], options: [] };

  //   // Helper function to compare allSizes and return differences
  //   function compareAllSizes(localSizes, customSizes) {
  //     return customSizes
  //       .map((customSize) => {
  //         const localSize = localSizes.find(
  //           (s) => s.id_size === customSize.id_size && s.tariff === customSize.tariff,
  //         );
  //         if (!localSize) return customSize;

  //         let sizeChanges = {};
  //         Object.keys(customSize).forEach((key) => {
  //           if (customSize[key] !== localSize[key]) {
  //             sizeChanges = { ...sizeChanges, ...customSize };
  //           }
  //         });
  //         return Object.keys(sizeChanges).length > 0 ? sizeChanges : null;
  //       })
  //       .filter((change) => change !== null);
  //   }

  //   // Helper function to compare prices array and return differences
  //   function comparePricesArray(localPrices, customPrices) {
  //     return customPrices
  //       .map((customPrice) => {
  //         const localPrice = localPrices.find(
  //           (p) => p.id === customPrice.id && p.tariff === customPrice.tariff,
  //         );
  //         if (!localPrice) return customPrice;

  //         let priceChanges = {};
  //         Object.keys(customPrice).forEach((key) => {
  //           if (customPrice[key] !== localPrice[key]) {
  //             // priceChanges[key] = customPrice[key];
  //             priceChanges = { ...priceChanges, ...customPrice };
  //           }
  //         });
  //         return Object.keys(priceChanges).length > 0 ? priceChanges : null;
  //       })
  //       .filter((change) => change !== null);
  //   }

  //   // Compare items
  //   customisedPricesL.produits.forEach((customItem) => {
  //     const localItem = localJson.items.find((item) => item.idGlobal === customItem.idGlobal);

  //     if (!localItem) {
  //       result.produits.push({
  //         idGlobal: customItem.idGlobal,
  //         name: customItem.name,
  //         changes: customItem,
  //       });
  //     } else {
  //       const itemChanges = {};
  //       if (customItem.price !== localItem.price) itemChanges.price = customItem.price;
  //       if (customItem.sold_out !== localItem.sold_out) itemChanges.sold_out = customItem.sold_out;
  //       if (customItem.removed !== localItem.removed) itemChanges.removed = customItem.removed;

  //       // Compare allSizes if available
  //       const allSizesChanges = compareAllSizes(
  //         localItem.allSizes || [],
  //         customItem.allSizes || [],
  //       );
  //       if (allSizesChanges.length > 0) itemChanges.allSizes = allSizesChanges;

  //       // Compare prices array if available
  //       const pricesChanges = comparePricesArray(localItem.prices || [], customItem.prices || []);
  //       if (pricesChanges.length > 0) itemChanges.prices = pricesChanges;

  //       if (Object.keys(itemChanges).length > 0) {
  //         result.produits.push({
  //           idGlobal: customItem.idGlobal,
  //           name: customItem.name,
  //           changes: itemChanges,
  //         });
  //       }
  //     }
  //   });

  //   // Compare options
  //   // Compare options
  //   customisedPricesL.options.forEach((customOption) => {
  //     // Find the corresponding parent option in localJson by matching idParent
  //     const parentOption = localJson.options.find(
  //       (option) => option.idGlobal === customOption.idParent,
  //     );

  //     if (!parentOption) {
  //       // If the parent option doesn't exist, add the entire customOption as a new change
  //       result.options.push({ idParent: customOption.idParent, changes: customOption });
  //     } else {
  //       // Find the specific item within the parent option by matching idGlobal
  //       const localItem = parentOption.items.find(
  //         (item) => item.idGlobal === customOption.idGlobal,
  //       );

  //       if (!localItem) {
  //         // If the item within the parent option is new, add it directly
  //         result.options.push({
  //           idParent: customOption.idParent,
  //           idGlobal: customOption.idGlobal,
  //           name: customOption.name,
  //           changes: customOption,
  //         });
  //       } else {
  //         // Object to store changes for this specific item
  //         const itemChanges = {};

  //         // List of keys to compare, including additional price-related keys
  //         const keysToCompare = [
  //           'price',
  //           'price_junior',
  //           'price_senior',
  //           'price_mega',
  //           'price_size1',
  //           'price_size2',
  //           'price_size3',
  //           'price_size4',
  //           'price_size5',
  //           'price_size6',
  //           'price_size7',
  //           'removed',
  //           'sold_out',
  //         ];

  //         // Compare each key in keysToCompare
  //         keysToCompare.forEach((key) => {
  //           if (customOption[key] !== localItem[key]) {
  //             itemChanges[key] = customOption[key];
  //           }
  //         });

  //         // Compare the prices array
  //         const pricesChanges = customOption.prices
  //           .map((customPrice) => {
  //             // Find the matching price in localItem by id_price
  //             const localPrice = (localItem.prices || []).find(
  //               (lp) => lp.id_price === customPrice.id_price,
  //             );
  //             if (!localPrice) return customPrice; // If no matching price found, consider it a new entry

  //             // Object to track changes within each price entry
  //             let priceChanges = {};

  //             // Compare each relevant field in customPrice and localPrice
  //             Object.keys(customPrice).forEach((key) => {
  //               if (customPrice[key] !== localPrice[key]) {
  //                 priceChanges = {...priceChanges,...customPrice};
  //               }
  //             });

  //             return Object.keys(priceChanges).length > 0
  //               ? { ...priceChanges, id_price: customPrice.id_price }
  //               : null;
  //           })
  //           .filter((change) => change !== null);

  //         // If there are any price changes, add them to itemChanges
  //         if (pricesChanges.length > 0) itemChanges.prices = pricesChanges;

  //         // If there are any changes, push them to the result
  //         if (Object.keys(itemChanges).length > 0) {
  //           result.options.push({
  //             idParent: customOption.idParent,
  //             idGlobal: customOption.idGlobal,
  //             name: customOption.name,
  //             changes: itemChanges,
  //           });
  //         }
  //       }
  //     }
  //   });

  //   return result;
  // }
export {
  ConvertirMonetaire,
  detectName,
  detectTariff,
  updateChangeLogs, 
  updateCart,
  fetchImageURLs,
  uploadImage,
  handleLogout,
  handleSaveImage,
  objectsAreEqual,
  objectsAreEqual2,
  adjustDataForTimeZone,
  convertHexToRgb,
  convertRgbToHex,
  updateFranchiseOptions,
  updateFranchiseOptions2,
  updatedPricesInProductFn,
  updatedPricesInOptionFn,
  removeSpacesAndAccent,
  addSpacesToNumber,
  addImageURLS,
  addPrinters,
  updateProductPrinters,
  cleanPhoneNumbers,
  logChange,
  
};
