import { Box, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { InfoOutlined } from '@material-ui/icons';
import dateFormat from 'dateformat';
// import frLocale from 'date-fns/locale/fr';
// import Calendar from './dashboardRestau-components/Calendar';
import FromtoDatesChoice from './dashboardRestau-components/FromtoDatesChoice';
import SelectRestau from './dashboardRestau-components/SelectRestau';
import authService from '../../services/auth.service';
import OptDashboardRestau from './OptDashboardRestau';
import OptDashboardFranchise from './OptDashboardFranchise';


const OptionDashboard = () => {
  const currentDate = new Date();
  // const [forDay, setForDay] = useState(true);
  // const [date, setDate] = useState(currentDate);
  const currentUser = authService.getCurrentUser();
  const [selectedRestau, setSelectedRestau] = useState(null);


  //new traitement dates [debut & fin]
  
  // const date = new Date();
  const firstDay = dateFormat(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), "yyyy-mm-dd");
  const lastDay = dateFormat(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), "yyyy-mm-dd");
  // const lastDay = dateFormat(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-mm-dd');

//   const currentUser = AuthService.getCurrentUser();
  const [datedebut, setDatedebut] = useState(firstDay);
  const [datefin, setDatefin] = useState(lastDay);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& .block': {
          background: { xs: 'white ' },
          borderRadius: { xs: '8px ' },
          padding: { xs: '14px', md: '20px' },
          boxShadow: {
            xs: '0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
          },
        },
      }}
    >
      {/* head  */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 9,
        }}
        className="head"
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.text.textDark,
              fontSize: '24px',
              fontWeight: '700',
            }}
            className="left"
          >
            Mes Options
          </Box>
          <Tooltip title="le montant des plates-formes n'est pas pris en considération dans ce CA.">
            <InfoOutlined
              sx={{
                color: (t) => t.palette.mainColor,
                fontSize: '24px',
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '70%', xl: '50%' },
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: '20px',
          }}
          className="right"
        >
          {currentUser?.roles !== 'moderator' && (
            <SelectRestau selectedRestau={selectedRestau} setSelectedRestau={setSelectedRestau} />
          )}
          <FromtoDatesChoice 
          datedebut={datedebut} 
          setDatedebut={setDatedebut} 
          datefin={datefin} 
          setDatefin={setDatefin} 

          // date={date} 
          // setDate={setDate} 
          // forDay={forDay} 
          // setForDay={setForDay} 
          />
        </Box>
      </Box>
      {/* !head  */}
      {currentUser?.roles === 'moderator' || selectedRestau ? (
        <OptDashboardRestau
          // date={date}
          // forDay={forDay}
          
          datedebut={datedebut} 
          datefin={datefin} 
          currentUser={
            selectedRestau
              ? { siret: selectedRestau?.restos?.siret, roles: 'moderator' }
              : currentUser
          }
        />
      ) : (
        <OptDashboardFranchise 
        // date={date} 
        // forDay={forDay} 
          
        datedebut={datedebut} 
        datefin={datefin}
        currentUser={currentUser} 
        />
      )}
    </Box>
  );
};

export default OptionDashboard;
