/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Button,
} from '@mui/material';
import { Box, Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { firestore } from '../../firebase/firebase';

const Deliveroo = ({
  listEquipments,
  selectedModule,
  siret,
  modulesWithPos,
  handleSwitchChange,
  handleCloseDialog,
}) => {
  const [keenId, setKeenId] = useState(null);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [moduleToSave, setModuleToSave] = useState({
    ...selectedModule,
    status: true,
  });

  console.log("selected module from deliveroo", selectedModule);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setModuleToSave({
      ...moduleToSave,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setModuleToSave({
      ...moduleToSave,
      [name]: checked,
    });
  };

  useEffect(() => {
    setModuleToSave((prevModule) => ({
      ...prevModule,
      keen_user_id: keenId,
    }));
  }, [keenId]);

  const handleEquipmentChange = (selectedEquipmentId) => {
    setSelectedEquipments((prevSelected) => {
      const updatedSelected = prevSelected.includes(selectedEquipmentId)
        ? prevSelected.filter((id) => id !== selectedEquipmentId)
        : [...prevSelected, selectedEquipmentId];
      return updatedSelected;
    });
  };


  const saveModule = async () => {
    try {
      const collectionRef = firestore.collection('marketplaceModules');
      const docRef = collectionRef.doc(siret);
      const docSnapshot = await docRef.get();

      const updatedUserModules = docSnapshot.exists ? docSnapshot.data().users : [];

      selectedEquipments.forEach((equipmentId) => {
        const equipment = listEquipments.find((eq) => eq.id === equipmentId);
        if (equipment) {
          // Check if a module with the same id_module already exists in the user's modules
          const userIndex = updatedUserModules.findIndex(
            (user) => user.keen_user_id === equipment.user_keen_id,
          );

          if (userIndex !== -1) {
            // Update the existing module instead of pushing a new one
            const user = updatedUserModules[userIndex];
            const existingModuleIndex = user.modules.findIndex(
              (module) => module.id_module === moduleToSave.id_module,
            );

            if (existingModuleIndex !== -1) {
              // Update the existing module's properties
              user.modules[existingModuleIndex] = {
                ...user.modules[existingModuleIndex], // Preserve existing properties
                selected_pos: equipment.name,
                keen_user_id: equipment.user_keen_id,
              };
            } else {
              // If the module with the same id_module does not exist, push the new module
              user.modules.push({
                ...moduleToSave,
                selected_pos: equipment.name,
                keen_user_id: equipment.user_keen_id,
                id_module: uuidv4(),
              });
            }
          } else {
            // If the user does not exist, create a new user entry with the new module
            updatedUserModules.push({
              keen_user_id: equipment.user_keen_id,
              modules: [
                {
                  ...moduleToSave,
                  selected_pos: equipment.name,
                  keen_user_id: equipment.user_keen_id,
                  id_module: uuidv4(),
                },
              ],
            });
          }
        }
      });

      // Update Firestore with the updated user modules
      await docRef.set({ users: updatedUserModules }, { merge: true });
      console.log('Modules successfully updated/added');
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding/updating document:', error);
    }
  };

  return (
    <>
      <DialogContent>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ fontSize: '20px', mr: 1 }} />
            <Typography variant="h4">Deliveroo</Typography>
          </Box>
          <Divider sx={{ my: 1, mb: 2 }} />
          <InputLabel sx={{ fontSize: '14px' }} htmlFor="location_id">
            Location ID
          </InputLabel>
          <TextField
            required
            id="location_id"
            name="location_id"
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange}
            value={moduleToSave.location_id}
          />
          <FormGroup>
            <FormControlLabel
              name="mode_deliveroo_developer"
              onChange={handleCheckBoxChange}
              control={<Checkbox />}
              label="Mode deliveroo developpeur"
            />
            <FormControlLabel
              name="auto_accept_commands"
              onChange={handleCheckBoxChange}
              control={<Checkbox />}
              label="Accepter les commandes Deliveroo automatiquement"
            />
          </FormGroup>

          <Typography sx={{ fontWeight: 'bold', my: 1 }} variant="h6">
            Choisir POS
          </Typography>
          <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            {listEquipments
              ?.filter(
                (equipment) =>
                  !modulesWithPos.some((module) => module.selected_pos === equipment.name),
              )
              .map((equipment) => (
                <div key={equipment.id}>
                  <FormControlLabel
                    label={equipment.name}
                    control={
                      <Switch
                        onChange={() => handleEquipmentChange(equipment.id)}
                      />
                    }
                  />
                </div>
              ))}
          </FormControl>
          <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            {modulesWithPos?.map((module) => (
              <FormControlLabel
                label={module.selected_pos}
                control={
                  <Switch
                    checked={module.status}
                    onChange={() => handleSwitchChange(module.id_module, siret)}
                  />
                }
              />
            ))}
          </FormControl>
          <Button onClick={saveModule} sx={{ width: 1, my: 1 }} variant="contained" color="success">
          Enregistrer
          </Button>
        </Grid>
      </DialogContent>
    </>
  );
};

Deliveroo.propTypes = {
  listEquipments: PropTypes.array,
  selectedModule: PropTypes.func,
  handleSwitchChange: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  siret: PropTypes.func,
  modulesWithPos: PropTypes.array,
};

export default Deliveroo;
