import { Box, Typography } from '@material-ui/core';
import { 
  // ArrowUpward, 
  WarningAmber } from '@material-ui/icons';
import dateFormat from 'dateformat';
import PropsType from 'prop-types';
import { useSelector } from 'react-redux';

import React, {
  // useEffect,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
// import authService from '../../../services/auth.service';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';
import { privateRequest } from '../../../requestMethods';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import Loading from '../../../components/custum-elements/Loading';

const Graph = ({ date, forDay, setTotalTtc, currentUser }) => {
  const [data, setData] = useState({});
  const [errorMessage, seterrorMessage] = useState(false);
  const [loading, setloading] = useState(false);
  const formattedDate = dateFormat(date, 'yyyy-mm-dd');
  // const
  // const currentUser = authService.getCurrentUser();
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  // const userId = currentUser?.roles === 'moderator' ? currentUser?.siret : currentUser?.id;
  const getTopVentsProducts = async () => {
    setloading(true);
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/GraphMesChiffredAffaireParHeure/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setData({
          yaxisArray: res?.data?.yaxisArray,
          xaxisArray: !forDay
            ? res?.data?.xaxisArray.map((el) => dateFormat(new Date(el), 'DD'))
            : res?.data?.xaxisArray.map(el=>{
              if(el<10){
                return `0${el}`
              }
              return el
            }),
            totalDifference:res?.data?.totalDifference
        },
        );
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/GraphMesChiffredAffaireParJour/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setData({
          yaxisArray: res?.data?.yaxisArray,
          xaxisArray: !forDay
            ? res?.data?.xaxisArray.map((el) => dateFormat(new Date(el), 'dd'))
            : res?.data?.xaxisArray.map(el=>{
              if(el<10){
                return `0${el}`
              }
              return el
            }),
            totalDifference:res?.data?.totalDifference
        });
      }
      seterrorMessage(false);
    } catch (error) {
      console.log(error);
      seterrorMessage(true);
    }
    setloading(false);
  };
  // useEffect(() => {
  //   getTopVentsProducts();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(() => getTopVentsProducts(), 500, [
    date,
    currentUser?.siret,
    currentUser?.id,
    forDay,
  ]);
  const keysArray = data?.xaxisArray;
  const valuesArray = data?.yaxisArray;
  setTotalTtc(data?.totalDifference);
  const optionscolumnchart = {
    chart: {
      id: 'column-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
      toolbar: {
        show: true,
      },
    },
    colors: ['rgb(245, 111, 59)', '#0b70fb', '#f64e60'],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '80%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: keysArray || [],
      // categories : [...Array(31).keys()].slice(1)
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${ConvertirMonetaire(currencyCode, currencySymbol, val)}`;
        },
      },
      x: {
        formatter(val) {
          console.log("date inside tooltip", date)
          return forDay
            ? `${val}h `
            : `${val} ${dateFormat(date, 'mmm, yyyy')}`;
        },
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
  };
  const seriescolumnchart = [
    {
      name:"",
      data: valuesArray?.map((value) => value?.toFixed(2)) || [],
    },
  ];
  const CustomHead =forDay
    ? `Heure (${dateFormat(date, 'dd mmm, yyyy')})`
    : `Jour (${dateFormat(date, 'mmm, yyyy')})`;
    console.log("check this info!!","loading",loading, "errorMessage",errorMessage, (!loading && !errorMessage) )

  return (
    <Box>
      <Box sx={{
        display: { xs: 'block', md: 'none' },
      }}>
        <Box
        sx={{
          fontSize: '20px',
          color: (t) => t.palette.text.textDark,
          
        }}
        className="title"
      >
        CA par
        {CustomHead}
        
      </Box>
      {/* <Box
            sx={{
              fontSize: '25px',
              fontWeight: '700',
              transform:(!loading && !errorMessage) ? "scale(1)": "scale(0)",
              // overflow:"hidden",
              transition:"transform .4s ease"
            }}
            className="total"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, data?.totalDifference)}
          </Box> */}
      </Box>
      
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        className="graphHead"
      >
        <Box
          sx={{
            fontSize: '18px',
            color: (t) => t.palette.text.textDark,
            fontWeight: '600',
            mb: '20px',
          }}
          className="title"
        >
          Chiffre d'Affaires par {CustomHead}
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '38px',
          }}
          className="moreInfo"
        >
          <Box
            sx={{
              fontSize: '28px',
              fontWeight: '700',
              transform:(!loading && !errorMessage) ? "scale(1)": "scale(0)",
              // overflow:"hidden",
              transition:"transform .4s ease"
            }}
            className="total"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, data?.totalDifference)}
          </Box>
          <Box
            sx={{
              display: 'none',
              alignItems: 'center',
              gap: '14px',
            }}
            className="percent"
          >
            <Box
              sx={{
                backgroundColor: (t) => t.palette.mainColor,
                color: 'white',

                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px',
              }}
              className="icon"
            >
              <ArrowUpward
                sx={{
                  fontSize: '18px',
                }}
              />
            </Box>
            <Box
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: (t) => t.palette.mainColor,
              }}
              className="text"
            >
              <Box className="percentValue">0,8%</Box>
              <Box
                sx={{
                  fontSize: '12px',
                  color: (t) => t.palette.Soft,
                }}
              >
                Alors Dernier Jour.
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>

      <Box
        className="scrollableBox"
        sx={{
          overflow: 'hidden',
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
        }}
      >
        {loading ? (
          <Box
            sx={{
              '& svg': {
                fill: (t) => t.palette.mainColor,
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                backdropFilter: 'blur(1px)',
                background: '#ffffff5e',
                zIndex: 10,
              }}
            >
              <Loading type="bubbles" width={40} height={40} />
              <Typography
                sx={{
                  fontSize: '16px',
                  color: (t) => t.palette.mainColor,
                  textAlign: 'center',
                  px: '10px',
                  fontWeight: '600',
                }}
              >
                En cours de chargement...
              </Typography>
            </Box>
          </Box>
        ) : (
          errorMessage && (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                backdropFilter: 'blur(1px)',
                background: '#ffffff5e',
              }}
            >
              <WarningAmber
                sx={{
                  color: (t) => t.palette.error.main,
                }}
              />
              <Typography
                sx={{
                  fontSize: '13px',
                  color: (t) => t.palette.error.main,
                  textAlign: 'center',
                  px: '10px',
                  fontWeight: '500',
                }}
              >
                Une erreur est survenue lors de la récupération des données.
              </Typography>
            </Box>
          )
        )}
        <Box
          sx={{
            width: { xs: '200%', sm: '100%' },
          }}
        >
          {' '}
          <Chart
            options={optionscolumnchart}
            series={seriescolumnchart}
            type="bar"
            height="300px"
          />
        </Box>
      </Box>
    </Box>
  );
};
Graph.propTypes = {
  date: PropsType.any.isRequired,
  forDay: PropsType.any.isRequired,
  setTotalTtc: PropsType.any.isRequired,
  currentUser: PropsType.any.isRequired,
};
export default Graph;
