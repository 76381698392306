import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropsType from 'prop-types';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
// import authService from '../../../services/auth.service';
import { ConvertirMonetaire, addSpacesToNumber } from '../../../utility/utilityFunctions';
import ProgressRectangle from '../../../components/icons/ProgressRectangle';
import { privateRequest } from '../../../requestMethods';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import Loading from '../../../components/custum-elements/Loading';
import EmptyData from '../../../components/custum-elements/EmptyData';

const Types = ({ date, forDay, currentUser }) => {
  const formattedDate = dateFormat(date, 'yyyy-mm-dd');
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // const currentUser=authService.getCurrentUser()
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  // const userId=currentUser?.roles==="moderator" ? currentUser?.siret : currentUser?.id
  ConvertirMonetaire(currencyCode, currencySymbol, 13);
  const getTypes = async () => {
    setLoading(true);
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParTypeCommande/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setData(res.data);
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiqueParTypeCommande/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setData(res.data);
      }
      setErrorMessage(null);
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
      );
      setData([]);
    }
    setLoading(false);
  };
  useDebounce(
    ()=>
    {
      getTypes();
      },
    500,
    [date, currentUser?.siret, currentUser?.id, forDay]
  )
  const typeComandes = data.map((element) => ({
    title: element.type,
    montant: element.ttcAmount,
    percent: element.percentage,
    nbreCommandes: element.nombrescommandes,
  }));
  return (
    <Box
      className="Types block"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '10px',
        }}
        className="top"
      >
        <Box
          sx={{
            fontSize: '18px',
            fontWeight: '600',
            color: (t) => t.palette.text.textDark,
          }}
          className="left"
        >
          Types
        </Box>
        <Box
          sx={{
            fontSize: '14px',
            display: 'none',
            color: (t) => t.palette.text.textDark,
          }}
          className="right"
        >
          See All
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
        className="typesList"
      >
        {loading ? (
        <Box
        sx={{
         width: '100%',
          height: '100%',
          '& svg': {
            fill: (t) => t.palette.mainColor,
          },
        }}
      >
        <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection:"column",
          justifyContent: 'center',
        }}
      >
        <Loading type="bubbles" width={40} height={40} />
        <Typography sx={{
          fontSize:"13px",
          color: (t) => t.palette.mainColor,
          textAlign:"center",
          px:"10px",
          fontWeight:"500"
        }}>
        En cours de chargement...
        </Typography>
      </Box>
        
      </Box>
      ) :
        errorMessage ? <Box>{errorMessage}</Box>
        : typeComandes?.length===0 ? 
        <Box sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          width:"100%"
        }}><EmptyData/></Box>
        : typeComandes.map((type) => (
          <Box
            sx={{
              background: 'rgba(246, 246, 246, 1)',
              padding: { xs: '5px', sm: '10px' },
              borderRadius: '8px',
              width: { xs: 'max-content', md: 'calc(100% / 2 - 5px)', xl: 'max-content' },
            }}
            className="type"
          >
            <Box
              sx={{
                mb: '7px',
                fontSize: '14px',
                textAlign: 'center',
                color: (t) => t.palette.text.textDark,
              }}
              className="title"
            >
              {type.title}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              {ConvertirMonetaire(currencyCode, currencySymbol, type.montant)}
            </Box>
            <Box
              sx={{
                backgroundColor: 'white',
                padding: { xs: '5px', sm: '8' },
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="progressContainer"
            >
              <Box
                sx={{
                  position: 'absolute',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: (t) => t.palette.text.textDark,
                }}
                className="text"
              >
                <Box
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                  className="nbreCommandes"
                >
                  {addSpacesToNumber(type.nbreCommandes)} cmd
                </Box>
                <Box
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                  className="nbreCommandes"
                >
                  {type.percent}%{' '}
                </Box>
              </Box>
              <ProgressRectangle
                percent={type.percent}
                sx={{
                  width: { xs: '80', sm: '100' },
                  height: { xs: '68', sm: '75' },
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
Types.propTypes = {
  date: PropsType.any.isRequired,
  forDay: PropsType.any.isRequired,
  currentUser: PropsType.any.isRequired,
};
export default Types;
