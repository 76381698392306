import { Box } from '@material-ui/core';
import React from 'react';
import PropsType from 'prop-types';
import useClients from '../custom-hooks/useClients';
import { addSpacesToNumber } from '../../../utility/utilityFunctions';

const Clients = ({date, forDay, totalTtc, currentUser}) => {
    const { NewClients } = useClients(
      currentUser,
      date,
      forDay,
      totalTtc
    ); 
    const {
      loading,
      errorMessage,
      data
    }= NewClients
     return (
       <Box>
         {loading ? (
           'loading...'
         ) : errorMessage ? (
           'error'
         ) : (
           <>
           
             <Box sx={{
              display:"flex",
              justifyContent:"space-between"
             }}>
               <Box sx={{
                fontWeight:"700",
               }} className="title">Nouveau Clients</Box>
               <Box >{addSpacesToNumber(data?.actual)} clients</Box>
             </Box>
             <Box className="percent">{data?.pourcentage}%</Box>
           </>
         )}
       </Box>
     );
};
Clients.propTypes = {
    date: PropsType.any.isRequired,
    forDay: PropsType.any.isRequired,
    totalTtc: PropsType.any.isRequired,
    currentUser: PropsType.any.isRequired,
  };
export default Clients;
