import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Divider,
  Box,
  Button,
  Grid,
  Alert,
  Stack,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@material-ui/core';
import AuthService from '../../../services/auth.service';
import ProduitService from '../../../services/ProduitService';
import CustomCheckbox from '../../../components/forms/custom-elements/CustomCheckbox';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';

const ConfigProduits = (props) => {
  const { produitchoiceEditog } = props;

  const initialProduitState = {
    categorieProduitID: '',
    CODE: '',
    NAME: '',
    DESCRIPTION: '',
    PRICEBUY: 0,
    PRICESELL: 0,
    TAXCAT: '',
    order_item: 0,
    price_type: 'per_piece',
    sp: 0,
    emp: 0,
    lv: 0,
    bar: 0,
    terasse: 0,
    happy_hour: 0,
    removed: 0,
    menu: 0,
    path: '',
    printer: 0,
    many_size: 0,
    different_price: 0,
    color: '',
    TAXEMP: '',
    TAXLV: '',
    label: 0,
    printerLabel: 0,
    display_kitchen: 0,
    option_free: 0,
    special_option: 0,
    number_line: 0,
    number_column: 0,
    max_line: 0,
    additional_sale: 0,
    moment_product: 0,
    quantity: 0,
    REF_WEB: '',
    unit: 'piece',
    weight: 0,
    extra_unit: '',
    APPLY_DISCOUNT: 0,
    PRINTER_KITCHEN: '',
    HIDDEN_BORNE: 0,
    SOLD_OUT: 0,
    FREE_PRICE: 0,
    ALIAS_KITCHEN: '',
    SEND_COLOR: 0,
    HAS_DISPLAY_TIME: 0,
    FIRST_PERIOD: 0,
    END_PERIOD: 0,
    REMOVED_BY_ADMIN: 0,
    STORABLE: 0,
    MIN_STORE: 0,
    ADDITIONAL_SALE_AT_SPOT: 0,
    ADDITIONAL_SALE_TAKE_AWAY: 0,
    LATER: 0,
    PRINT_INGREDIENTS: 0,
    OPTIONS_FREE_GROUPED: 0,
    AVAILABLE_CAISSE: 0,
    LABELED: 0,
    BEST_SALE: 0,
    PROMO_BASKET: 0,
    DISPLAY_IMAGE_IN_KITCHEN: 0,
    DAILY_STOCK: 0,
    HAS_BAR_CODE: 0,
    HIDDEN_UBEREAT: 0,
    DESCRIPTION_UBEREAT: '',
    TOP_PRODUCT: 0,
    HIDE_NAME_IN_BORNE: 0,
    HIDE_PRICE_IN_BORNE: 0,
    HAS_TIMETABLE: 0,
    UNAVAILABLE_ONLINE: 0,
    HIDDEN_ONLINE: 0,
    PRODUCT_UBEREAT: 0,
    MIXED_PRODUCT: 0,
    price_sp: 0,
    price_emp: 0,
    price_lv: 0,
    price_bar: 0,
    price_terasse: 0,
    price_happy: 0,
    PRICE_PLATFORM: 0,
    PRICE_DRIVE: 0,
    RECTANGULAR_IMAGE: 0,
    PATH_2: '',
    PRODUCT_LOYALTY: 0,
    PRICE_POINT: 0,
    HIDDEN_ONLINE_TEMPORARY: 0,
    HIDDEN_UBEREAT_TEMPORARY: 0,
    HIDDEN_BORNE_TEMPORARY: 0,
    SOLD_OUT_TEMPORARY: 0,
    ENABLE_IN_OPENING: 0,
    PRICE_OPENING: 0,
    prepared: 0,
  };

  const currentUser = AuthService.getCurrentUser();
  const [produit, setProduit] = useState(initialProduitState);
  const [categorieNoms, seCategorieNoms] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const MessageSuccess = (
    <Alert variant="filled" severity="success">
      Ajout de la catégorie effectué avec succès !
    </Alert>
  );
  const MessageError = (
    <Alert variant="filled" severity="error">
      Erreur d ajout de la catégorie !
    </Alert>
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProduit({ ...produit, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setProduit({ ...produit, [name]: value, [name]: checked });
  };

  const retrieveCategorieDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_NODEJS}/api/produit/${produitchoiceEditog}`)
      .then((response) => {
        setProduit(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveCategorieDetails(produitchoiceEditog);
  }, [produitchoiceEditog]);

  const retrieveCategorieNames = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL_NODEJS}/api/categorie_produit/sousCategorie/${currentUser?.id}`,
      )
      .then((response) => {
        seCategorieNoms(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveCategorieNames();
  }, []);

  const refreshList = () => {
    retrieveCategorieNames();
    setProduit(initialProduitState);
  };

  const saveProduit = () => {
    const data = {
      categorieProduitID: produit.categorieProduitID,
      CODE: produit.CODE,
      NAME: produit.NAME,
      DESCRIPTION: produit.DESCRIPTION,
      PRICEBUY: produit.PRICEBUY,
      PRICESELL: produit.PRICESELL,
      TAXCAT: produit.TAXCAT,
      order_item: produit.order_item,
      price_type: produit.price_type,
      sp: produit.sp,
      emp: produit.emp,
      lv: produit.lv,
      bar: produit.bar,
      terasse: produit.terasse,
      happy_hour: produit.happy_hour,
      removed: produit.removed,
      menu: produit.menu,
      path: produit.path,
      printer: produit.printer,
      many_size: produit.many_size,
      different_price: produit.different_price,
      color: produit.color,
      TAXEMP: produit.TAXEMP,
      TAXLV: produit.TAXLV,
      label: produit.label,
      printerLabel: produit.printerLabel,
      display_kitchen: produit.display_kitchen,
      option_free: produit.option_free,
      special_option: produit.special_option,
      number_line: produit.number_line,
      number_column: produit.number_column,
      max_line: produit.max_line,
      additional_sale: produit.additional_sale,
      moment_product: produit.moment_product,
      quantity: produit.quantity,
      REF_WEB: produit.REF_WEB,
      unit: produit.unit,
      weight: produit.weight,
      extra_unit: produit.extra_unit,
      APPLY_DISCOUNT: produit.APPLY_DISCOUNT,
      PRINTER_KITCHEN: produit.PRINTER_KITCHEN,
      HIDDEN_BORNE: produit.HIDDEN_BORNE,
      SOLD_OUT: produit.SOLD_OUT,
      FREE_PRICE: produit.FREE_PRICE,
      ALIAS_KITCHEN: produit.ALIAS_KITCHEN,
      SEND_COLOR: produit.SEND_COLOR,
      HAS_DISPLAY_TIME: produit.HAS_DISPLAY_TIME,
      FIRST_PERIOD: produit.FIRST_PERIOD,
      END_PERIOD: produit.END_PERIOD,
      REMOVED_BY_ADMIN: produit.REMOVED_BY_ADMIN,
      STORABLE: produit.STORABLE,
      MIN_STORE: produit.MIN_STORE,
      ADDITIONAL_SALE_AT_SPOT: produit.ADDITIONAL_SALE_AT_SPOT,
      ADDITIONAL_SALE_TAKE_AWAY: produit.ADDITIONAL_SALE_TAKE_AWAY,
      LATER: produit.LATER,
      PRINT_INGREDIENTS: produit.PRINT_INGREDIENTS,
      OPTIONS_FREE_GROUPED: produit.OPTIONS_FREE_GROUPED,
      AVAILABLE_CAISSE: produit.AVAILABLE_CAISSE,
      LABELED: produit.LABELED,
      BEST_SALE: produit.BEST_SALE,
      PROMO_BASKET: produit.PROMO_BASKET,
      DISPLAY_IMAGE_IN_KITCHEN: produit.DISPLAY_IMAGE_IN_KITCHEN,
      DAILY_STOCK: produit.DAILY_STOCK,
      HAS_BAR_CODE: produit.HAS_BAR_CODE,
      HIDDEN_UBEREAT: produit.HIDDEN_UBEREAT,
      DESCRIPTION_UBEREAT: produit.DESCRIPTION_UBEREAT,
      TOP_PRODUCT: produit.TOP_PRODUCT,
      HIDE_NAME_IN_BORNE: produit.HIDE_NAME_IN_BORNE,
      HIDE_PRICE_IN_BORNE: produit.HIDE_PRICE_IN_BORNE,
      HAS_TIMETABLE: produit.HAS_TIMETABLE,
      UNAVAILABLE_ONLINE: produit.UNAVAILABLE_ONLINE,
      HIDDEN_ONLINE: produit.HIDDEN_ONLINE,
      PRODUCT_UBEREAT: produit.PRODUCT_UBEREAT,
      MIXED_PRODUCT: produit.MIXED_PRODUCT,
      price_sp: produit.price_sp,
      price_emp: produit.price_emp,
      price_lv: produit.price_lv,
      price_bar: produit.price_bar,
      price_terasse: produit.price_terasse,
      price_happy: produit.price_happy,
      PRICE_PLATFORM: produit.PRICE_PLATFORM,
      PRICE_DRIVE: produit.PRICE_DRIVE,
      RECTANGULAR_IMAGE: produit.RECTANGULAR_IMAGE,
      PATH_2: produit.PATH_2,
      PRODUCT_LOYALTY: produit.PRODUCT_LOYALTY,
      PRICE_POINT: produit.PRICE_POINT,
      HIDDEN_ONLINE_TEMPORARY: produit.HIDDEN_ONLINE_TEMPORARY,
      HIDDEN_UBEREAT_TEMPORARY: produit.HIDDEN_UBEREAT_TEMPORARY,
      HIDDEN_BORNE_TEMPORARY: produit.HIDDEN_BORNE_TEMPORARY,
      SOLD_OUT_TEMPORARY: produit.SOLD_OUT_TEMPORARY,
      ENABLE_IN_OPENING: produit.ENABLE_IN_OPENING,
      PRICE_OPENING: produit.PRICE_OPENING,
      prepared: produit.prepared,
    };

    ProduitService.create(data)
      .then((response) => {
        setProduit({
          categorieProduitID: data.categorieProduitID,
          CODE: data.CODE,
          NAME: data.NAME,
          DESCRIPTION: data.DESCRIPTION,
          PRICEBUY: data.PRICEBUY,
          PRICESELL: data.PRICESELL,
          TAXCAT: data.TAXCAT,
          order_item: data.order_item,
          price_type: data.price_type,
          sp: data.sp,
          emp: data.emp,
          lv: data.lv,
          bar: data.bar,
          terasse: data.terasse,
          happy_hour: data.happy_hour,
          removed: data.removed,
          menu: data.menu,
          path: data.path,
          printer: data.printer,
          many_size: data.many_size,
          different_price: data.different_price,
          color: data.color,
          TAXEMP: data.TAXEMP,
          TAXLV: data.TAXLV,
          label: data.label,
          printerLabel: data.printerLabel,
          display_kitchen: data.display_kitchen,
          option_free: data.option_free,
          special_option: data.special_option,
          number_line: data.number_line,
          number_column: data.number_column,
          max_line: data.max_line,
          additional_sale: data.additional_sale,
          moment_product: data.moment_product,
          quantity: data.quantity,
          REF_WEB: data.REF_WEB,
          unit: data.unit,
          weight: data.weight,
          extra_unit: data.extra_unit,
          APPLY_DISCOUNT: data.APPLY_DISCOUNT,
          PRINTER_KITCHEN: data.PRINTER_KITCHEN,
          HIDDEN_BORNE: data.HIDDEN_BORNE,
          SOLD_OUT: data.SOLD_OUT,
          FREE_PRICE: data.FREE_PRICE,
          ALIAS_KITCHEN: data.ALIAS_KITCHEN,
          SEND_COLOR: data.SEND_COLOR,
          HAS_DISPLAY_TIME: data.HAS_DISPLAY_TIME,
          FIRST_PERIOD: data.FIRST_PERIOD,
          END_PERIOD: data.END_PERIOD,
          REMOVED_BY_ADMIN: data.REMOVED_BY_ADMIN,
          STORABLE: data.STORABLE,
          MIN_STORE: data.MIN_STORE,
          ADDITIONAL_SALE_AT_SPOT: data.ADDITIONAL_SALE_AT_SPOT,
          ADDITIONAL_SALE_TAKE_AWAY: data.ADDITIONAL_SALE_TAKE_AWAY,
          LATER: data.LATER,
          PRINT_INGREDIENTS: data.PRINT_INGREDIENTS,
          OPTIONS_FREE_GROUPED: data.OPTIONS_FREE_GROUPED,
          AVAILABLE_CAISSE: data.AVAILABLE_CAISSE,
          LABELED: data.LABELED,
          BEST_SALE: data.BEST_SALE,
          PROMO_BASKET: data.PROMO_BASKET,
          DISPLAY_IMAGE_IN_KITCHEN: data.DISPLAY_IMAGE_IN_KITCHEN,
          DAILY_STOCK: data.DAILY_STOCK,
          HAS_BAR_CODE: data.HAS_BAR_CODE,
          HIDDEN_UBEREAT: data.HIDDEN_UBEREAT,
          DESCRIPTION_UBEREAT: data.DESCRIPTION_UBEREAT,
          TOP_PRODUCT: data.TOP_PRODUCT,
          HIDE_NAME_IN_BORNE: data.HIDE_NAME_IN_BORNE,
          HIDE_PRICE_IN_BORNE: data.HIDE_PRICE_IN_BORNE,
          HAS_TIMETABLE: data.HAS_TIMETABLE,
          UNAVAILABLE_ONLINE: data.UNAVAILABLE_ONLINE,
          HIDDEN_ONLINE: data.HIDDEN_ONLINE,
          PRODUCT_UBEREAT: data.PRODUCT_UBEREAT,
          MIXED_PRODUCT: data.MIXED_PRODUCT,
          price_sp: data.price_sp,
          price_emp: data.price_emp,
          price_lv: data.price_lv,
          price_bar: data.price_bar,
          price_terasse: data.price_terasse,
          price_happy: data.price_happy,
          PRICE_PLATFORM: data.PRICE_PLATFORM,
          PRICE_DRIVE: data.PRICE_DRIVE,
          RECTANGULAR_IMAGE: data.RECTANGULAR_IMAGE,
          PATH_2: data.PATH_2,
          PRODUCT_LOYALTY: data.PRODUCT_LOYALTY,
          PRICE_POINT: data.PRICE_POINT,
          HIDDEN_ONLINE_TEMPORARY: data.HIDDEN_ONLINE_TEMPORARY,
          HIDDEN_UBEREAT_TEMPORARY: data.HIDDEN_UBEREAT_TEMPORARY,
          HIDDEN_BORNE_TEMPORARY: data.HIDDEN_BORNE_TEMPORARY,
          SOLD_OUT_TEMPORARY: data.SOLD_OUT_TEMPORARY,
          ENABLE_IN_OPENING: data.ENABLE_IN_OPENING,
          PRICE_OPENING: data.PRICE_OPENING,
          prepared: data.prepared,
        });
        refreshList();
        setAlertMessage(MessageSuccess);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        setAlertMessage(MessageError);
      });
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              p: 0,
            }}
          >
            <Stack sx={{ width: '100%' }}>{alertMessage}</Stack>
            <Box
              sx={{
                padding: '15px 30px',
              }}
              display="flex"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Typography fontWeight="500" variant="h4">
                  Configuration des produits
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CardContent>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="categorieProduitID"
                    >
                      categorieProduitID
                    </CustomFormLabel>
                    <CustomSelect
                      id="categorieProduitID"
                      name="categorieProduitID"
                      value={produit.categorieProduitID}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      defaultValue="0"
                    >
                      <MenuItem value="1">Null</MenuItem>
                      {categorieNoms &&
                        categorieNoms.map((categorieNom) => (
                          <MenuItem key={categorieNom.ID} value={categorieNom.ID}>
                            {categorieNom.NAME}
                          </MenuItem>
                        ))}
                    </CustomSelect>
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="CODE"
                    >
                      CODE
                    </CustomFormLabel>
                    <CustomTextField
                      id="CODE"
                      name="CODE"
                      required
                      value={produit.CODE}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="NAME"
                    >
                      NAME
                    </CustomFormLabel>
                    <CustomTextField
                      id="NAME"
                      name="NAME"
                      required
                      value={produit.NAME}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="DESCRIPTION"
                    >
                      DESCRIPTION
                    </CustomFormLabel>
                    <CustomTextField
                      id="DESCRIPTION"
                      name="DESCRIPTION"
                      required
                      value={produit.DESCRIPTION}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="TAXCAT"
                    >
                      TAXCAT
                    </CustomFormLabel>
                    <CustomTextField
                      id="TAXCAT"
                      name="TAXCAT"
                      required
                      value={produit.TAXCAT}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_type"
                    >
                      PRICE TYPE
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_type"
                      name="price_type"
                      required
                      value={produit.price_type}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="path"
                    >
                      PATH
                    </CustomFormLabel>
                    <CustomTextField
                      id="path"
                      name="path"
                      required
                      value={produit.path}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PATH_2"
                    >
                      PATH 2
                    </CustomFormLabel>
                    <CustomTextField
                      id="PATH_2"
                      name="PATH_2"
                      required
                      value={produit.PATH_2}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="ALIAS_KITCHEN"
                    >
                      ALIAS KITCHEN
                    </CustomFormLabel>
                    <CustomTextField
                      id="ALIAS_KITCHEN"
                      name="ALIAS_KITCHEN"
                      required
                      value={produit.ALIAS_KITCHEN}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="TAXEMP"
                    >
                      TAXEMP
                    </CustomFormLabel>
                    <CustomTextField
                      id="TAXEMP"
                      name="TAXEMP"
                      required
                      value={produit.TAXEMP}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="TAXLV"
                    >
                      TAXLV
                    </CustomFormLabel>
                    <CustomTextField
                      id="TAXLV"
                      name="TAXLV"
                      required
                      value={produit.TAXLV}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="color"
                    >
                      COLOR
                    </CustomFormLabel>
                    <CustomTextField
                      id="color"
                      name="color"
                      required
                      value={produit.color}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="REF_WEB"
                    >
                      REF WEB
                    </CustomFormLabel>
                    <CustomTextField
                      id="REF_WEB"
                      name="REF_WEB"
                      required
                      value={produit.REF_WEB}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="unit"
                    >
                      UNIT
                    </CustomFormLabel>
                    <CustomTextField
                      id="unit"
                      name="unit"
                      required
                      value={produit.unit}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="unit"
                    >
                      UNIT
                    </CustomFormLabel>
                    <CustomTextField
                      id="unit"
                      name="unit"
                      required
                      value={produit.unit}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="extra_unit"
                    >
                      EXTRA UNIT
                    </CustomFormLabel>
                    <CustomTextField
                      id="extra_unit"
                      name="extra_unit"
                      required
                      value={produit.extra_unit}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRINTER_KITCHEN"
                    >
                      PRINTER KITCHEN
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRINTER_KITCHEN"
                      name="PRINTER_KITCHEN"
                      required
                      value={produit.PRINTER_KITCHEN}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="order_item"
                    >
                      ORDER ITEM
                    </CustomFormLabel>
                    <CustomTextField
                      id="order_item"
                      name="order_item"
                      required
                      value={produit.order_item}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="printer"
                    >
                      PRINTER
                    </CustomFormLabel>
                    <CustomTextField
                      id="printer"
                      name="printer"
                      required
                      value={produit.printer}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="printerLabel"
                    >
                      PRINTER LABEL
                    </CustomFormLabel>
                    <CustomTextField
                      id="printerLabel"
                      name="printerLabel"
                      required
                      value={produit.printerLabel}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="display_kitchen"
                    >
                      DISPLAY KITCHEN
                    </CustomFormLabel>
                    <CustomTextField
                      id="display_kitchen"
                      name="display_kitchen"
                      required
                      value={produit.display_kitchen}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="option_free"
                    >
                      OPTION FREE
                    </CustomFormLabel>
                    <CustomTextField
                      id="option_free"
                      name="option_free"
                      required
                      value={produit.option_free}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="number_line"
                    >
                      NUMBER LINE
                    </CustomFormLabel>
                    <CustomTextField
                      id="number_line"
                      name="number_line"
                      required
                      value={produit.number_line}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="number_column"
                    >
                      NUMBER COLUMN
                    </CustomFormLabel>
                    <CustomTextField
                      id="number_column"
                      name="number_column"
                      required
                      value={produit.number_column}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="max_line"
                    >
                      MAX LINE
                    </CustomFormLabel>
                    <CustomTextField
                      id="max_line"
                      name="max_line"
                      required
                      value={produit.max_line}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRICEBUY"
                    >
                      PRICE BUY
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRICEBUY"
                      name="PRICEBUY"
                      required
                      value={produit.PRICEBUY}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRICESELL"
                    >
                      PRICE SELL
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRICESELL"
                      name="PRICESELL"
                      required
                      value={produit.PRICESELL}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="quantity"
                    >
                      QUANTITY
                    </CustomFormLabel>
                    <CustomTextField
                      id="quantity"
                      name="quantity"
                      required
                      value={produit.quantity}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="weight"
                    >
                      WEIGHT
                    </CustomFormLabel>
                    <CustomTextField
                      id="weight"
                      name="weight"
                      required
                      value={produit.weight}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="FIRST_PERIOD"
                    >
                      FIRST PERIOD
                    </CustomFormLabel>
                    <CustomTextField
                      id="FIRST_PERIOD"
                      name="FIRST_PERIOD"
                      required
                      value={produit.FIRST_PERIOD}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="END_PERIOD"
                    >
                      END PERIOD
                    </CustomFormLabel>
                    <CustomTextField
                      id="END_PERIOD"
                      name="END_PERIOD"
                      required
                      value={produit.END_PERIOD}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="MIN_STORE"
                    >
                      MIN STORE
                    </CustomFormLabel>
                    <CustomTextField
                      id="MIN_STORE"
                      name="MIN_STORE"
                      required
                      value={produit.MIN_STORE}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="DAILY_STOCK"
                    >
                      DAILY STOCK
                    </CustomFormLabel>
                    <CustomTextField
                      id="DAILY_STOCK"
                      name="DAILY_STOCK"
                      required
                      value={produit.DAILY_STOCK}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="DESCRIPTION_UBEREAT"
                    >
                      DESCRIPTION UBEREAT
                    </CustomFormLabel>
                    <CustomTextField
                      id="DESCRIPTION_UBEREAT"
                      name="DESCRIPTION_UBEREAT"
                      required
                      value={produit.DESCRIPTION_UBEREAT}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_sp"
                    >
                      PRICE SP
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_sp"
                      name="price_sp"
                      required
                      value={produit.price_sp}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_emp"
                    >
                      PRICE EMP
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_emp"
                      name="price_emp"
                      required
                      value={produit.price_emp}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_lv"
                    >
                      PRICE LV
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_lv"
                      name="price_lv"
                      required
                      value={produit.price_lv}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_bar"
                    >
                      PRICE BAR
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_bar"
                      name="price_bar"
                      required
                      value={produit.price_bar}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_terasse"
                    >
                      PRICE TERASSE
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_terasse"
                      name="price_terasse"
                      required
                      value={produit.price_terasse}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="price_happy"
                    >
                      PRICE HAPPY
                    </CustomFormLabel>
                    <CustomTextField
                      id="price_happy"
                      name="price_happy"
                      required
                      value={produit.price_happy}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRICE_PLATFORM"
                    >
                      PRICE PLATFORM
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRICE_PLATFORM"
                      name="PRICE_PLATFORM"
                      required
                      value={produit.PRICE_PLATFORM}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRICE_DRIVE"
                    >
                      PRICE DRIVE
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRICE_DRIVE"
                      name="PRICE_DRIVE"
                      required
                      value={produit.PRICE_DRIVE}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="PRICE_POINT"
                    >
                      PRICE POINT
                    </CustomFormLabel>
                    <CustomTextField
                      id="PRICE_POINT"
                      name="PRICE_POINT"
                      required
                      value={produit.PRICE_POINT}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="sp"
                          name="sp"
                          value={produit.sp}
                          checked={produit.sp}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SP"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="emp"
                          name="emp"
                          value={produit.emp}
                          checked={produit.emp}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="EMP"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="lv"
                          name="lv"
                          value={produit.lv}
                          checked={produit.lv}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LV"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="bar"
                          name="bar"
                          value={produit.bar}
                          checked={produit.bar}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="BAR"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="terasse"
                          name="terasse"
                          value={produit.terasse}
                          checked={produit.terasse}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="TERASSE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="happy_hour"
                          name="happy_hour"
                          value={produit.happy_hour}
                          checked={produit.happy_hour}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HAPPY HOUR"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="removed"
                          name="removed"
                          value={produit.removed}
                          checked={produit.removed}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="REMOVED"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="menu"
                          name="menu"
                          value={produit.menu}
                          checked={produit.menu}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="MENU"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="prepared"
                          name="prepared"
                          value={produit.prepared}
                          checked={produit.prepared}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PREPARED"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="many_size"
                          name="many_size"
                          value={produit.many_size}
                          checked={produit.many_size}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="MANY SIZE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="different_price"
                          name="different_price"
                          value={produit.different_price}
                          checked={produit.different_price}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="DIFFERENT PRICE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="label"
                          name="label"
                          value={produit.label}
                          checked={produit.label}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LABEL"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="special_option"
                          name="special_option"
                          value={produit.special_option}
                          checked={produit.special_option}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SPECIAL OPTION"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="additional_sale"
                          name="additional_sale"
                          value={produit.additional_sale}
                          checked={produit.additional_sale}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ADDITIONAL SALE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="moment_product"
                          name="moment_product"
                          value={produit.moment_product}
                          checked={produit.moment_product}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="MOMENT PRODUCT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="APPLY_DISCOUNT"
                          name="APPLY_DISCOUNT"
                          value={produit.APPLY_DISCOUNT}
                          checked={produit.APPLY_DISCOUNT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="APPLY DISCOUNT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_BORNE"
                          name="HIDDEN_BORNE"
                          value={produit.HIDDEN_BORNE}
                          checked={produit.HIDDEN_BORNE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN BORNE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="SOLD_OUT"
                          name="SOLD_OUT"
                          value={produit.SOLD_OUT}
                          checked={produit.SOLD_OUT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SOLD OUT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="FREE_PRICE"
                          name="FREE_PRICE"
                          value={produit.FREE_PRICE}
                          checked={produit.FREE_PRICE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="FREE PRICE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="SEND_COLOR"
                          name="SEND_COLOR"
                          value={produit.SEND_COLOR}
                          checked={produit.SEND_COLOR}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SEND COLOR"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HAS_DISPLAY_TIME"
                          name="HAS_DISPLAY_TIME"
                          value={produit.HAS_DISPLAY_TIME}
                          checked={produit.HAS_DISPLAY_TIME}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HAS DISPLAY TIME"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="REMOVED_BY_ADMIN"
                          name="REMOVED_BY_ADMIN"
                          value={produit.REMOVED_BY_ADMIN}
                          checked={produit.REMOVED_BY_ADMIN}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="REMOVED BY ADMIN"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="ADDITIONAL_SALE_AT_SPOT"
                          name="ADDITIONAL_SALE_AT_SPOT"
                          value={produit.ADDITIONAL_SALE_AT_SPOT}
                          checked={produit.ADDITIONAL_SALE_AT_SPOT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ADDITIONAL SALE AT SPOT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="ADDITIONAL_SALE_TAKE_AWAY"
                          name="ADDITIONAL_SALE_TAKE_AWAY"
                          value={produit.ADDITIONAL_SALE_TAKE_AWAY}
                          checked={produit.ADDITIONAL_SALE_TAKE_AWAY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ADDITIONAL SALE TAKE AWAY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="PRINT_INGREDIENTS"
                          name="PRINT_INGREDIENTS"
                          value={produit.PRINT_INGREDIENTS}
                          checked={produit.PRINT_INGREDIENTS}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PRINT INGREDIENTS"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="OPTIONS_FREE_GROUPED"
                          name="OPTIONS_FREE_GROUPED"
                          value={produit.OPTIONS_FREE_GROUPED}
                          checked={produit.OPTIONS_FREE_GROUPED}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="OPTIONS FREE GROUPED"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="AVAILABLE_CAISSE"
                          name="AVAILABLE_CAISSE"
                          value={produit.AVAILABLE_CAISSE}
                          checked={produit.AVAILABLE_CAISSE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="AVAILABLE CAISSE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="PROMO_BASKET"
                          name="PROMO_BASKET"
                          value={produit.PROMO_BASKET}
                          checked={produit.PROMO_BASKET}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PROMO BASKET"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="DISPLAY_IMAGE_IN_KITCHEN"
                          name="DISPLAY_IMAGE_IN_KITCHEN"
                          value={produit.DISPLAY_IMAGE_IN_KITCHEN}
                          checked={produit.DISPLAY_IMAGE_IN_KITCHEN}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="DISPLAY IMAGE IN KITCHEN"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HAS_BAR_CODE"
                          name="HAS_BAR_CODE"
                          value={produit.HAS_BAR_CODE}
                          checked={produit.HAS_BAR_CODE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HAS BAR CODE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_UBEREAT"
                          name="HIDDEN_UBEREAT"
                          value={produit.HIDDEN_UBEREAT}
                          checked={produit.HIDDEN_UBEREAT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN UBEREAT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="TOP_PRODUCT"
                          name="TOP_PRODUCT"
                          value={produit.TOP_PRODUCT}
                          checked={produit.TOP_PRODUCT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="TOP PRODUCT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDE_PRICE_IN_BORNE"
                          name="HIDE_PRICE_IN_BORNE"
                          value={produit.HIDE_PRICE_IN_BORNE}
                          checked={produit.HIDE_PRICE_IN_BORNE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDE PRICE IN BORNE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HAS_TIMETABLE"
                          name="HAS_TIMETABLE"
                          value={produit.HAS_TIMETABLE}
                          checked={produit.HAS_TIMETABLE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HAS TIMETABLE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="UNAVAILABLE_ONLINE"
                          name="UNAVAILABLE_ONLINE"
                          value={produit.UNAVAILABLE_ONLINE}
                          checked={produit.UNAVAILABLE_ONLINE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="UNAVAILABLE ONLINE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_ONLINE"
                          name="HIDDEN_ONLINE"
                          value={produit.HIDDEN_ONLINE}
                          checked={produit.HIDDEN_ONLINE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN ONLINE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="PRODUCT_UBEREAT"
                          name="PRODUCT_UBEREAT"
                          value={produit.PRODUCT_UBEREAT}
                          checked={produit.PRODUCT_UBEREAT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PRODUCT UBEREAT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="MIXED_PRODUCT"
                          name="MIXED_PRODUCT"
                          value={produit.MIXED_PRODUCT}
                          checked={produit.MIXED_PRODUCT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="MIXED PRODUCT"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="RECTANGULAR_IMAGE"
                          name="RECTANGULAR_IMAGE"
                          value={produit.RECTANGULAR_IMAGE}
                          checked={produit.RECTANGULAR_IMAGE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="RECTANGULAR IMAGE"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="PRODUCT_LOYALTY"
                          name="PRODUCT_LOYALTY"
                          value={produit.PRODUCT_LOYALTY}
                          checked={produit.PRODUCT_LOYALTY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PRODUCT LOYALTY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_ONLINE_TEMPORARY"
                          name="HIDDEN_ONLINE_TEMPORARY"
                          value={produit.HIDDEN_ONLINE_TEMPORARY}
                          checked={produit.HIDDEN_ONLINE_TEMPORARY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN ONLINE TEMPORARY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_UBEREAT_TEMPORARY"
                          name="HIDDEN_UBEREAT_TEMPORARY"
                          value={produit.HIDDEN_UBEREAT_TEMPORARY}
                          checked={produit.HIDDEN_UBEREAT_TEMPORARY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN UBEREAT TEMPORARY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_BORNE_TEMPORARY"
                          name="HIDDEN_BORNE_TEMPORARY"
                          value={produit.HIDDEN_BORNE_TEMPORARY}
                          checked={produit.HIDDEN_BORNE_TEMPORARY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HIDDEN BORNE TEMPORARY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="SOLD_OUT_TEMPORARY"
                          name="SOLD_OUT_TEMPORARY"
                          value={produit.SOLD_OUT_TEMPORARY}
                          checked={produit.SOLD_OUT_TEMPORARY}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SOLD OUT TEMPORARY"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="ENABLE_IN_OPENING"
                          name="ENABLE_IN_OPENING"
                          value={produit.ENABLE_IN_OPENING}
                          checked={produit.ENABLE_IN_OPENING}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ENABLE IN OPENING"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="STORABLE"
                          name="STORABLE"
                          value={produit.STORABLE}
                          checked={produit.STORABLE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="STORABLE"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="LATER"
                          name="LATER"
                          value={produit.LATER}
                          checked={produit.LATER}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LATER"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="LABELED"
                          name="LABELED"
                          value={produit.LABELED}
                          checked={produit.LABELED}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LABELED"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="BEST_SALE"
                          name="BEST_SALE"
                          value={produit.BEST_SALE}
                          checked={produit.BEST_SALE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="MEILLEURE VENTE"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="PRICE_OPENING"
                          name="PRICE_OPENING"
                          value={produit.PRICE_OPENING}
                          checked={produit.PRICE_OPENING}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PRICE OPENING"
                    />
                  </Grid>
                </Grid>

                <Divider />
                <Box p={3}>
                  <Button color="primary" variant="contained" onClick={saveProduit}>
                    Sauvegarder
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

ConfigProduits.propTypes = {
  produitchoiceEditog: PropTypes.any.isRequired,
};

export default ConfigProduits;
