import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { experimentalStyled as styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
  Box, 
  Typography,
  MenuItem, 
  Select,  
  InputBase 
 } 
 from '@material-ui/core';
 import CircularProgress from '@mui/material/CircularProgress';
import AuthService from "../../../services/auth.service";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '&  .MuiInputBase-root': {
    borderRadius: '5px',
  },
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.mode === 'light' ? 'white' : theme.palette.grey.A400,
    borderRadius: 5,
    fontSize: 15,
    padding: '8px 33px 8px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '1px solid rgba(0,0,0,0.12)',
  },
}));



const LineChart = ({datedebut, datefin, idResto}) => {


  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [periode, setPeriode] = useState("jour");

  const [typeDiscountKey, setTypeDiscountKey] = useState([]);
  const [typeDiscountValue, setTypeDiscountValue] = useState([]);

  // PROGRESS CIRCLE
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (event) => {
    setPeriode(event.target.value);
  };

  const getListeRestoParams = () => {
    axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`)
      .then(response => {
      setMesRestoParams(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  useEffect(() => {
    if(UserRoles==='guest'){
    getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscount = () => {
    if(periode === 'jour'){
    if(UserRoles==='guest'){
        axios.post(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/GraphDiscountPaidAtGuest/${currentUser?.id}&${datedebut}&${datefin}`, mesRestoParams)
          .then(response => {
 
            setTypeDiscountKey(response.data.keys);

            const valuesWithoutQuotesInital = response.data.values;
            const valuesWithoutQuotes = valuesWithoutQuotesInital.map(value => parseFloat(value));
            console.log(valuesWithoutQuotes);
            setTypeDiscountValue(valuesWithoutQuotes);
            
          })
          .catch(e => {
            console.log(e);
          });
        }
        else {
          setIsLoading(true);
          axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/GraphDiscountPaidAt/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`)
            .then(response => {
   
              setTypeDiscountKey(response.data.labelPaidAtArray);
  
              const valuesWithoutQuotesInital = response.data.discountLabelArray;
              const valuesWithoutQuotes = valuesWithoutQuotesInital.map(value => Math.round((value * 100) / 100));
              console.log(valuesWithoutQuotes);
              setTypeDiscountValue(valuesWithoutQuotes);
              setIsLoading(false);
              
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
      else if(periode === 'mois'){
        if(UserRoles==='guest'){
            axios.post(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/GraphDiscountMonthGuest/${currentUser?.id}&${datedebut}&${datefin}`, mesRestoParams)
              .then(response => {
     
                setTypeDiscountKey(response.data.keys);
    
                const valuesWithoutQuotesInital = response.data.values;
                const valuesWithoutQuotes = valuesWithoutQuotesInital.map(value => parseFloat(value));
                console.log(valuesWithoutQuotes);
                setTypeDiscountValue(valuesWithoutQuotes);
                
              })
              .catch(e => {
                console.log(e);
              });
            }
            else {
              setIsLoading(true);
              axios.get(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/GraphDiscountMonth/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`)
                .then(response => {
       
                  setTypeDiscountKey(response.data.labelPaidAtArray);
      
                  const valuesWithoutQuotesInital = response.data.discountLabelArray;
                  const valuesWithoutQuotes = valuesWithoutQuotesInital.map(value => Math.round((value * 100) / 100));
                  console.log(valuesWithoutQuotes);
                  setTypeDiscountValue(valuesWithoutQuotes);
                  setIsLoading(false);
                  
                })
                .catch(e => {
                  console.log(e);
                });
            }
      }
  };

  useEffect(() => {
    retrieveTypeDiscount();
  }, [currentUser?.id,datedebut, datefin, periode, mesRestoParams, idResto]);


  const optionslinechart = {
    chart: {
      height: 350,
      type: 'line',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
      zoom: {
        type: 'x',
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      shadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
    },
    xaxis: {
      categories : typeDiscountKey,

      title: {
        text: 'Jour',
      },
    },
    grid: {
      show: true,
    },
    colors: ['#0b70fb', '#6ac3fd'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'straight',
      width: '2',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    tooltip: {
      theme: 'dark',
    },
  };
  const serieslinechart = [
    {
      name: 'Graph de Réduction Par Jour',
      data: typeDiscountValue,
    },
  ];

  return (
      <Card variant="outlined">
      {isLoading && <Box sx={{ display: 'flex' }}>
        <CircularProgress size="50px" />
      </Box>}
        <Box p={2} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4">Graph de Réduction Par Jour</Typography>
          </Box>
        </Box>
        <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={periode}
      onChange={handleChange}
      input={<BootstrapInput />}
    >
      <MenuItem value="jour">Par Jour</MenuItem>
      {/* <MenuItem value="semaine">Par Semaine</MenuItem> */}
      <MenuItem value="mois">Par Mensuel</MenuItem>
    </Select>
        {!isLoading && <CardContent>
          <Chart options={optionslinechart} series={serieslinechart} type="line" height="308px" />
        </CardContent>}
      </Card>
  );
};

export default LineChart;

LineChart.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
