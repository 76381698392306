import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  Grid,
  FormControlLabel,
} from '@material-ui/core';

import CustomCheckbox from '../../../components/forms/custom-elements/CustomCheckbox';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';

const ConfigOptions = () => {
  const initialCatState = {
    alphabetical_order: false,
    prepared: false,
    display_free: false,
    order_name: false,
    NAME: '',
    shift_option: '',
    min_items: '',
    screens: '',
    id: '',
    number_click: '',
    max_items: '',
    printer: '',
    position: '',
    is_ingredient: false,
    no_printable: false,
    has_options: false,
    multiple_category: false,
    supplement_ubereat: true,
    print_black_background: false,
    is_bold: false,
    HIDDEN_ONLINE: false,
    HIDDEN_UBEREAT: false,
    removed: false,
    REMOVED_BY_ADMIN: false,
    display_screen_sorti: false,
    hidden_borne: false,
  };

  const [option, setOption] = useState(initialCatState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOption({ ...option, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setOption({ ...option, [name]: value, [name]: checked });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              p: 0,
            }}
          >
            <Box
              sx={{
                padding: '15px 30px',
              }}
              display="flex"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Typography fontWeight="500" variant="h4">
                  Options
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CardContent
              sx={{
                padding: '30px',
              }}
            >
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="NAME"
                    >
                      Nom
                    </CustomFormLabel>
                    <CustomTextField
                      id="NAME"
                      name="NAME"
                      required
                      value={option.NAME}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="shift_option"
                    >
                      Shift option
                    </CustomFormLabel>
                    <CustomTextField
                      id="shift_option"
                      name="shift_option"
                      value={option.shift_option}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="min_items"
                    >
                      Min items
                    </CustomFormLabel>
                    <CustomTextField
                      id="min_items"
                      name="min_items"
                      required
                      value={option.min_items}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="END_PERIOD"
                    >
                      Screens
                    </CustomFormLabel>
                    <CustomTextField
                      id="screens"
                      name="screens"
                      required
                      value={option.screens}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="Tags"
                    >
                      Id
                    </CustomFormLabel>
                    <CustomTextField
                      id="id"
                      name="id"
                      required
                      value={option.id}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="Tags"
                    >
                      Number click
                    </CustomFormLabel>
                    <CustomTextField
                      id="number_click"
                      name="number_click"
                      required
                      value={option.number_click}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="max_items"
                    >
                      Max items
                    </CustomFormLabel>
                    <CustomTextField
                      id="max_items"
                      name="max_items"
                      required
                      value={option.max_items}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="Tags"
                    >
                      Printer
                    </CustomFormLabel>
                    <CustomTextField
                      id="printer"
                      name="printer"
                      required
                      value={option.printer}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="Tags"
                    >
                      Position
                    </CustomFormLabel>
                    <CustomTextField
                      id="position"
                      name="position"
                      required
                      value={option.position}
                      onChange={handleInputChange}
                      variant="outlined"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="alphabetical_order"
                          name="alphabetical_order"
                          value={option.alphabetical_order}
                          checked={option.alphabetical_order}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Ordre alphabétique"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="prepared"
                          name="prepared"
                          value={option.prepared}
                          checked={option.prepared}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Préparé"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="removed"
                          name="removed"
                          value={option.removed}
                          checked={option.removed}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Supprimé"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_BORNE"
                          name="HIDDEN_BORNE"
                          value={option.HIDDEN_BORNE}
                          checked={option.HIDDEN_BORNE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Cacher dans la Borne"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="display_free"
                          name="display_free"
                          value={option.display_free}
                          checked={option.display_free}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Display free"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="REMOVED_BY_ADMIN"
                          name="REMOVED_BY_ADMIN"
                          value={option.REMOVED_BY_ADMIN}
                          checked={option.REMOVED_BY_ADMIN}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Supprimé par l'admin"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="order_name"
                          name="order_name"
                          value={option.order_name}
                          checked={option.order_name}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Order name"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="is_ingredient"
                          name="is_ingredient"
                          value={option.is_ingredient}
                          checked={option.is_ingredient}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Is ingredient"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="no_printable"
                          name="no_printable"
                          value={option.no_printable}
                          checked={option.no_printable}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="No printable"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="has_options"
                          name="has_options"
                          value={option.has_options}
                          checked={option.has_options}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Has options"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="multiple_category"
                          name="multiple_category"
                          value={option.multiple_category}
                          checked={option.multiple_category}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Multiple category"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_UBEREAT"
                          name="HIDDEN_UBEREAT"
                          value={option.HIDDEN_UBEREAT}
                          checked={option.HIDDEN_UBEREAT}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Hidden Ubereat"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="supplement_ubereat"
                          name="supplement_ubereat"
                          value={option.supplement_ubereat}
                          checked={option.supplement_ubereat}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Supplement ubereat"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="HIDDEN_ONLINE"
                          name="HIDDEN_ONLINE"
                          value={option.HIDDEN_ONLINE}
                          checked={option.HIDDEN_ONLINE}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Cacher en ligne"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="print_black_background"
                          name="print_black_background"
                          value={option.print_black_background}
                          checked={option.print_black_background}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Print black background"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="is_bold"
                          name="is_bold"
                          value={option.is_bold}
                          checked={option.is_bold}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Is bold"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id="display_screen_sorti"
                          name="display_screen_sorti"
                          value={option.display_screen_sorti}
                          checked={option.display_screen_sorti}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Display screen sorti"
                    />
                  </Grid>
                </Grid>

                <Divider />
                <Box p={3}>
                  <Button color="primary" variant="contained">
                    Sauvegarder
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigOptions;
