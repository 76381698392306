import { Box, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import CaData from './dashboardRestau-components/CaData';
import Graph from './dashboardRestau-components/Graph';
import AdditionalInfo from './dashboardRestau-components/AdditionalInfo';
import Types from './dashboardRestau-components/Types';
import TopVentsProducts from './dashboardRestau-components/TopVentsProducts';
import ClassementRestaus from './dashboardFranchise-components/ClassementRestaus';
import Promotions from './dashboardRestau-components/Promotions';
import Annulations from './dashboardRestau-components/Annulations';
import Clients from './dashboardRestau-components/Clients';

const DashboardRestau = ({ forDay, date, currentUser }) => {
  const [totalTtc, setTotalTtc] = useState(0);
  console.log(totalTtc)
  const [tableExtended, setTableExtended]=useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  useEffect(() => {
    if(currentUser?.roles==="guest"){
      setTableExtended(true)
    }else{
      setTableExtended(false)
    }
  }, [isMobile])
  useEffect(() => {
    if(currentUser?.roles==="guest"){
      setTableExtended(true)
    }
  }, [currentUser?.roles])
  
  return (
    <Box>
      {/* caCards  */}
      {currentUser?.roles==="admin" && <CaData currentUser={currentUser} date={date} forDay={forDay} />}
      {/* !caCards  */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          transition: 'flex-drection 2s ease',
          // flexDirection: tableExtended ? "column" : { xs: 'column', md: 'row' },
          gap: '20px',
          mt: '20px',
        }}
        className="dashContainer"
      >
        <Box
          sx={{
            position: 'relative',
            transition: 'width .4s ease',
            width: tableExtended ? '100%' : {xs:"100%", md: 'calc(40% - 10px)' },
          }}
        >
          {currentUser?.roles==="admin" && <Box
            onClick={() => setTableExtended(!tableExtended)}
            sx={{
              display: {xs:'none',md:'flex'},
              width: '46px',
              height: '46px',
              padding: '6px 9px',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow:
                '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)',
              borderRadius: '50%',
              position: 'absolute',
              backgroundColor: 'white',
              cursor: 'pointer',
              right: 'calc(-46px / 2)',
              top: '10px',
            }}
          >
            <KeyboardDoubleArrowRight
              sx={{
                transition: 'transform .4s ease',
                transform: tableExtended && 'rotate(180deg)',
              }}
            />
          </Box>}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
            className="left block"
          >
            <ClassementRestaus date={date} forDay={forDay} tableExtended={tableExtended} setTableExtended={setTableExtended} />
          </Box>
        </Box>

        {/* // right   */}
        {currentUser?.roles==="admin" &&<Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            transition: 'width .4s ease',
            width: tableExtended ? '100%' : { xs:"100%", md: 'calc(60% - 10px)' },
          }}
          className="right"
        >
          <Box className="block">
            <Graph
              currentUser={currentUser}
              date={date}
              forDay={forDay}
              setTotalTtc={setTotalTtc}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              // flexDirection: { xs: 'column', md: 'row' },
              gap: '20px',
              flexWrap: 'wrap',
              "& .block":{
                width: { xs: '100%', md: 'calc(100% / 2 - 10px)' }
              },
              "& .onlyInMobile":{
                display:{xs:"block", md:"none"}
            }
            }}
            
            className="container2"
          >
            {/* top ventes */}
            <Box
              sx={{
                padding: '12px !important',
              }}
              className="block"
            >
              <TopVentsProducts currentUser={currentUser} date={date} forDay={forDay} />
            </Box>
            <Box sx={{
              width: { xs: '100%', md: 'calc(100% / 2 - 10px)' },
              "& .block":{
                width:"100% !important"
              }
            }} className="groupElements">
                {/* modePaiment */}
            <Box
              className="block"
              sx={{
                mb:"20px"
              }}
            >
              <AdditionalInfo
                currentUser={currentUser}
                date={date}
                forDay={forDay}
                title="Modes de Paiement"
              />
              {/* <DisplayedComponentInLeft /> */}
            </Box>
            
            {/* Types */}
            <Types currentUser={currentUser} date={date} forDay={forDay} />
            {/* !Types */}
            </Box>
            {/* !top ventes */}
           
           
            {/* sources */}
            <Box
              className="block"
            >
              <AdditionalInfo
                currentUser={currentUser}
                date={date}
                forDay={forDay}
                title="Sources"
              />
            </Box>
            {/* !sources */}
            {/* Equipement */}
            {/* <Box
              className="block"
            >
              <AdditionalInfo
                currentUser={currentUser}
                date={date}
                forDay={forDay}
                title="Equipements"
              />
            </Box> */}
            {/* !Equipement */}
            <Box
        sx={{
          padding: '12px !important',
        }}
        className="block onlyInMobile"
      >
        <Promotions currentUser={currentUser} forDay={forDay} date={date} totalTtc={totalTtc}/>
      </Box>
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block onlyInMobile"
      >
        <Annulations currentUser={currentUser} forDay={forDay} date={date} />
      </Box>
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block onlyInMobile"
      >
        <Clients currentUser={currentUser} forDay={forDay} date={date} />
      </Box>
            {/* !modePaiment */}
          </Box>
        </Box>}
      </Box>
    </Box>
  );
};
DashboardRestau.propTypes = {
  date: PropTypes.any.isRequired,
  forDay: PropTypes.any,
  currentUser: PropTypes.any,
};
export default DashboardRestau;
