import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../../../services/auth.service';

const TempsSortieType = ({ datedebut, datefin, idResto }) => {
  const currentUser = AuthService.getCurrentUser();
  const [labelArray, setLabelArray] = useState([]);
  const [tempsSortiArray, setTempsSortiArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // RECUPERATION BACKGROUND COLOR
  const retrieveDataGlobal = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/LineChartByTypeCommande/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
      )
      .then((response) => {
        setLabelArray(response.data.TypeCOmmandeArray);
        setTempsSortiArray(response.data.TempsSortieArray);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveDataGlobal();
  }, [currentUser?.id, datedebut, datefin, idResto]);

  const optionscolumnchart = {
    chart: {
      id: 'column-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
      toolbar: {
        show: false,
      },
    },
    colors: ['#6ac3fd', '#0b70fb', '#f64e60'],
    plotOptions: {
      bar: {
        horizontal: true,
        endingShape: 'rounded',
        columnWidth: '80%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      // categories: ['A Emporter', 'Sur Place', 'En Livraison'],
      categories: labelArray,
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} minutes`;
        },
      },
      theme: 'dark',
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
  };
  const seriescolumnchart = [
    {
      name: 'Temps de Sortie Moyen',
      // data: [32, 54, 22],
      data: tempsSortiArray,
    },
  ];

  return (
    <Card variant="outlined">
      <Box pt={2} pl={2} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">Temps de Sortie par Type de Commande</Typography>
        </Box>
      </Box>

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 2 }}>
          <CircularProgress size="50px" />
        </Box>
      )}
      {!isLoading && (
        <CardContent>
          <Chart
            options={optionscolumnchart}
            series={seriescolumnchart}
            type="bar"
            height="300px"
          />
        </CardContent>
      )}
    </Card>
  );
};

export default TempsSortieType;

TempsSortieType.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
