import React from 'react'
import {
    Button,
    Dialog,
    Grid,
    Stack,
    Typography,
} from '@material-ui/core'
import PropTypes from "prop-types"
import { Close } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { updateCart } from '../../utility/utilityFunctions'
import authService from '../../services/auth.service'

const RestoreModal = ({onClose, itemRestore, setSelectedItem, type}) => {
    const currentUser = authService.getCurrentUser();

    const {storageFile, config}=useSelector(state=>state.carte)
    const dispatch=useDispatch()
    const handleDelete= async()=>{
        
          const items = type==="categorie" 
          ? storageFile?.orderJson?.categories 
          :type==="produit" && storageFile?.orderJson?.categories;
          const modifiedItems = type==="categorie" 
          ? items.map((item) => {
            if (item.idCat === itemRestore.idCat) {
              return { ...item,removed:false,removed_by_admin:false };
            }
            return item;
          })           
          : items.map((item) => {
            if (item.idItem === itemRestore.idItem) {
              return { ...item,removed:false,removed_by_admin:false };
            }
            return item;
          })           
          const updatedCarteData = type==="categorie" ? 
            {
              orderJson:{
                ...storageFile.orderJson,
            categories: modifiedItems,
              }
            } 
            :{
              orderJson:{
                ...storageFile.orderJson,
            items: modifiedItems,
              }
            }
            try {
      await updateCart(config, dispatch, updatedCarteData, currentUser);
      setSelectedItem(null);
      onClose();
    } catch (error) {
      console.log('error during delete', error);
    }
    }
    console.log("itemRestore",itemRestore, setSelectedItem, type)
  return (
    <Dialog open onClose={onClose} fullWidth  >
        <Stack p={3}>
        <Grid spacing={2} container >
            <Grid dispaly="flex" textAlign="right" item xs={12}>
                <Close sx={{cursor:"pointer"}} onClick={onClose} color="#ff7b00"/>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Are you sure  you want delete the {type==="categorie" ? "category" : type==="produit" && "product"} {itemRestore.name} ?
                </Typography>
            </Grid>
            <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
                <Button onClick={onClose} variant='outlined'>cancel</Button>
                <Button onClick={handleDelete}  variant='contained'>delete</Button>
            </Grid>
        </Grid>
        </Stack>
    </Dialog>
  )
}
RestoreModal.propTypes={
    onClose:PropTypes.any.isRequired,
    itemRestore:PropTypes.any.isRequired,
    setSelectedItem:PropTypes.any.isRequired,
    type:PropTypes.any.isRequired,
}
export default RestoreModal