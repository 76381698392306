import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateFranchiseOptions } from '../../utility/utilityFunctions';
import authService from '../../services/auth.service';
import { setMessage } from '../../redux/alert/AlertSlice';

const DeleteModalOptions = ({ onClose, itemTodelete, setSelectedItem, type }) => {
  console.log('itemTodelete**', itemTodelete);
  const [loading, setLoading]=useState(false)
  const currentUser = authService.getCurrentUser();
  const { itemsOfOptions} = useSelector((state) => state.itemsOfOptions);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    setLoading(true)
    // categories
    const items = itemsOfOptions
    //************************** */
    // categories
    const modifiedItems =
     type === 'option'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true };
            }
            return item;
          })
        : (type === 'ingredient' || type === 'singleOption') &&
        itemsOfOptions.map((option) => {
            if (option.items) {
              const updatedItems = option.items.map((item) => {
                if (item.idGlobal === itemTodelete.idGlobal) {
                  console.log("the item that i want update", item)
                  const updatedItem={ ...item, removed: true, removed_by_admin: true }
                  console.log("after updating ", updatedItem)
                  return updatedItem;
                }
                return item;
              });
              return { ...option, items: updatedItems };
            }
            return option;
          });

    console.log('modifiedItems', modifiedItems);
    
    try {
      await updateFranchiseOptions(dispatch, modifiedItems, currentUser?.id)
      setSelectedItem(null);
      dispatch(
        setMessage({
          content: "la suppression de l'element a été éffectué avec success!",
          type: 'success',
        }),
      );
     
    } catch (error) {
      console.log('error during delete', error);
    }
    setLoading(false)
    onClose();
  };
  console.log('itemTodelete', itemTodelete, setSelectedItem, type);
  return (
    <Dialog open onClose={onClose} fullWidth>
      <Stack p={3}>
        <Grid spacing={2} container>
          <Grid dispaly="flex" textAlign="right" item xs={12}>
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} color="#ff7b00" />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Êtes-vous sûr de vouloir supprimer
              { type === 'ingredient'
                ? " l'ingrédient"
                : type === 'option'
                ? " l'option"
                : type === 'singleOption' && " l'élément d'option"}{' '}
              <strong>
                {itemTodelete.name}
              </strong>
              ?
            </Typography>
          </Grid>
          <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
            <Button onClick={onClose} variant="outlined">
              Annuler
            </Button>
            <Button
              disabled={loading}
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
              }}
              onClick={handleDelete}
              variant="contained"
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
DeleteModalOptions.propTypes = {
  onClose: PropTypes.any.isRequired,
  itemTodelete: PropTypes.any.isRequired,
  setSelectedItem: PropTypes.any.isRequired,
  type: PropTypes.any.isRequired,
};
export default DeleteModalOptions;
