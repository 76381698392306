import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  Grid,
  Alert,
  Stack,
  FormControlLabel,
} from '@material-ui/core';

import CategorieProduitService from '../../../services/CategorieProduitService';
import CustomCheckbox from '../../../components/forms/custom-elements/CustomCheckbox';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';

import AuthService from '../../../services/auth.service';

const ConfigCategories = () => {

  const initialCatState = {
    franchiseId: '',
    NAME: '',
    PARENTID: 1,
    removed: false,
    path: '',
    APPLY_DISCOUNT: false,
    HIDDEN_BORNE: false,
    MOMENT_CATEGORY: false,
    REMOVED_BY_ADMIN: false,
    PRINTER_LABEL: 0,
    SOLD_OUT: false,
    PRODUCTS_FIRST: false,
    NEXT_AVAILABLE: false,
    HAS_DISPLAY_TIME: false,
    FIRST_PERIOD: 0,
    END_PERIOD: 0,
    AVAILABLE_CAISSE: false,
    HIDDEN_UBEREAT: false,
    HIDE_NAME_IN_BORNE: false,
    HIDDEN_ONLINE: false,
    CATEGORY_UBEREAT: false,
    has_sub_categories: false,
  };

  const currentUser = AuthService.getCurrentUser();
  const [categorie, setCategorie] = useState(initialCatState);
  const [alertMessage, setAlertMessage] = useState('');
  const MessageSuccess = (
    <Alert variant="filled" severity="success">
      Ajout de la catégorie effectué avec succès !
    </Alert>
  );
  const MessageError = (
    <Alert variant="filled" severity="error">
      Erreur d ajout de la catégorie !
    </Alert>
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategorie({ ...categorie, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setCategorie({ ...categorie, [name]: value, [name]: checked });
  };

  const saveCategorieProduit = () => {
    const data = {
      franchiseId: currentUser?.id,
      NAME: categorie.NAME,
      PARENTID: categorie.PARENTID,
      removed: categorie.removed,
      path: categorie.path,
      REF_WEB: categorie.REF_WEB,
      APPLY_DISCOUNT: categorie.APPLY_DISCOUNT,
      HIDDEN_BORNE: categorie.HIDDEN_BORNE,
      MOMENT_CATEGORY: categorie.MOMENT_CATEGORY,
      REMOVED_BY_ADMIN: categorie.REMOVED_BY_ADMIN,
      SOLD_OUT: categorie.SOLD_OUT,
      PRODUCTS_FIRST: categorie.PRODUCTS_FIRST,
      NEXT_AVAILABLE: categorie.NEXT_AVAILABLE,
      HAS_DISPLAY_TIME: categorie.HAS_DISPLAY_TIME,
      FIRST_PERIOD: categorie.FIRST_PERIOD,
      END_PERIOD: categorie.END_PERIOD,
      AVAILABLE_CAISSE: categorie.AVAILABLE_CAISSE,
      HIDDEN_UBEREAT: categorie.HIDDEN_UBEREAT,
      HIDE_NAME_IN_BORNE: categorie.HIDE_NAME_IN_BORNE,
      HIDDEN_ONLINE: categorie.HIDDEN_ONLINE,
      CATEGORY_UBEREAT: categorie.CATEGORY_UBEREAT,
    };

    CategorieProduitService.create(data)
      .then((response) => {
        setCategorie({
          franchiseId: currentUser?.id,
          NAME: data.NAME,
          PARENTID: data.PARENTID,
          removed: data.removed,
          path: data.path,
          REF_WEB: data.REF_WEB,
          APPLY_DISCOUNT: data.APPLY_DISCOUNT,
          HIDDEN_BORNE: data.HIDDEN_BORNE,
          MOMENT_CATEGORY: data.MOMENT_CATEGORY,
          REMOVED_BY_ADMIN: data.REMOVED_BY_ADMIN,
          SOLD_OUT: data.SOLD_OUT,
          PRODUCTS_FIRST: data.PRODUCTS_FIRST,
          NEXT_AVAILABLE: data.NEXT_AVAILABLE,
          HAS_DISPLAY_TIME: data.HAS_DISPLAY_TIME,
          FIRST_PERIOD: data.FIRST_PERIOD,
          END_PERIOD: data.END_PERIOD,
          AVAILABLE_CAISSE: data.AVAILABLE_CAISSE,
          HIDDEN_UBEREAT: data.HIDDEN_UBEREAT,
          HIDE_NAME_IN_BORNE: data.HIDE_NAME_IN_BORNE,
          HIDDEN_ONLINE: data.HIDDEN_ONLINE,
          CATEGORY_UBEREAT: data.CATEGORY_UBEREAT,
        });
        setAlertMessage(MessageSuccess);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        setAlertMessage(MessageError);
      });
  };

  return (
    <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              sx={{
                p: 0,
              }}
            >
              <Stack sx={{ width: '100%' }}>{alertMessage}</Stack>
              <Box
                sx={{
                  padding: '15px 30px',
                }}
                display="flex"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <Typography fontWeight="500" variant="h4">
                   Configuration des catégories
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent
                sx={{
                  padding: '30px',
                }}
              >
                <form>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="NAME"
                      >
                        Nom
                      </CustomFormLabel>
                      <CustomTextField
                        id="NAME"
                        name="NAME"
                        required
                        value={categorie.NAME}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    </Grid>


                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="path"
                      >
                        Path
                      </CustomFormLabel>
                      <CustomTextField
                        id="path"
                        name="path"
                        value={categorie.path}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    </Grid>

   

                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="FIRST_PERIOD"
                      >
                        First Period
                      </CustomFormLabel>
                      <CustomTextField
                        id="FIRST_PERIOD"
                        name="FIRST_PERIOD"
                        required
                        value={categorie.FIRST_PERIOD}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="END_PERIOD"
                      >
                        End Period
                      </CustomFormLabel>
                      <CustomTextField
                        id="END_PERIOD"
                        name="END_PERIOD"
                        required
                        value={categorie.END_PERIOD}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="Tags"
                      >
                        Tags
                      </CustomFormLabel>
                      <CustomTextField
                        id="TAGS"
                        name="TAGS"
                        required
                        value={categorie.TAGS}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="Tags"
                      >
                        Parent ID
                      </CustomFormLabel>
                      <CustomTextField
                        id="PARENT_ID"
                        name="PARENT_ID"
                        required
                        value={categorie.PARENT_ID}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="CAT_ID"
                      >
                        Category ID
                      </CustomFormLabel>
                      <CustomTextField
                        id="CAT_ID"
                        name="CAT_ID"
                        required
                        value={categorie.CAT_ID}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomFormLabel
                        sx={{
                          mt: 0,
                        }}
                        htmlFor="Tags"
                      >
                        Position
                      </CustomFormLabel>
                      <CustomTextField
                        id="POSITION"
                        name="POSITION"
                        required
                        value={categorie.POSITION}
                        onChange={handleInputChange}
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="APPLY_DISCOUNT"
                            name="APPLY_DISCOUNT"
                            value={categorie.APPLY_DISCOUNT}
                            checked={categorie.APPLY_DISCOUNT}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Appliquer la réduction"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="removed"
                            name="removed"
                            value={categorie.removed}
                            checked={categorie.removed}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Supprimé"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="HIDDEN_BORNE"
                            name="HIDDEN_BORNE"
                            value={categorie.HIDDEN_BORNE}
                            checked={categorie.HIDDEN_BORNE}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Cacher dans la Borne"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="MOMENT_CATEGORY"
                            name="MOMENT_CATEGORY"
                            value={categorie.MOMENT_CATEGORY}
                            checked={categorie.MOMENT_CATEGORY}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Catégorie de moment"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="REMOVED_BY_ADMIN"
                            name="REMOVED_BY_ADMIN"
                            value={categorie.REMOVED_BY_ADMIN}
                            checked={categorie.REMOVED_BY_ADMIN}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Supprimé par l'admin"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="SOLD_OUT"
                            name="SOLD_OUT"
                            value={categorie.SOLD_OUT}
                            checked={categorie.SOLD_OUT}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Rupture en stock"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="PRODUCTS_FIRST"
                            name="PRODUCTS_FIRST"
                            value={categorie.PRODUCTS_FIRST}
                            checked={categorie.PRODUCTS_FIRST}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Afficher les produits en premier"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="NEXT_AVAILABLE"
                            name="NEXT_AVAILABLE"
                            value={categorie.NEXT_AVAILABLE}
                            checked={categorie.NEXT_AVAILABLE}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Next Available"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="HAS_DISPLAY_TIME"
                            name="HAS_DISPLAY_TIME"
                            value={categorie.HAS_DISPLAY_TIME}
                            checked={categorie.HAS_DISPLAY_TIME}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Has Display Time"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="AVAILABLE_CAISSE"
                            name="AVAILABLE_CAISSE"
                            value={categorie.AVAILABLE_CAISSE}
                            checked={categorie.AVAILABLE_CAISSE}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Available Caisse"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="HIDDEN_UBEREAT"
                            name="HIDDEN_UBEREAT"
                            value={categorie.HIDDEN_UBEREAT}
                            checked={categorie.HIDDEN_UBEREAT}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Hidden Ubereat"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="HIDE_NAME_IN_BORNE"
                            name="HIDE_NAME_IN_BORNE"
                            value={categorie.HIDE_NAME_IN_BORNE}
                            checked={categorie.HIDE_NAME_IN_BORNE}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Cacher le nom sur la borne"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="HIDDEN_ONLINE"
                            name="HIDDEN_ONLINE"
                            value={categorie.HIDDEN_ONLINE}
                            checked={categorie.HIDDEN_ONLINE}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Cacher en ligne"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="has_sub_categories"
                            name="has_sub_categories"
                            value={categorie.has_sub_categories}
                            checked={categorie.has_sub_categories}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Has Subcategories"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            id="CATEGORY_UBEREAT"
                            name="CATEGORY_UBEREAT"
                            value={categorie.CATEGORY_UBEREAT}
                            checked={categorie.CATEGORY_UBEREAT}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Category Ubereat"
                      />
                    </Grid>
                  </Grid>

                  <Divider />
                  <Box p={3}>
                    <Button color="primary" variant="contained" onClick={saveCategorieProduit}>
                      Sauvegarder
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </>
  );
};

export default ConfigCategories;
