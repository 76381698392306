/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Button,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import { Box, Checkbox, FormControlLabel, FormGroup, Radio } from '@material-ui/core';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import stripeLogo from '../../assets/images/marketplace/stripe.png';
import deliverooLogo from '../../assets/images/marketplace/deliveroo2.png';





const Dishop = () => {
const [planToActivate, setPlanToActivate] = useState({})


const handleInputChange = (event) => {
  const {name, value} = event.target;
}

  return (
    <>
          <DialogContent>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SettingsIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="h4">Dishop</Typography>
              </Box>
              <Divider sx={{ my: 1, mb: 2 }} />
              <InputLabel sx={{ fontSize: '14px' }} htmlFor="location_id">
                Location ID
              </InputLabel>
              <TextField
                required
                id="location_id"
                name="location_id"
                variant="outlined"
                fullWidth
                size="small"
                onChange={handleInputChange}
                value={planToActivate.location_id}
              />
              <FormControl variant="standard" sx={{ width: 1 }}>
                <InputLabel sx={{ fontSize: '14px' }} id="label">
                  Imprimante caisse
                </InputLabel>
                <Select
                  name="imprimante"
                  onChange={handleInputChange}
                  labelId="imprimante"
                  id="imprimante"
                >
                  <MenuItem value="impr1">imprimante1</MenuItem>
                  <MenuItem value="impr2">imprimante2</MenuItem>
                </Select>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  name="auto_impression"
                  onChange={handleInputChange}
                  control={<Checkbox defaultChecked />}
                  label="Auto impression"
                />
              </FormGroup>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                My Plan
              </Typography>
              <Typography sx={{ color: '#979797' }} variant="subtitle2">
                Change how you pay plan
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #E6E6E6',
                  p: 1,
                  mt: 2,
                  borderRadius: '10px',
                }}
              >
                <Avatar src={stripeLogo} />
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    $16.99 USD
                  </Typography>
                  <Typography sx={{ color: '#979797' }} variant="subtitle2">
                    Next renew 20 December 2023
                  </Typography>
                </Box>
                <Box />
                <Box />
                <Box />
                <Chip
                  sx={{
                    borderRadius: '6px',
                    background: (theme) => theme.palette.secondary.light,
                    color: (theme) => theme.palette.secondary.main,
                  }}
                  size="small"
                  color="primary"
                  label="Billed monthly"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #E6E6E6',
                  p: 1,
                  mt: 2,
                  borderRadius: '10px',
                }}
              >
                <Avatar src={deliverooLogo} />
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    yasser@biborne.com
                  </Typography>
                  <Typography sx={{ color: '#979797' }} variant="subtitle2">
                    Expiry 12/2023
                  </Typography>
                </Box>
                <Box />
                <Box />
                <Box />
                <Box>
                  <Button sx={{ mr: 1 }} variant="outlined" color="secondary" size="small">
                    Change
                  </Button>
                  <Button variant="contained" color="error" size="small">
                    Cancel
                  </Button>
                </Box>
              </Box>
              <Typography sx={{ color: '#979797' }} variant="caption">
                Notes: Please be carefull on choosing your payment method, because we will
                automatically cut your balance.
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 1 }} variant="h6">
                Choisir POS
              </Typography>
              <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="pos1"
                    control={<Radio />}
                    label="POS 1"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="pos2"
                    control={<Radio />}
                    label="POS 2"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="pos3"
                    control={<Radio />}
                    label="POS 3"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
              <Button sx={{ width: 1, my: 1 }} variant="contained" color="success">
                Activate
              </Button>
            </Grid>
          </DialogContent>
    </>
  );
};

Dishop.propTypes = {
};

export default Dishop;
