import React, { useEffect } from 'react';
import { useLocation, 
  useNavigate, 
  useRoutes } from 'react-router-dom';
// import Cookies from 'js-cookie';
import {  CssBaseline, ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import getRouter  from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ModalProvider } from './context/ModalContext';
import ModalManager from './components/modals/ModalManager';
import { SocketProvider } from './context/SocketContext'; // Importer SocketProvider
import { 
  getFranchiseInfo, 
  getCarteFileStorage2, 
  getItemsOfOptions, 
    getListeLangues,
    getsizes,
    getPrinters
} from './redux/apiCalls';
import authService from './services/auth.service';
import './App.css';
import CustomSnackbar from './components/custum-elements/CustomSnackbar';
import useCartes from './views/campagne/campagnes-hooks/useCartes';
// import { privateRequest } from './requestMethods';
// import Menuitems from './layouts/full-layout/sidebar/Menuitems';
// import { setMessage } from './redux/alert/AlertSlice';

const App = () => {
  localStorage?.setItem("guideStatus", "removed")
  localStorage?.removeItem("removeNotification")
  const currentUser = authService.getCurrentUser();
  const {currentUserInfo}=useSelector(state=>state.currentUserInfo)
// Utilisez useLocation pour obtenir l'objet de localisation
const location = useLocation();

// Accédez aux paramètres de la chaîne de recherche de l'URL
const searchParams = new URLSearchParams(location.search);

// Récupérez la valeur d'une variable (par exemple, "token")
const token = searchParams.get('token');
// const isBlocked = currentUserInfo?.isBlocked;
const routing = useRoutes(
  getRouter(currentUser, token, localStorage?.getItem('guideStatus'), currentUserInfo?.isBlocked),
);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { config } = useSelector((state) => state.carte);
  
  const dispatch = useDispatch();
  const {pathname}=useLocation()
  const {sizes3, loading}=useCartes()
  useEffect(() => {
    if(currentUser?.id && !loading && sizes3){
     getsizes(currentUser?.id, dispatch, sizes3) 
    }
    
  }, [currentUser?.id, sizes3?.length, loading])
  useEffect(() => {
    if(currentUser?.id && currentUser?.roles!=="moderator" && [
      '/campagne/MesCartes',
      '/campagne/MesProduits',
      '/campagne/DeploiementCarte',
      '/config/mesImprimantes',
    ].includes(pathname)){
     getPrinters(currentUser?.id, dispatch) 
    }
    
  }, [currentUser?.id, pathname])

  const FranchiseLangues=currentUserInfo?.countryFranchiseInfo?.languages && currentUserInfo?.countryFranchiseInfo?.languages.length!==0 
  ? currentUserInfo?.countryFranchiseInfo?.languages : [{
    code:"fr",
    label:"Français",
    id:1,
    country_languages:{
      is_default:true
    }
  }]
  useEffect(() => {
    if (currentUser) {
      getFranchiseInfo(dispatch, currentUser)
    }
  }, [currentUser?.id]);
  useEffect(() => {
    if (
      currentUser?.id &&
      currentUserInfo?.id &&
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesLangues',
      ].includes(pathname)
    ) {
      getItemsOfOptions(currentUser?.id, dispatch);
      if(currentUserInfo && currentUser?.roles!=="moderator"){
              getListeLangues(currentUser?.id, dispatch, FranchiseLangues, currentUserInfo?.countryFranchiseInfo?.name || "non trouvé");
      }
    }
  }, [currentUser?.id, pathname, currentUserInfo?.id, currentUserInfo?.countryFranchiseInfo?.name ]);
  

  useEffect(() => {
    if (currentUser && config) {
      getCarteFileStorage2(config, currentUser?.id, dispatch)
    }
  }, [config?.campagne, currentUser?.id]);
  const navigate=useNavigate()
  useEffect(async () => {
    if (
      currentUser?.id &&
      !currentUser?.superAdminLogin &&
      currentUserInfo?.isBlocked &&
      !currentUser?.superAdminLogin
    ) {
      navigate('/accessDenied');
    }
    if (currentUser?.roles === 'admin' && currentUser?.id === '9') {
      if (
        currentUser?.login !== 'direction.chamasgroup@gmail.com' &&
        currentUserInfo?.password !== '$2a$08$WdWStjaR9sfOmCmZuw7uIuVm56DaDhVkUnl2LM/KHiVxqb7zN2bT.'
      ) {
        authService.logout();
      }
    }
    // if (currentUser?.roles === 'guest') {
    //   let firstPageForGuest = '';
    //   try {
    //     const res4 = await privateRequest.get(
    //       `/api/v1/dashboard/findAllPagesGuest/${currentUser?.id_guest}`,
    //     );
    //     const updatedMenu = res4?.data?.map((el) =>
    //       Menuitems?.find((mp) => mp?.title === el?.title),
    //     );
    //     // Find the first element in updatedMenu that is not undefined and has a valid href
    //     const firstValidPage = updatedMenu.find(
    //       (menuItem) =>
    //         (menuItem?.children?.length && menuItem.children[0]?.href) || menuItem?.href,
    //     );

    //     // Extract the href from the found element
    //     firstPageForGuest = firstValidPage?.children?.length
    //       ? firstValidPage.children[0]?.href
    //       : firstValidPage?.href;

    //     // Navigate to the first valid page
    //     if (firstPageForGuest) {
    //       navigate(firstPageForGuest);
    //     }
    //     else {
    //       console.error('No valid page found in updatedMenu.');
    //       dispatch(
    //         setMessage({
    //           content:
    //             "Il semble que cet utilisateur n'ait aucune page à accéder. Veuillez essayer de vous connecter avec un autre utilisateur.",
    //           type: 'error',
    //           autoHideDuration: 'noune',
    //           width: '70%',
    //         }),
    //       );
    //       authService.logout();
    //     }
    //   } catch (error) {
    //     console.log('error while recupering pageGuest', error);
    //     dispatch(
    //       setMessage({
    //         content:
    //           "Il semble que cet utilisateur n'ait aucune page à accéder. Veuillez essayer de vous connecter avec un autre utilisateur.",
    //         type: 'error',
    //         autoHideDuration: 'noune',
    //         width: '70%',
    //       }),
    //     );
    //     authService.logout();
    //   }
    // }
  }, [currentUserInfo?.isBlocked, pathname, currentUser?.id, currentUser?.superAdminLogin]);
 

  return (
    <ModalProvider>
      <ModalManager />
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <CustomSnackbar/>
          <SocketProvider> {/* Wrapping the app with SocketProvider */}
          {routing}
          </SocketProvider>
        </RTL>
      </ThemeProvider>
    </ModalProvider>
  );
};

export default App;
