import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import AuthService from '../../../services/auth.service';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const restaurants = [
//   {
//     name: 'CHAMAS BURGER CHALON',
//     equipement: 'Equipement 1',
//     totalp_platfrome: 433,
//     total_ttc: 3442,
//     ca_ht: 0.14,
//     tva: 3.33,
//     tva5_5: 13.52,
//     tva2_1: 13.52,
//     tva2_5: 13.52,
//     tva7_7: 13.52,
//     tva8_5: 13.52,
//     tva20: 13.52,
//     tva10: 13.52,
//     total_platfrome: 13.52,
//     total_espece: 13.52,
//     total_tr: 13.52,
//     total_cb: 13.52,
//     cash_glory: 13.52,
//     cheque_vacances: 13.52,
//     total_avoir_decaisse: 13.52,
//     total_avoir_ecaisse: 13.52,
//     promotion: 34,
//     nbrcmd_ttcp_platform: 23,
//     nbrcmd_platform: 23
//   },
//   {
//     name: 'CHAMAS BURGER VALENCE',
//     equipement: 'Equipement 2',
//     totalp_platfrome: 235,
//     total_ttc: 3442,
//     ca_ht: 0.14,
//     tva: 3.33,
//     tva5_5: 54.52,
//     tva2_1: 13.52,
//     tva2_5: 32.52,
//     tva7_7: 13.45,
//     tva8_5: 13.63,
//     tva20: 13.52,
//     tva10: 65.52,
//     total_platfrome: 311.52,
//     total_espece: 546.52,
//     total_tr: 13.52,
//     total_cb: 435.52,
//     cash_glory: 2345.52,
//     cheque_vacances: 54.324,
//     total_avoir_decaisse: 1343.52,
//     total_avoir_ecaisse: 134.52,
//     promotion: 334,
//     nbrcmd_ttcp_platform: 213,
//     nbrcmd_platform: 234
//   },
//   {
//     name: 'CHAMAS TACOS LYON',
//     equipement: 'Equipement 3',
//     totalp_platfrome: 433,
//     total_ttc: 3442,
//     ca_ht: 0.14,
//     tva: 3.33,
//     tva5_5: 13.52,
//     tva2_1: 13.52,
//     tva2_5: 13.52,
//     tva7_7: 13.52,
//     tva8_5: 13.52,
//     tva20: 13.52,
//     tva10: 13.52,
//     total_platfrome: 13.52,
//     total_espece: 13.52,
//     total_tr: 13.52,
//     total_cb: 13.52,
//     cash_glory: 13.52,
//     cheque_vacances: 13.52,
//     total_avoir_decaisse: 13.52,
//     total_avoir_ecaisse: 13.52,
//     promotion: 34,
//     nbrcmd_ttcp_platform: 23,
//     nbrcmd_platform: 23
//   },
//   {
//     name: 'CHAMAS BURGER VALENCE',
//     equipement: 'Equipement 2',
//     totalp_platfrome: 325,
//     total_ttc: 3442,
//     ca_ht: 0.14,
//     tva: 3.33,
//     tva5_5: 54.52,
//     tva2_1: 13.52,
//     tva2_5: 32.52,
//     tva7_7: 13.45,
//     tva8_5: 13.63,
//     tva20: 13.52,
//     tva10: 65.52,
//     total_platfrome: 311.52,
//     total_espece: 546.52,
//     total_tr: 13.52,
//     total_cb: 435.52,
//     cash_glory: 2345.52,
//     cheque_vacances: 54.324,
//     total_avoir_decaisse: 1343.52,
//     total_avoir_ecaisse: 134.52,
//     promotion: 334,
//     nbrcmd_ttcp_platform: 213,
//     nbrcmd_platform: 234
//   },
// ];

const FranchiseEquipement = ({ datedebut, datefin, idResto }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [restaurants, setRestaurants] = useState([]);

  // PROGRESS CIRCLE
  const [isLoading, setIsLoading] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - restaurants.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getListeRestoParams = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`,
      )
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscount = () => {
    if (UserRoles === 'guest') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/FindAllZEquipementListGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setRestaurants(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/FindAllZEquipementList/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          setRestaurants(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeDiscount();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto]);

   const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
const currencyCode=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code
const currencySymbol=currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol

  return (
    <>
      <Breadcrumb title="Z Équipement Franchise" />
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h4">Z Équipements Franchise</Typography>
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size="50px" />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            {!isLoading && (
              <Table
                aria-label="custom pagination table"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Restaurant</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Nom Equipement</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total + Platforme
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total TTC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        CA HT
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 2.2%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 2.5%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 5.5%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 7.7%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 8.5%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 10%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        TVA 20%
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total Platforme
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total Espèce
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total TR
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total CB
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Cash Glory
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Cheque Vacances
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total Avoir Devaisse
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Total Avoir Ecaisse
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Promotion
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Nombre Commande TTC + Platforme
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="right">
                        Nombre Commande Platforme
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? restaurants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : restaurants
                  ).map((restau) => (
                    <TableRow key={restau.id_row}>
                      <TableCell>
                        <Chip
                          sx={{
                            backgroundColor: (theme) => theme.palette.warning.light,
                            color: (theme) => theme.palette.warning.dark,
                            borderRadius: '6px',
                            pl: '3px',
                            pr: '3px',
                          }}
                          size="small"
                          label={restau.pseudo}
                        />
                      </TableCell>

                      <TableCell>
                        <Chip
                          sx={{
                            backgroundColor: (theme) => theme.palette.info.light,
                            color: (theme) => theme.palette.info.dark,
                            borderRadius: '6px',
                            pl: '3px',
                            pr: '3px',
                          }}
                          size="small"
                          label={restau.name_equipement}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.totalp_platfrome)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.totalTtc)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.ca_ht)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_2_2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_2_5)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_5_5)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_7_7)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_8_5)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_10)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.tva_20)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_platform)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_espece)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_tr)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_cb)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.cash_glory)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.cheque_vacances)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_avoir_decaisse)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.total_avoir_ancaisser)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {ConvertirMonetaire(
                    currencyCode,
                    currencySymbol,restau.Promotion)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {restau.nbcommandes_ttc_platform}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                            textAlign: 'right',
                          }}
                        >
                          {restau.nbcommande_platforme}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={restaurants.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputprops: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default FranchiseEquipement;

FranchiseEquipement.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
