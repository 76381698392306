import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { Paper, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Button, Grid } from '@mui/material';
import { privateRequest } from '../../../requestMethods';

const DetailsCommande = ({
//   orderDetails,
  currencySymbol,
  siretChoose,
  keenioChoose,
  orderIdChoose,
  
}) => {
  console.log("DetailsCommande", siretChoose,
    keenioChoose,
    orderIdChoose)
  const [detailsCommande, setDetailsCommande] = useState(null);
  // const [paysFranchhise, setPaysFranchhise] = useState(null);
  // const paysFranchhise = "canada";

  const retrieveOrderDetails = () => {
    privateRequest
      .get(
        `/api/v1/orders/findOneOrderDetails/${orderIdChoose}&${siretChoose}&${keenioChoose}`,
      )
      .then((response) => {
        setDetailsCommande(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveOrderDetails();
  }, [siretChoose, keenioChoose, orderIdChoose]);

  const ConvertirMille = (value) => {
    const ValueParseFloat = parseFloat(value);
    const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
    return FinalValue;
  };

  // CMD1704025896631
// Calculer la somme des sumhtamount
const sumOfSumtaxamount = detailsCommande?.orderItemsTaxData.reduce((sum, item) => sum + item.sumtaxamount, 0);
const sumOfSumhtamount = detailsCommande?.orderItemsTaxData.reduce((sum, item) => sum + item.sumhtamount, 0);
const sumOfSumttcamount = detailsCommande?.orderItemsTaxData.reduce((sum, item) => sum + item.sumttcamount, 0);


const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  pageStyle: `
    @media print {
      body {
        margin: 0;
        padding: 0;
      }
      /* Ajoutez ici votre style d'impression personnalisé */
    }
  `,
});
console.log("detailsCommande", detailsCommande)
  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center', lineHeight: '0.4' }} ref={componentRef}>
      {siretChoose && keenioChoose && orderIdChoose ?(
        <>
          <Typography variant="h4" sx={{ fontWeight: 'bold', lineHeight: 2 }}>
            Détails de la commande
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            <strong>Source:</strong> {detailsCommande?.orderData?.sent_from}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            <strong>Commande N°:</strong> {detailsCommande?.orderData?.order_id}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            <strong>Vendeur:</strong> {detailsCommande?.orderData?.user_name}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            {/* <strong>Date et Heure:</strong> {moment(detailsCommande?.orderData?.paid_at).format('llll')} -{' '} */}
            {moment(detailsCommande?.orderData?.paid_at?.split('Z')[0]).format('ddd DD MMM yyyy HH:mm:ss')} -{' '}
            <strong>{detailsCommande?.orderData?.type}</strong>
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    QTE
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    DESIGNATION
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    PRIX
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    TOTAL
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    TVA
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsCommande?.orderItemsWithOptions?.map((item) => (
                  <React.Fragment key={item.pk_id}>
                    <TableRow>
                      <TableCell sx={{ lineHeight: '0.1' }} align="left">
                        {item.quantity}
                      </TableCell>
                      <TableCell sx={{ lineHeight: item.name.length > 25 ? '1.3' : '0.1' }} align="left">
                        {item.name}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.1' }} align="right">
                        {ConvertirMille(item.price)} {currencySymbol}
                      </TableCell>
                      <TableCell align="right">
                        {ConvertirMille(item.ttc_amount)} {currencySymbol}
                      </TableCell>
                      <TableCell align="right">{item.tax_rate * 100}%</TableCell>
                    </TableRow>
                    {/* Options */}
                    {item.options.map((option) => (
                      <TableRow key={option?.pk_id} sx={{ backgroundColor: '#fcfcf7' }}>
                        {/* <TableCell sx={{ lineHeight: '0.1' }} colSpan={5} align="left">
                        <Typography sx={{ lineHeight: '0.1' }} variant="caption">{option.quantity}</Typography>
                      </TableCell> */}
                        {/* <TableCell sx={{ lineHeight: '0.1' }} align="left">
                        <Typography sx={{ lineHeight: '0.1' }} variant="caption">{option.quantity}</Typography>
                      </TableCell> */}
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography sx={{ lineHeight: '0.1' }} variant="caption">
                            {option?.quantity}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            {option?.name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} colSpan={3} align="left">
                          <Typography
                            sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                            variant="caption"
                          >
                            {option.amount_total > 0 ? option?.amount_total + currencySymbol : '*'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" align="left" sx={{ paddingTop: 2 }} gutterBottom>
            Nb. Article : {detailsCommande?.orderItemsWithOptions.length}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    TVA
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    MT. TVA
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    Base HT
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    Base TTC
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsCommande?.orderItemsTaxData?.map((item) => (
                  <TableRow key={item.tax_rate}>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {item.tax_rate * 100}%
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {ConvertirMille(item.sumtaxamount)} {currencySymbol}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(item.sumhtamount)} {currencySymbol}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(item.sumttcamount)} {currencySymbol}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: '#e9ecef' }}>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    Total
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="left">
                    {ConvertirMille(sumOfSumtaxamount)} {currencySymbol}
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    {ConvertirMille(sumOfSumhtamount)} {currencySymbol}
                  </TableCell>
                  <TableCell sx={{ lineHeight: '0.3' }} align="right">
                    {ConvertirMille(sumOfSumttcamount)} {currencySymbol}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <hr style={{ borderTop: '0px dashed #e9ecef', margin: '20px 0' }} />

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Total T.T.C.
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {ConvertirMille(detailsCommande?.orderData?.total)} {currencySymbol}
            </Typography>
          </div>

          <hr style={{ borderBottom: '0px dashed #e9ecef', margin: '20px 0' }} />

            {Number(detailsCommande?.orderData?.discount) > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Réduction
              </Typography>
              <Typography variant="h6">
                {ConvertirMille(detailsCommande?.orderData?.discount)} {detailsCommande?.orderData?.type_discount==="pourcentage" ? "%" :currencySymbol}
              </Typography>
            </div>
          )}

          <Typography variant="h6" style={{ marginTop: '16px' }}>
            Mode de paiement ({detailsCommande?.paymentsItemsData.length})
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>
                {detailsCommande?.paymentsItemsData?.map((item) => (
                  <TableRow>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {item.name}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMille(item?.amount)} {currencySymbol}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* 
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Signature : BO413y7jg
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        N* de serie L 3544-560-502-615
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Version logiciel : 9.0.0.11
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Type d'operation : Vente
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Impression N* 1 - 2 ligne(s)
      </Typography> */}

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '16px' }}
            onClick={handlePrint}
          >
            Imprimer
          </Button>
        </>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '5px', textAlign: 'center', lineHeight: '0.8' }}>
            <p>Cliquez sur une commande pour voir les détails</p>
          </Paper>
        </Grid>
      )}
    </Paper>
  );
};

DetailsCommande.propTypes = {
    // orderDetails: PropTypes.any.isRequired,
    currencySymbol: PropTypes.any.isRequired,
    siretChoose: PropTypes.any.isRequired,
    keenioChoose: PropTypes.any.isRequired,
    orderIdChoose: PropTypes.any.isRequired,
};

export default DetailsCommande;
