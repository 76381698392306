/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Button,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import { Box, Checkbox, FormControlLabel, FormGroup, Radio } from '@material-ui/core';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import stripeLogo from '../../assets/images/marketplace/stripe.png';
import deliverooLogo from '../../assets/images/marketplace/deliveroo2.png';

const ClickNCollect = () => {
  const [planToActivate, setPlanToActivate] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  };

  return (
    <>
      <DialogContent>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ fontSize: '20px', mr: 1 }} />
            <Typography variant="h4">Click & Collect</Typography>
          </Box>
          <Divider sx={{ my: 1, mb: 2 }} />
          <FormGroup>
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Suivi commande en ligne"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Faire sonner la caisse lors d'une nouvelle commande en ligne"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Accepter les commandes payée en ligne automatiquement"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Accepter automatiquement les commandes non payées en ligne"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Click & Collect v3"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Cacher les noms des catégories"
            />
            <FormControlLabel
              name="auto_impression"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Suivi commande en ligne"
            />
            <FormControlLabel
              name="hide_subcategories"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Cacher les noms des sous catégories"
            />
            <FormControlLabel
              name="hide_products"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Cacher les noms des produits"
            />
            <FormControlLabel
              name="hide_options"
              onChange={handleInputChange}
              control={<Checkbox />}
              label="Cacher les noms des options"
            />
          </FormGroup>

          <Typography sx={{ fontWeight: 'bold', my: 1 }} variant="h6">
            Choisir POS
          </Typography>
          <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="pos1"
                control={<Radio />}
                label="POS 1"
                labelPlacement="start"
              />
              <FormControlLabel
                value="pos2"
                control={<Radio />}
                label="POS 2"
                labelPlacement="start"
              />
              <FormControlLabel
                value="pos3"
                control={<Radio />}
                label="POS 3"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
          <Button sx={{ width: 1, my: 1 }} variant="contained" color="success">
            Activate
          </Button>
        </Grid>
      </DialogContent>
    </>
  );
};

ClickNCollect.propTypes = {};

export default ClickNCollect;
