import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    currentUserInfo :null
}
export const currentUserInfoSlice = createSlice({
  name: 'currentUserInfo',
  initialState,
  reducers: {
    setCurrentUserInfo: (state, action) => {
      state.currentUserInfo = action.payload;
    },
  },
});

export const {
    setCurrentUserInfo 
} = currentUserInfoSlice.actions
export default currentUserInfoSlice.reducer