import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Card, CardContent, Box, Typography, Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import AuthService from '../../../services/auth.service';

const BCrumb = [];

const FirstCharts = ({ datedebut, datefin, idResto }) => {
  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);

  const [typeDiscountKey, setTypeDiscountKey] = useState([]);
  const [typeDiscountValue, setTypeDiscountValue] = useState([]);

  const [typeOrderDiscountKey, setTypeOrderDiscountKey] = useState([]);
  const [typeOrderDiscountValue, seTypeOrderDiscountValue] = useState([]);

  const [labelDiscountKey, setLabelDiscountKey] = useState([]);
  const [labelDiscountValue, setLabelDiscountValue] = useState([]);

  // PROGRESS CIRCLE

  const [isLoadingTypeDis, setIsLoadingTypeDis] = useState(false);
  const [isLoadingTypeOrd, setIsLoadingTypeOrd] = useState(false);
  const [isLoadingTypeLabel, setIsLoadingTypeLabel] = useState(false);

  const getListeRestoParams = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`,
      )
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscount = () => {
    if (UserRoles === 'guest') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionTypeDiscountGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setTypeDiscountKey(response.data.keys);

          const valuesWithoutQuotesInital = response.data.values;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          setTypeDiscountValue(valuesWithoutQuotes);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsLoadingTypeDis(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionTypeDiscount/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          setTypeDiscountKey(response.data.typeDiscountArray);

          const valuesWithoutQuotesInital = response.data.discountArray;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          setTypeDiscountValue(valuesWithoutQuotes);
          setIsLoadingTypeDis(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeDiscount();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto]);

  const retrieveTypeOrderDiscount = () => {
    if (UserRoles === 'guest') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionSourceCommandeGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setTypeOrderDiscountKey(response.data.keys);

          const valuesWithoutQuotesInital = response.data.values;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          seTypeOrderDiscountValue(valuesWithoutQuotes);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsLoadingTypeOrd(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionSourceCommande/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          setTypeOrderDiscountKey(response.data.labelDiscountArray);

          const valuesWithoutQuotesInital = response.data.discountLabelArray;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          seTypeOrderDiscountValue(valuesWithoutQuotes);
          setIsLoadingTypeOrd(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeOrderDiscount();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto]);

  const retrieveLabelDiscount = () => {
    if (UserRoles === 'guest') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionLabelCommandeGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setLabelDiscountKey(response.data.keys);

          const valuesWithoutQuotesInital = response.data.values;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          setLabelDiscountValue(valuesWithoutQuotes);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsLoadingTypeLabel(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/RepartitionLabelCommande/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          setLabelDiscountKey(response.data.labelDiscountArray);

          const valuesWithoutQuotesInital = response.data.discountLabelArray;
          const valuesWithoutQuotes = valuesWithoutQuotesInital.map((value) => parseFloat(value));
          console.log(valuesWithoutQuotes);
          setLabelDiscountValue(valuesWithoutQuotes);
          setIsLoadingTypeLabel(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveLabelDiscount();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto]);

  // REPARTITION TYPE DISCOUNT
  const optionsTypeDiscount = {
    chart: {
      id: 'donut-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
    },
    dataLabels: {
      enabled: false,
    },
    labels: typeDiscountKey,
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
    colors: ['#509EE2', '#96D9D9', '#B8BBC3', '#ffa800'],
    tooltip: {
      theme: 'dark',
      fillSeriesColor: false,
    },
  };
  const seriesTypeDiscount = typeDiscountValue;

  // REPARTITION SOURCE
  const optionsLabelDiscount = {
    chart: {
      id: 'donut-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
    },
    dataLabels: {
      enabled: false,
    },
    labels: typeOrderDiscountKey,
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
    colors: ['#509EE2', '#96D9D9', '#B8BBC3', '#6ac3fd', '#f64e60', '#26c6da', '#ffa800'],
    tooltip: {
      theme: 'dark',
      fillSeriesColor: false,
    },
  };
  const seriesLabelDiscount = typeOrderDiscountValue;
  // 2
  const optionspiechart = {
    chart: {
      id: 'pie-chart',
      fontFamily: "'DM Sans', sans-serif",
      foreColor: '#adb0bb',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: labelDiscountKey,
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
        },
      },
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
    },
    colors: [
      '#509EE2',
      '#96D9D9',
      '#B8BBC3',
      '#6ac3fd',
      '#f64e60',
      '#26c6da',
      '#ffa800',
      '#7C7FB3',
      '#F8D266',
      '#89BD54',
      '#A31334',
      '#A31334',
      '#A2D9CE',
      '#F0B27A',
      '#AAB7B8',
      '#A569BD',
      '#EC7063',
      '#D4AC0D',
      '#117A65',
    ],
    tooltip: {
      fillSeriesColor: false,
    },
  };
  const seriespiechart = labelDiscountValue;

  return (
    <>
      {/* breadcrumb */}
      <Breadcrumb title="Mes Réductions" items={BCrumb} />
      {/* end breadcrumb */}
      <Grid container spacing={0}>
        <Grid item lg={4} md={4} xs={12}>
          <Card variant="outlined">
            {isLoadingTypeDis && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size="30px" />
              </Box>
            )}
            <Box p={2} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography textAlign="center" variant="h6">
                  Répartition type Discount - Franchise
                </Typography>
              </Box>
            </Box>
            {!isLoadingTypeDis && (
              <CardContent>
                <Chart
                  options={optionsTypeDiscount}
                  series={seriesTypeDiscount}
                  type="donut"
                  height="300px"
                />
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Card variant="outlined">
            {isLoadingTypeOrd && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size="30px" />
              </Box>
            )}
            <Box p={2} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography textAlign="center" variant="h6">
                  Répartition Source Commande - Franchise
                </Typography>
              </Box>
            </Box>
            {!isLoadingTypeOrd && (
              <CardContent>
                <Chart
                  options={optionsLabelDiscount}
                  series={seriesLabelDiscount}
                  type="donut"
                  height="300px"
                />
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Card variant="outlined">
            {isLoadingTypeLabel && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size="30px" />
              </Box>
            )}
            <Box p={2} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography textAlign="center" variant="h6">
                  Répartition Label Discount - Franchise
                </Typography>
              </Box>
            </Box>
            {!isLoadingTypeLabel && (
              <CardContent>
                <Chart
                  options={optionspiechart}
                  series={seriespiechart}
                  type="donut"
                  height="300px"
                />
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default FirstCharts;

FirstCharts.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
