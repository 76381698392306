import {  useState } from 'react';
import dateFormat from 'dateformat';
// import PropsType from 'prop-types';
import { privateRequest } from '../../../requestMethods';
import useDebounce from '../../../redux/custom-hooks/useDebounce';

const usePromotions = (currentUser, date, forDay, totalTtc) => {
  const [promotions, setPromotions] = useState({
    loading: false,
    error: null,
    data: [],
  });
  const formattedDate = dateFormat(date, 'yyyy-mm-dd');
  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const getPromotions = async () => {
    setPromotions({
      loading: true,
      error: null,
      data: [],
    });
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiquePromotion/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setPromotions({
          loading: false,
          error: null,
          data: res.data,
        });
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CaPeriodiquePromotion/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }`,
        );
        setPromotions({
          loading: false,
          error: null,
          data: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      setPromotions({
        loading: false,
        error:
          "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
        data: [],
      });
    }
  };
  // useEffect(() => {
  //   getPromotions();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(
    ()=>getPromotions(),
    500,
    [date, currentUser?.siret, currentUser?.id, forDay]
  )
  const accumulationPercent =
    promotions?.data?.accumulation?.totalDiscount === 0
      ? 0
      : (promotions?.data?.accumulation?.totalDiscount!== 0 && totalTtc!==0) 
      ? (promotions?.data?.accumulation?.totalDiscount / totalTtc) * 100
      : totalTtc === 0 && 100;
      console.log("accumulationPercent***",accumulationPercent)
  return {
    promotions,
    accumulationPercent
  };
};
export default usePromotions;
