import { Box, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { KeyboardDoubleArrowRight } from '@mui/icons-material';
// import CaData from './dashboardRestau-components/CaData';
// import Graph from './dashboardRestau-components/Graph';
// import AdditionalInfo from './dashboardRestau-components/AdditionalInfo';
// import Types from './dashboardRestau-components/Types';
import TopVentsCatProducts from './dashboardRestau-components/TopVentsCatProducts';
import TopVentsAllProducts from './dashboardRestau-components/TopVentsAllProducts';
// import ClassementRestaus from './dashboardFranchise-components/ClassementRestaus';
// import Promotions from './dashboardRestau-components/Promotions';
// import Annulations from './dashboardRestau-components/Annulations';

const DashboardRestau = ({ 
  // forDay, 
  // date, 
  datedebut,
  datefin,
  currentUser 
}) => {
  // const [totalTtc, setTotalTtc] = useState(0);
  // console.log(totalTtc)
  const [tableExtended, setTableExtended]=useState(false);
  const [selectedCat, setSelectedCat] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  useEffect(() => {
    setTableExtended(false)
  }, [isMobile])
  return (
    <Box>
      {/* caCards  */}
      {/* <CaData currentUser={currentUser} date={date} forDay={forDay} /> */}
      {/* !caCards  */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          transition: 'flex-drection 2s ease',
          // flexDirection: tableExtended ? "column" : { xs: 'column', md: 'row' },
          gap: '20px',
          mt: '20px',
        }}
        className="dashContainer"
      >
        {/* <Box
          sx={{
            position: 'relative',
            transition: 'width .4s ease',
            width: tableExtended ? '100%' : {xs:"100%", md: 'calc(40% - 10px)' },
          }}
        >
          <Box
            onClick={() => setTableExtended(!tableExtended)}
            sx={{
              display: {xs:'none',md:'flex'},
              width: '46px',
              height: '46px',
              padding: '6px 9px',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow:
                '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)',
              borderRadius: '50%',
              position: 'absolute',
              backgroundColor: 'white',
              cursor: 'pointer',
              right: 'calc(-46px / 2)',
              top: '10px',
            }}
          >
            <KeyboardDoubleArrowRight
              sx={{
                transition: 'transform .4s ease',
                transform: tableExtended && 'rotate(180deg)',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
            className="left block"
          >
            <ClassementRestaus date={date} forDay={forDay} tableExtended={tableExtended} setTableExtended={setTableExtended} />
          </Box>
        </Box> */}

        {/* // right   */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            transition: 'width .4s ease',
            // old
            // width: tableExtended ? '100%' : { xs: '100%', md: 'calc(60% - 10px)' },
            width: tableExtended ? '100%' : { xs: '100%', md: '100%' },
          }}
          className="right"
        >
          {/* <Box className="block">
            <Graph
              currentUser={currentUser}
              date={date}
              forDay={forDay}
              setTotalTtc={setTotalTtc}
            />
          </Box> */}

          <Box
            sx={{
              display: 'flex',
              // flexDirection: { xs: 'column', md: 'row' },
              gap: '20px',
              flexWrap: 'wrap',
              '& .block': {
                width: { xs: '100%', md: 'calc(100% / 2 - 10px)' },
              },
            }}
            className="container2"
          >
            {/* top ventes */}
            <Box
              sx={{
                padding: '12px !important',
              }}
              className="block"
            >
              <TopVentsCatProducts 
              currentUser={currentUser}  
              datedebut={datedebut} 
              datefin={datefin} 
              // date={date} 
              // forDay={forDay} 
              setSelectedCat={setSelectedCat}
              selectedCat={selectedCat}
               />
            </Box>
            {/* !top ventes */}
            {/* sources */}
            <Box className="block">
              <TopVentsAllProducts 
              currentUser={currentUser} 
              datedebut={datedebut} 
              datefin={datefin} 
              // date={date} 
              // forDay={forDay} 
              selectedCat={selectedCat}
              />
            </Box>
            {/* !sources */}
            {/* sources */}
            {/* <Box
              className="block"
            >
              <AdditionalInfo
                currentUser={currentUser}
                date={date}
                forDay={forDay}
                title="Sources"
              />
            </Box> */}
            {/* !sources */}
            {/* Types */}
            {/* <Types currentUser={currentUser} date={date} forDay={forDay} /> */}
            {/* !Types */}
            {/* modePaiment */}
            {/* <Box
              className="block"
            >
              <AdditionalInfo
                currentUser={currentUser}
                date={date}
                forDay={forDay}
                title="Modes de Paiement"
              />
               <DisplayedComponentInLeft /> 
            </Box> */}
            {/* <Box
        sx={{
          padding: '12px !important',
        }}
        className="block"
      >
        <Promotions currentUser={currentUser} forDay={forDay} date={date} totalTtc={totalTtc}/>
      </Box>
      <Box
        sx={{
          padding: '12px !important',
        }}
        className="block"
      >
        <Annulations currentUser={currentUser} forDay={forDay} date={date} />
      </Box> */}
            {/* !modePaiment */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
DashboardRestau.propTypes = {
  datedebut: PropTypes.any.isRequired,
  datefin: PropTypes.any.isRequired,

  // date: PropTypes.any.isRequired,
  // forDay: PropTypes.any,
  currentUser: PropTypes.any,
};
export default DashboardRestau;
