import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext();
export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const openModal = (name,props={}) => {
    setModal({name,props});
  };
  const closeModal = () => {
    setModal(null);
  };
  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
ModalProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
export const useModal = () => useContext(ModalContext);
